<template>
    <div class='compose'>
        <div v-if="showMenu" class='possible-mobile-menu mobile-menu app--only-mobile'>
            <PossibleMenu2 @onMenuClick="closeMenu" />
            <button @click="clickLogout" class='mobile-logout'>Logout</button>
        </div>
        <div class=' app--only-mobile flex menu-toolbar'>
            <button @click="showMenu = !showMenu" class='material-icons mobile-menu-button'>menu</button>
            <div>PossibleMe</div>
            <div class='menu-date'>{{ currentDate }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options } from 'vue-class-component';
import FireComponent from '../phoenix/core/FireComponent.vue';
import PossibleMenu2 from "@/components/possibleme/PossibleMenu2.vue"
import { toPossibleDate } from "possibleme-db/lib/util/DateHelper"
import MeAction from "../possibleme/helper/MeAction.vue"
import { signout } from "@/scripts-core/CloudDB"

@Options({
    components: {
        PossibleMenu2,
        MeAction
    }
})
export default class MobileMenu extends FireComponent {
    componentClass = () => "MobileMenu"
    showMenu = false
    currentDate = "1/1/1970"

    closeMenu = () => this.showMenu = false

    mounted() {
        this.currentDate = toPossibleDate(Date.now()) 
    }

    async clickLogout() {
        await signout()
        this.$router.push("/login")
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color--primary);
    padding-left: .5rem;
    padding-right: .5rem ;
}

.mobile-menu-button {
    color: white;
    padding: 1rem;
}
div {
    margin-top: auto;
    margin-bottom: auto;
}

.menu-toolbar {
    color: white;
}

.menu-date {
    position: absolute;
    right: 1rem;
    top:25%;
}

.mobile-logout {
    margin-top: .5rem;
    padding: .5rem;
    background-color: purple;
    width: 100%;
    color: white;
    border-radius: 1rem;
}

</style>