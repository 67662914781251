import { PossibleData } from "./Data"
import { PossibleHelper } from "./Helper"

import PossibleCloud from "./possibleme/PossibleCloud"

const cloudImp = new PossibleCloud()
const cloud : Cloud = cloudImp;

export const COFFEE_ANY : any = {}

export default cloud

export interface Cloud {
    helper : PossibleHelper
    data : PossibleData
    genUUID() : string
    sha256(msg : string | Uint8Array) : string 
}

export type Demount = () => void
