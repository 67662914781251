import { AccountDetails, LiveAtlas, LiveObject, PossibleAPI , CoreModel } from "../scripts/PossibleAPI";
import { BaseCycle, PossibleContext } from "./PossibleApp";
import firebase from "firebase/app";
import { Demount } from "@/Cloud";
import { PossibleUser } from "possibleme-db";
import { possibleEnv } from "../Conf"

const FIRE_API_NAME = "possible-api/@firebase"

export default class FireAPI implements PossibleAPI {

    connectDocument(path: string, callback: (object: any) => void): Demount {
        const dereg = firebase.firestore().doc(path).onSnapshot((snap) =>{
            if(snap.exists){
                const data = snap.data();
                callback(data);
            }
        });
        return dereg;
    }

    getApiName(): string {
        throw FIRE_API_NAME
    }
    sendEmailVerification(success: () => void, err?: (message: string) => void): void {
        firebase.auth().currentUser?.sendEmailVerification().then(done =>{
            success()
        }).catch(e =>{
            err?.(e.message);
        })
    }
    isEmailVerified(): boolean {
        return firebase.auth().currentUser?.emailVerified ?? false;
    }
    resetPassword(email:  string, success?: () => void, err?: (msg: string) => void): void {
        firebase.auth().sendPasswordResetEmail(email).then(()=>{
            success?.();
        }).catch(e =>{
            err?.(e.message);
        });
    }
    
    updateUser(user: PossibleUser, success? : ()=>void, err? :(msg:string)=>void): void {

        const fireUser : PossibleUser = {
            firstname : user.firstname,
            lastname : user.lastname,
            ndisNo :  user.ndisNo,
            birthday : user.birthday
            //TODO: Fix birthday format
        }
        firebase.firestore().doc(`users/${firebase.auth().currentUser?.uid}`).update(fireUser).then(call =>{
            success?.();
        }).catch((e)=>{
            if(e.code == 'not-found'){
                firebase.firestore().doc(`users/${firebase.auth().currentUser?.uid}`).set(fireUser).then(call =>{
                    success?.();
                }).catch((e) =>{
                    err?.(e.message);
                })
            }
            else {
                err?.(e.message);
            }
        });
    }
    changePassword(currentPassword: string, newPassword: string, success?: () => void, err?: (msg: string) => void): void {
        const cred =  firebase.auth.EmailAuthProvider.
        credential(firebase.auth().currentUser?.email??"",currentPassword)
        
        firebase.auth().currentUser?.reauthenticateWithCredential(cred).then((user)=>{
            user.user?.updatePassword(newPassword).then(()=>{
                success?.();
            }).catch((e)=>{
                err?.(e.message);
            })
        }).catch(e =>{
            err?.(e.message);
        });
    }
    changeEmail(newEmail : string, currentPassword :string, success?: () =>void, err? : (msg:string) =>void): void {
        const cred =  firebase.auth.EmailAuthProvider.
        credential(firebase.auth().currentUser?.email??"",currentPassword);

       firebase.auth().currentUser?.reauthenticateWithCredential(cred).then((user)=>{
            user.user?.updateEmail(newEmail).then(()=>{
                user.user?.sendEmailVerification();
                success?.()
            }).catch(e =>{
                err?.(e.message)
            })
       }).catch(e =>{
           err?.(e.message);
       })
       return;
    }
    currentEmail(): string {
        return firebase.auth().currentUser?.email?? "N/A"
    }
    async redirectPortal(): Promise<void> {
        const portalHost = possibleEnv.appHostPortal
        const functionRef = firebase
        .app()
        .functions(portalHost)
        .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
        const { data } = await functionRef({ returnUrl: window.location.origin});
            window.location.assign(data.url);
    }

    async resolveAccount(uid : string, account : AccountDetails, resolve : (val:PossibleUser)=>void, deref:()=>void, success?:(acc:PossibleUser)=>void){
        await firebase.firestore().doc(`users/${uid}`).set(account.details);
                        resolve(account.details);
                        deref();
                        success?.(account.details);
    }
    
    newAccount(details: AccountDetails, success?: (details: PossibleUser) => void, err?: (msg: string) => void): Promise<PossibleUser> {

        return new Promise((resolve, reject)=>{
            firebase.auth().createUserWithEmailAndPassword(details.email, details.password).then(async cred =>{
                cred.user?.sendEmailVerification();
                /* Await trial data from Clod functions*/
                const trialInit = firebase.firestore().doc(`trials/${cred.user?.uid}`).onSnapshot(async snap =>{
                    if(snap.exists)
                        this.resolveAccount(cred.user?.uid??"", details,resolve,trialInit,success);
                })
                setTimeout(()=>{
                    this.resolveAccount(cred.user?.uid??"", details,resolve,trialInit,success);
                }, 4000)
                /**/
            }).catch(e =>{
                reject(e);
                console.error(e);
            })
            setTimeout(()=>{reject("Function timing out")}, 10000)
        })
    }
    
    signout(): void {
        this.disconnectUser();
        firebase.auth().signOut();
    }
    listenUser(callback: (uid: string | undefined) => void): ()=>void {
        const detach = firebase.auth().onAuthStateChanged(authChange => {
           callback(authChange?.uid);
        })
        return detach;
    }
    
    currentUid(): string | undefined {
        return firebase.auth().currentUser?.uid;
    }
    login(email: string, password: string, success?: () => void, err?: (msg : string) => void): void {
        firebase.auth().signInWithEmailAndPassword(email, password).then(()=>{
            success?.()
        }).catch(e =>{
            let message = e.message;
            if(e.code == "auth/wrong-password")
                message = "The password or email is invalid";
            if(e.code == "auth/user-not-found")
                message = "There is no user corresponding to this email";
            console.error(e);
            err?.(message);
        })
    }
    verifyPromo(code : string) : Promise<boolean>{
        code = code.trim().toLocaleLowerCase()
        
        return new Promise((resolve, reject)=> {
            firebase.firestore().collection("public/api/promo").where("promoCode", "==", code).get()
            .then(async list =>{
                if(list.size > 0) resolve(true);
                else resolve(false);
            }).catch(e =>reject(e));
        })
    }

    atlas(context: PossibleContext, path: string, callback: (array: any[]) => void): LiveAtlas {
        const atlas = new FireAtlas(context, path, callback);
        return atlas;
    }
    watch(context : PossibleContext, path: string, callback: (object: any) => void): LiveObject {
        //firebase.firestore().doc(pa)
        const newLive = new FireWatch(context, path, callback);
        return newLive;
    }




    private disconnectUser():void{
        FireWatch.ALL_WATCHERS.forEach(watcher =>{
            watcher.disconnect();
        })
        FireWatch.ALL_WATCHERS.clear();
    }
}
class FireWatch extends BaseCycle implements LiveObject {
    static ALL_WATCHERS : Set<FireWatch> = new Set();
    private callback : {(object: any):void}
    private cancelListener : any;
    private path : string;
    private context : PossibleContext;

    constructor(context: PossibleContext, path: string, callback: (object: any) => void){
        super("FireWatch");
        this.context = context;
        this.path =path;
        this.callback = callback;
        //this.cancelListener = firebase.firestore().doc(path).onSnapshot(this.onFireSnap);
        this.context.addCycle(this);
    }


    name():string {
        return "Random Name";
    }
    onCreate(): void {
        FireWatch.ALL_WATCHERS.add(this);
        return;
    }
    onStart(): void {
        this.connect();
    }
    onStop(): void {
        this.disconnect();
    }
    onDestory(): void {
        FireWatch.ALL_WATCHERS.delete(this);
        return;
    }
    connect(): void {
     
        if(this.cancelListener)
            this.disconnect();
        const localCaller = this.callback;
        this.cancelListener = firebase.firestore().doc(this.path).onSnapshot(snap =>{
            if(snap.exists)
                localCaller(snap.data());
        }, onError =>{
            console.error("Firewatch error")
        });
    }
    disconnect(): void {
    
        if(this.cancelListener)
            this.cancelListener();
        this.cancelListener = undefined;
    }
}


class FireAtlas extends BaseCycle implements LiveAtlas {
    private context : PossibleContext;
    private path : string;
    private cancelListener : any;

    constructor(context : PossibleContext, path : string, callback: (array: CoreModel[]) => void){
        super("FireAtlas");
        this.context = context;
        this.callback = callback;
        this.path = path;
        this.context.addCycle(this);
    }

    private callback: (array: CoreModel[]) => void;
    connect(): void {
        //throw new Error("Method not implemented.");
        const localCall = this.callback;
        this.cancelListener = firebase.firestore().collection(this.path).onSnapshot(snap =>{
            const array : CoreModel[] = [];

            snap.forEach(docSnap => {
                
                const model : CoreModel = {
                    id : docSnap.id,
                    path : docSnap.ref.path,
                    model : docSnap.data(),
                }
                array.push(model);

            });
            localCall?.(array);
        })
    }
    onStart(): void {
        this.connect();
    }
    onStop(): void {
        this.disconnect();
    }
    disconnect(): void {
        if(this.cancelListener)
            this.cancelListener();
        this.cancelListener = undefined;
        //throw new Error("Method not implemented.");
    }
    name(): string {
        return this.cycleId();
    }

   
  
} 
interface FireUser {
    birthday? : string
    firstname? : string
    lastname? : string
    ndisNo? : string
    uuid? : string
}



function path_user(uid: string):string {return `users/${uid}`}