<template>
    <div>
        <img class='logo-center' src="/logo.png" alt="">
        <h1 class='report-title pb-2'>NDIS {{ headerTitle }} Report for {{user.firstname}} {{user.lastname}}</h1>
        
        <table class='widget-table widget-report'>
            <tr >
                <th class='first-cell'>Name</th>
                <td>{{user?.firstname ?? "N/A"}} {{user?.lastname ?? "N/A"}}</td>
            </tr>
            <tr>
                <th>Birth Date</th>
                <td>{{user?.birthday ?? "N/A"}}</td>
            </tr>
            <tr>
                <th>Age</th>
                <td>{{calcAge()}}</td>
            </tr>
            <tr>
                <th>NDIS No.</th>
                <td>{{user?.ndisNo ?? "N/A"}}</td>
            </tr>
            <tr>
                <th>Plan Date</th>
                <td>{{displayInt()}}</td>
            </tr>
            <tr>
                <th>Report Date</th>
                <td>{{help.displayDate(Date.now())}}</td>
            </tr>
        </table>
        <h3 class='report-header mt-3 nova-bold' >Disclaimer</h3>
        <p>This report is auto generated by PossibleMe app and does not guarantee any future funding from NDIS.</p>
        <p  class='text-justify'>This report summarises the services and therapies that {{user.firstname}} have received through the support of NDIS funding. <span v-if='showReadit' >This report should be read together with all the therapies’ reports and any other related documents.</span> </p>
    </div>
</template>

<script lang="ts">
import { BaseUser, NDISPlan} from "possibleme-db"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import * as DateHelp from "possibleme-db/lib/util/DateHelper"

@Options({
  components : {
  }
})
export default class ReportHeader extends Vue {
    help = DateHelp

    @Prop()
    plan! : NDISPlan

    @Prop()
    user! : BaseUser

    @Prop()
    showReadit! : boolean

    @Prop()
    headerTitle! : string

    displayInt() : string {
        try {
            return `${DateHelp.displayDate(this.plan.dateStart)} - ${DateHelp.displayDate(this.plan.dateEnd)}` 
        }
        catch(e){
            console.error(e)
        }
        return ""
    }
    calcAge(){
        try {
            const now = new Date();
            const parse = DateHelp.fromPossibleDate(this.user.birthday ?? "ERR");
            if(parse == null)
                return "ERR"
            const age = now.getFullYear() - parse.getFullYear();
            return age;
        }catch(e){
            return "ERR"
        }
    }
    /* name: string;
    description: string;
    dateEnd: number;
    dateStart: number;
    filename: string;
    fileDisplay: string;
    funding: {
        [key: string]: PlanFunding;
    }; 
    */
}
</script>

<style scoped>
.first-cell {
    width: 30% ;
}
.logo-center {
    margin: auto;
    margin-bottom: 2rem;
}
</style>

