
import { Options } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import createBig from "possibleme-db/lib/util/BigNum"

@Options({
    components : {
    },
    emits : ["amount", "clear", "set"]
})
export default class DebugVue extends FireComponent {
    @Prop()
    header!: string

    @Prop()
    initAmount! : string
    
    amount : string = createBig("0").valueOf()
    amountStr = "0"

    lastBig = createBig("0");

    @Prop()
    labelText! : string

    mounted(){
        if(this.initAmount)
            this.setAmount(this.initAmount)
        this.$emit("clear", this.clearMe);
        this.$emit("amount",this.amount);
        this.$emit("set", this.setAmount);
    }
    setAmount(amount : string){
        this.amount = amount
        this.onMoney();
    }

    clearMe(){
        this.amount = "0.00"
        this.amountStr = "0.00"
        this.lastBig = createBig("0")
    }
    componentClass = () => "DebugVue.vue"
    onUserReady(): void {
        return;
    }
    onInput() {
        if(this.amount == "")
            return
        try {
            const parse = createBig(this.amount);
            const split = this.amount.split(".");
            if(split.length > 1 &&  split[1].length > 2){
                this.amount = this.lastBig.valueOf();
            }
            this.$emit("amount", parse.valueOf());
            
            this.lastBig = parse;
        }catch(e){
            this.amount = this.lastBig.valueOf();
        }
    }
    onMoney() {
        try{ 
            const format = createBig(this.amount);
            this.amount = format.display()
            this.$emit("amount", format.valueOf());
        }catch(e){ this.amount = "" }
       
    }
}
