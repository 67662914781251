import LightArray from "../../scripts-core/util/LightArray";
import CloudObject, { CloudAccess, ObjectHelper } from "../../scripts-core/cloud/CloudObject";
import PhoenixContext from "../../scripts-core/core/PhoenixContext";
import { Goals, NDISPlan, PlanFunding, PossibleUser, Provider, Support, SupportIdea} from "possibleme-db";
import { uuid } from "vue-uuid";
import { computeReviews, SupportReview } from "possibleme-db/lib/Compute"
import {ReadyCall, ready } from "../../scripts-core/CloudDB"
import CloudDocument from "../../scripts-core/cloud/CloudDocument"
import CloudList from "../../scripts-core/cloud/CloudList"
import {CurrentPlanSelect, CustomPlanSelect} from "./PossibleHelpers"
import { initGoals, ClientSubscribe, SubscribeMethod, Unsubscribe, registerSub, Selection } from "./ClientHelpers";
import PhoenixApp from "../../scripts-core/core/PhoenixApp";
import * as api from "../../possibleme/Api"
import {sendAlertMessage, sendComponentClass} from "../../components/phoenix/PhoenixComponent"
import * as comp from "../../components/CompDef"

let stateResume : ClientState | null = null

/* NEw Client Model */
const ClientApp = new PhoenixApp("possibleme-webapp", {isDebug : false})
const ClientContext = new PhoenixContext(ClientApp, "possibleme-appcontext"); 

const planSubscribers = new LightArray<ClientSubscribe<CloudObject<NDISPlan>[]>>();
const providerSubscribers = new LightArray<ClientSubscribe<CloudObject<Provider>[]>>();
const supportSubscribers = new LightArray<ClientSubscribe<CloudObject<Support>[]>>();
const goalSubscribers = new LightArray<ClientSubscribe<CloudObject<Goals>>>();
//const currentPlanSubscribers = new LightArray<ClientSubscribe<CloudObject<NDISPlan>>>();
const ideaSubscribers = new LightArray<ClientSubscribe<CloudObject<SupportIdea>[]>>();

/* Select Objects */

const SelectPlan = new Selection(CurrentPlanSelect);

const CloudSupports = new CloudList<Support>(ClientContext, supports =>{
    ObjectHelper.sort(ClientContext, supports, (a,b)=> a.dateStart - b.dateStart)

    supportSubscribers.forEach(sub => sub(supports));

}, 
[])

const CloudGoals = new CloudDocument<Goals>(ClientContext, goal => {
    goalSubscribers.forEach(sub =>sub(goal));
},
[])

const CloudPlans = new  CloudList<NDISPlan>(ClientContext, plans => {
    ObjectHelper.sort(ClientContext, plans, (a, b)=> a.dateEnd - b.dateStart)

    let selectPlan : CloudObject<NDISPlan> | null = null
    if(stateResume){
        selectPlan = ObjectHelper.resolveId(stateResume.select, plans);
        SelectPlan.force(CustomPlanSelect, selectPlan)
       
        stateResume = null

       
        sendComponentClass(comp.WHAT_PLAN_TITLE, selectPlan?.value(ClientContext).name, 
            comp.CLASS_PLAN_BANNER)
    }
   
    if(!selectPlan)
        selectPlan = SelectPlan.select(plans);
    
    if(selectPlan){
      
        const pathPlan = selectPlan.path();
        const pathGoal =            `${selectPlan.path()}/data/goals`
        const pathSupports =        `${selectPlan.path()}/support`
        const pathIdeas =           `${selectPlan.path()}/ideas`

        CloudSupports.connect(ClientContext, pathSupports);
        CloudGoals.connect(ClientContext, pathGoal, initGoals);
        CloudIdeas.connect(ClientContext, pathIdeas);
    
        api.checkSupport(`${pathPlan}/support`, `${pathPlan}/ideas`).catch(e =>console.error(e))
    }
    else {
        return;
    }
    /* Call subs */
    planSubscribers.forEach(sub => sub(plans)) 
}, 
[SelectPlan])

const CloudIdeas = new CloudList<SupportIdea>(ClientContext, ideas =>{
    ideaSubscribers.forEach(sub =>sub(ideas));
},
[])

const CloudProvider = new CloudList<Provider>(ClientContext, providers => {
    providerSubscribers.forEach(sub =>sub(providers))
},
[])

const CloudUser = new CloudDocument<PossibleUser>(ClientContext, user =>{
    //(user.json())
    return;
},
[])

const UserReady : ReadyCall = (user =>{
    if(user){
        const uid = user.uid
        CloudUser.connect(ClientContext,        `users/${uid}`);
        CloudProvider.connect(ClientContext,    `users/${uid}/providers`);
        CloudPlans.connect(ClientContext,       `users/${uid}/plans`);
    }
})

export function startClient(){
    ready(UserReady);
}
/** */

export function subscribeCurrentPlan(context : PhoenixContext, subscription : ClientSubscribe<CloudObject<NDISPlan>>, type : SubscribeMethod = "ongoing"):Unsubscribe {
    return SelectPlan.subscribe(context, type,subscription)
}
export function subscribePlans(context : PhoenixContext, subscription : ClientSubscribe<CloudObject<NDISPlan>[]>,type : SubscribeMethod = "ongoing"):Unsubscribe {
    return registerSub(context, subscription, type, planSubscribers, CloudPlans.list());
}
export function subscribeProvider(context : PhoenixContext, subscription : ClientSubscribe<CloudObject<Provider>[]>, type : SubscribeMethod = "ongoing") : Unsubscribe {
    return registerSub(context, subscription, type, providerSubscribers, CloudProvider.list());
}
export function subscribeCurrentSupports(context : PhoenixContext, subscription : ClientSubscribe<CloudObject<Support>[]>, type: SubscribeMethod ="ongoing") : Unsubscribe {
    return registerSub(context, subscription ,type, supportSubscribers, CloudSupports.list());
}
export function subscribeCurrentGoals(context : PhoenixContext, subscription : ClientSubscribe<CloudObject<Goals>>, type : SubscribeMethod = "ongoing") : Unsubscribe{
    return registerSub(context, subscription,type, goalSubscribers, CloudGoals.document());
}
export function subscribeIdeas(context : PhoenixContext, subscription : ClientSubscribe<CloudObject<SupportIdea>[]>, type : SubscribeMethod = "ongoing") : Unsubscribe{
    return registerSub(context, subscription, type,ideaSubscribers, CloudIdeas.list());
}

export function getCurrentPlan() : CloudObject<NDISPlan> | null {
    return SelectPlan.get()
}
export function insertFunding(object : CloudObject<NDISPlan>, fundingItem : PlanFunding) {
    const data = object.value(ClientContext);
    const newId = uuid.v4();

    data.funding[newId] = fundingItem;
}

/* Client View GETS*/
export function selectCustomPlan(plan : CloudObject<NDISPlan>) {
    SelectPlan.change(CustomPlanSelect,plan);
}

export function getUser() : CloudObject<PossibleUser> | null {
    return CloudUser.document()
}

export function getUsersFirstname() : string {
    return getUser()?.value(ClientContext).firstname ?? ""
}

export function getUsersFullname() : string {
    const user = getUser()?.value(ClientContext)
    return `${user?.firstname ?? ""} ${user?.lastname ?? ""}`
}

export function getPlans(): CloudObject<NDISPlan>[] {
    return CloudPlans.list();
}
export function getSupports():CloudObject<Support>[] {
    return CloudSupports.list();
}

export function getIdeaSuggest(access : CloudAccess): string[] {
    const suggest : string[] = []
    const ideas = CloudIdeas.list();
    for(let i=0; i < ideas.length;i++)
        suggest.push(ideas[i].value(access).name);
    return suggest;
}

export function getGoals(access : CloudAccess):CloudObject<Goals> | null {
    return CloudGoals.document()
}

export function getReviews(access : CloudAccess) : SupportReview[] | null {
    const array : SupportReview[] = []
    const currentGoals = CloudGoals.document();

    if(currentGoals == null)    return null;
    try{
        
        const goalData = currentGoals.value(access);
        const reviews = CloudIdeas.list()

        for(let i=0; i < reviews.length; i++){
            const ilocal= reviews[i].value(access);

            if(!ilocal || !goalData)
                throw "Ilocal of goal data null"
            
            const computed = computeReviews(ilocal, goalData);
            if(computed.reviews.length > 0)
                array.push(computed);
        }
    }catch(e){
        console.error(e);
        return null;
    }
    return array;
}

type ClientState = {
    select : string
}

export function resumeState(state : ClientState) {
    stateResume = state
}

/**
 * returns true if the current plan is immutable. Should also 
 * bring up a dialog box
 */
export function isPlanImmutable(): boolean {
    const plan = SelectPlan.get();
    if(!plan) {
        return true;
    }
    const valPlan = plan.value(ClientContext);
    const now = Date.now();

    const immutable = valPlan.dateEnd < now;
    if(immutable){
        sendAlertMessage("Unable to Update Plan", "Once a plan has ended, you cannot make any changes to the supports or goals.")
    }
    return immutable;
        
    /*check if current plan is immutable. If the current 
    if the current plan exists outside of now. Show box and return

    returns true if dialog was shown
    */
}