
import { BaseUser, NDISPlan} from "possibleme-db"
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import * as DateHelp from "possibleme-db/lib/util/DateHelper"

@Options({
  components : {
  }
})
export default class ReportHeader extends Vue {
    help = DateHelp

    @Prop()
    plan! : NDISPlan

    @Prop()
    user! : BaseUser

    @Prop()
    showReadit! : boolean

    @Prop()
    headerTitle! : string

    displayInt() : string {
        try {
            return `${DateHelp.displayDate(this.plan.dateStart)} - ${DateHelp.displayDate(this.plan.dateEnd)}` 
        }
        catch(e){
            console.error(e)
        }
        return ""
    }
    calcAge(){
        try {
            const now = new Date();
            const parse = DateHelp.fromPossibleDate(this.user.birthday ?? "ERR");
            if(parse == null)
                return "ERR"
            const age = now.getFullYear() - parse.getFullYear();
            return age;
        }catch(e){
            return "ERR"
        }
    }
    /* name: string;
    description: string;
    dateEnd: number;
    dateStart: number;
    filename: string;
    fileDisplay: string;
    funding: {
        [key: string]: PlanFunding;
    }; 
    */
}
