<template>
    <div>
        <header>
            <nav class='navrow' v-if="showNav" >
                <div class='flex_center'  id="brand" >
                    <img src="./assets/logo.png" id="plogo" @click="logoClick()" ref="mylogo"/>
                    <h3 id="title" class='hidemobile'>PossibleMe</h3>
                </div>
                <div class='hidemobile navitem' style="display: none">
                    <button>Subscribe</button>
                </div>
                <div v-if="false" class='auto--margin'><button @click="onApplication">Application</button></div>
                <div class='hidemobile' v-if="false" style="margin-top: auto; margin-bottom: auto">
                    <button class="stm_btn" @click="clickSignup()" style="width: 120px; height: 50px">
                    New Account
                    </button>
                </div>
                <div class='loader' v-if="false" ></div>
                <div v-if="true" style="margin-top: auto; margin-bottom: auto">
                    <button v-if="false" id="account" @click="clickAccount()">
                        <p>{{ user_inital }}</p>
                    </button>
                    <img v-if="showTerms" id="logout" @click="clickLogout()" src="./assets/logout_white.png" style="color: white; margin-left: 1rem" alt=""/>
                </div>
            </nav>
        </header>
        <div class='app-content'>
            <router-view />
        </div> 
        <MeAlert/>
    </div>
</template>

<script lang="ts">

import { Options } from "vue-class-component"
import AppBar from "@/components/AppBar.vue"
import PossibleApp from "./scripts/PossibleApp"
import { Watch } from "vue-property-decorator"
import PossibleVue from "./scripts/PossibleFramework"
import * as WHAT from "@/scripts/what"
import AlertDialog from "@/components/phoenix/features/AlertDialog.vue"
import MeAlert from "./components/possibleme/helper/MeAlert.vue"
import { logWidthUsage } from "@/possibleme/PossibleAnalytics"

@Options({
    components: {
        AppBar,
        AlertDialog,
        MeAlert
    },
})
export default class App extends PossibleVue {
    showTerms = true;

    appbarContext = PossibleApp.Instance().newStartedContext("appbar")

    user_inital = "Login"
    showNav = true;

    @Watch("$route", { immediate : true, deep : true })
    onUrlChange(newVal: any) {
        try {
            const path = newVal.path as string
           
            const segments = path.split("/");
            if (segments[1] == "app" || segments[1] == "report")
                this.showNav = false
            else this.showNav = true
            //hide terms if login page
            if (path === "/login" || path == "/signup") 
                this.showTerms = false
            else
                this.showTerms = true
        } 
        catch (e) {
            console.error(e)
        }
    }
    onApplication() {
        this.$router.push({
            path : "/app"
        })
    }
  
    mounted() {
        PossibleApp.Instance().getApi().listenUser(this.onUser)
        logWidthUsage()
    }

    clickLogout() {
        this.apiDEP().signout()
        this.$router.push("/login")
    }

    clickSignup() {
        this.contextDEP().send(WHAT.SIGNIN, "Hello World Messages")
        this.$router.push({ path: "/signup" })
    }
    onUser(uid: string | undefined) {
        if (!uid) {
            this.user_inital = "Login"
            return
        }
        const userDoc = `users/${uid}`
        PossibleApp.Instance()
        .getApi()
        .watch(this.appbarContext, userDoc, (data) => {
            const fname = data.firstname as string
            const lname = data.lastname as string
            this.user_inital =
            fname.charAt(0).toUpperCase() + "" + lname.charAt(0).toUpperCase()
        });
    }
    clickAccount() {
        const uid = this.apiDEP().currentUid()
        if (uid) 
            this.$router.push({ path: "/manage" })
        else 
            this.$router.push({ path: "/login" })
    }
    clickHome() {
        this.$router.push({ path: "/" });
    }
    logoClick() {
        this.$router.push({ path: "/" });
    }
}
</script>

<style>
#logout {
    padding: 0.2rem;
    cursor: pointer;
}

ul {
    text-align: center;
}
li {
    display: block;
}

* {
    outline: none;
    border-style: none;
    font-size: 1.1rem;
}

#account p {
    color: white;
    line-height: 50px;
}

nav {
    background-color: var(--prime);
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 5rem;
}

menu {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
    background-color: var(--prime);
}

.app-content {
    margin-top: 5rem;
}

.navitem {
    margin-top: auto;
    margin-bottom: auto;
}

#account {
    margin-left: 0.2rem;
    text-align: center;
    border-radius: 0.5rem;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #aad400;
    width: 70px;
    height: 50px;
}

#account:hover {
    background-color: coral;
}

#brand {
    padding: 10px;
}

#app {
    width: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

.navrow {
    padding-top: var(--nav_vertmargin);
    padding-bottom: var(--nav_vertmargin);
    padding-right: var(--stm_margin);
    padding-left: var(--stm_margin);
}

header {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
}

#title {
    margin-left: 1.2rem;
    color: rgb(255, 255, 255);
}

header a {
    font-weight: bold;
    color: var(--nav_idle);
    font-size: 1.2em;
}

@media (min-width: 100000px) {
    header a {
        background-color: rgb(3, 49, 15);
        padding: 0.7rem;
        border-radius: 5rem;
    }
}

header a.router-link-exact-active {
    color: var(--nav_active);
}

#navbar {
    padding-right: var(--stm_margin);
    position: relative;
    left: 20px;
    margin-top: auto;
    margin-bottom: auto;
}

#plogo {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

h3 {
    margin-top: auto;
}

a {
    font-weight: bold;
}

/* Debug Styles */
.debug1{
    background-color: #B33F40;
}

.debug2{
    background-color: #48A14D;
}
.debug3 {
    background-color: #6577B3;
}
debug4{
    background-color: #EDD94C;
}
</style>