import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initPossibleMeApplication, debugBlock } from './scripts-possibleme/PossibleMe'
import PhoenixApp, { PhoneixConfig } from '@/scripts-core/core/PhoenixApp'
import FireAPI from './scripts/FireAPI'
import PossibleApp from "@/scripts/PossibleApp"
import StripePay from "@/scripts/StripePay"
import VCalendar from 'v-calendar';
import { possibleEnv, initConfig } from './Conf'
import { setModelMapping } from "@/possibleme/model/ModelMap"
import { Chart, registerables } from 'chart.js'


//The configureation for PossibleMe App
const applicationConfig : PhoneixConfig = {
    isDebug: possibleEnv.isDebug
}

setModelMapping();
initConfig(possibleEnv.isEmulate);

Chart.register(...registerables)

PossibleApp.config(new FireAPI(), new StripePay())
const clientApp = initPossibleMeApplication(applicationConfig)
PhoenixApp.initApp(clientApp)
createApp(App)
.use(store)
.use(router)
.use(VCalendar)
.mount("#app")


