<template>
    <div class='compose'>
        <h3 class='shift-down'>Managed Users</h3>
        <div class='managed-users'>
            <p v-if="managedUsers.length == 0" class='compose--center'>
                No managed users
            </p>
            <table class='compose w-full user-table'>
                <MUserRow  class='user-row' v-for="user in managedUsers" :key="user.id()" :user="user"/>
            </table>
           
        </div>
        <div v-if="showInput" class='fill'>
            <h4 class='mt-2'>New User Details</h4>
            <input class='fill' v-model="formFirstname" type="text" placeholder="First Name">
             <input class=' fill' v-model="formLastname"  type="text" placeholder="Last Name">
             <input class=' fill' v-model="formNDISNo"  type="text" placeholder="NDIS No">
             <input class=' fill' v-model="formBirthday"  type="date" placeholder="NDIS No">
             <h4 class='inline-block mr-4'>Add User</h4>
             <MeDone class='m-auto' @click="onSubmit"></MeDone>
        </div>
        <MeAction :full="true" @click="onAddUser" :labelText="'Add Managed User'" /> 
    </div>
</template>

<script lang="ts">
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject, { ObjectHelper } from "@/scripts-core/cloud/CloudObject";
import { ManagedUser } from "possibleme-db"
import { volUserManagedUser } from "possibleme-db/lib/Paths"
import { pushDb, user, collectionDb } from "@/scripts-core/CloudDB"
import { fromInputDate, toPossibleDate } from "possibleme-db/lib/util/DateHelper"
import {Options} from "vue-class-component"

import MUserRow from "@/components/possibleme/model/MUserRow.vue"
import MeAction from "../helper/MeAction.vue"
import MeDone from "../helper/MeDone.vue"

@Options({
    components : {
        MUserRow,
        MeAction,
        MeDone
    }
})
export default class MUser extends FireComponent {
    showInput = false
    managedUsers : CloudObject<ManagedUser>[] = []

    formFirstname = ""
    formLastname = ""
    formNDISNo = ""
    formBirthday = ""

    onUserReady(uid : string | null) : void {
        if(!uid) return
        //user here
        collectionDb<ManagedUser>(this.getContext(), volUserManagedUser(uid), users => {
            ObjectHelper.sort(this, users, (a, b)=>{
                if(a.firstname > b.firstname)
                    return 1;
                return -1;
            } )
            this.managedUsers = users;
        })

    }
    componentClass(): string {
        return "live-estimator"
    }
    onAddUser(){
        this.showInput = true
    }
    

    async onSubmit() {
        const parseDate = fromInputDate(this.formBirthday);
        let birthday : string | null = null
        if(parseDate)
            birthday = toPossibleDate(parseDate)
      
        const mUser : ManagedUser = {
            firstname: this.formFirstname,
            lastname : this.formLastname,
            birthday,
            ndisNo : this.formNDISNo
        }
        try {
            const uid = user();
            if(!uid)
                throw "no user here"
            await pushDb(this, volUserManagedUser(uid), mUser);
            this.formFirstname = ""
            this.formLastname = ""
            this.formNDISNo = ""
            this.formBirthday = ""
            this.showInput = false
        }
        catch(e){

            console.error(e);
        }
    }
 
}
</script>

<style scoped>

.widget-btn {
    border-radius: .5rem;
}

.managed-users {
    background: white;
    width: 100%;
    border-radius: .5rem;
}
input {
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: .5rem;
}

</style>