
import {Options} from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { ManagedUser } from "possibleme-db";
import ActionDialog, { ActionHandler } from "@/components/phoenix/features/ActionDialog.vue"
import { deletePlanFor } from "@/possibleme/Api"
import MeButton from "../helper/MeButton.vue"
import MeDone from "../helper/MeDone.vue"
import { toPossibleDate, fromInputDate, toInputDate, fromPossibleDate } from "possibleme-db/lib/util/DateHelper"

@Options({
  components : {
      ActionDialog,
      MeButton,
      MeDone
  }
})
export default class MUserRow extends FireComponent {
    isEditing = false;
    @Prop()
    user! : CloudObject<ManagedUser>

    deleteHandle! : ActionHandler

    inpFirstname = ""
    inpLastname = ""
    inpNDIS = ""
    inpBirthday = ""
    

    componentClass = () => "managed-user"
    onUserReady(uid: string|null): void {
        return
    }
    onConfirmDelete(){
        this.user.delete(this);
        deletePlanFor(this, this.user.id());
    }
    async onSubmit(){
        try {
            const val = this.user.value(this);
            val.firstname = this.inpFirstname
            val.lastname = this.inpLastname;
            val.ndisNo = this.inpNDIS

            const dat = fromInputDate(this.inpBirthday);
            if(dat == null)
                return {
                    message : "Error converting date to correct format"
                }

            val.birthday = toPossibleDate(dat);
            this.isEditing = false
            //val.birthday = 


        } catch(e : any){
            this.sendAlert("Error Submitting", `Error has occurred while submitting data (${e.message ?? JSON.stringify(e) ?? 'unknown'})`)
        }
    }
    onEditUser(){
        this.inpFirstname = this.user.value(this).firstname
        this.inpLastname = this.user.value(this).lastname ?? ""
        this.inpNDIS = this.user.value(this).ndisNo ?? ""
        const bday = this.user.object.birthday

       

        if(bday){
             const convertBday = fromPossibleDate(bday);
             this.inpBirthday = toInputDate(convertBday ?? new Date())
        }
            


        this.isEditing = true
    }
    onDeleteUser(){
        this.deleteHandle()
    }
    
    hanDelete (han : ActionHandler){
         this.deleteHandle = han
    }
}
