import PhoenixApp from "../../scripts-core/core/PhoenixApp";
import PhoenixContext from "../../scripts-core/core/PhoenixContext";
import LightArray from "../../scripts-core/util/LightArray"
import { Vue } from "vue-class-component"
import { uuid } from "vue-uuid";
import { WHAT_CONSOLE, MessageConsole, WHAT_ALERT, MessageAlert } from "../../scripts-core/PhoenixMessage"
import { CLASS_ALERT_DIALOG } from "../../scripts-core/ClassName"

/* Base Messaging interface */
const COMPONENET_LIST : LightArray<BaseComponent> = new LightArray(true)

export interface BaseComponent {
    componentClass() : string
    componenetID() : string
    onMessage(what : string, object : any) : any
}

type ComponentFilter = (comp : BaseComponent, tag : string) => boolean

const isClass : ComponentFilter = (comp, tag)       => comp.componentClass?.()  == tag
const isID :    ComponentFilter = (comp, tag)       => comp.componenetID()      == tag
const isAll :   ComponentFilter = ()                => true

/*REgister Functions*/
function registerComponent(comp : BaseComponent){
    if(!COMPONENET_LIST.contains(comp))
        COMPONENET_LIST.push(comp)
}
function registerSize(){
    return COMPONENET_LIST.size()
}
function deregisterComponent(comp  : BaseComponent){
    COMPONENET_LIST.eject(comp)
}

export function sendComponentMessage(checkFunc : ComponentFilter,  what : string, 
    object : any, tag : string,  
    responseCallback? : (what : string, object : any[]) => void, responseIDS? : () => void) {
    
    const arrayResponse : any[] = []
    const arrayIDS : string[] = []

    for(let i=0; i < COMPONENET_LIST.size(); i++) {
        const iComponent = COMPONENET_LIST.at(i)
        if(checkFunc(iComponent, tag)) {
            const response = iComponent.onMessage(what, object)
            if(response) {
                arrayResponse.push(response);
                arrayIDS.push(iComponent.componenetID())
            }
        }
    }
    responseCallback?.(what, arrayResponse)
}
export function sendComponentClass(what : string, object : any, aclass : string,  responseCallback? : (what : string, object : any[]) => void) {
    sendComponentMessage(isClass, what, object, aclass, responseCallback)
}
export function sendAlertMessage(title : string, message : string) {
    const alertMessage : MessageAlert = {
        title,
        message
    }
    sendComponentClass(WHAT_ALERT, alertMessage, CLASS_ALERT_DIALOG)
}
export default abstract class PhoenixComponent extends Vue implements BaseComponent {
    private context : PhoenixContext = new PhoenixContext(this.componentApp(), this.componenetID())
    private phoeixId! : string
    abstract componentClass():string
    onRefresh?() : void

    getContext() {
        return this.context
    }
    componenetID() : string {
        if(!this.phoeixId)
            this.phoeixId = uuid.v4()
        return this.phoeixId
    }
   
    componentApp():PhoenixApp {
        return PhoenixApp.getApp()
    } 
    
    mounted(){
        if(!this.context.isAlive()) {
            this.context = new PhoenixContext(this.componentApp(), this.componenetID())
        }
        registerComponent(this)
    }
    unmounted(){
        deregisterComponent(this)
        this.context.detach()
    }
    
    private sendMessage(checkFunc : ComponentFilter,  what : string, 
        object : any, tag : string,  responseCallback? : (what : string, object : any[])=>void, responseIDS?: () => void){
        sendComponentMessage(checkFunc, what, object, tag, responseCallback, responseIDS)
    }
    sendMessageClass= (what : string, object : any, aclass : string,  responseCallback? : (what : string, object : any[]) => void) =>
        this.sendMessage(isClass, what, object, aclass, responseCallback)
    
    sendMessageID = (what : string, object : any, id : string,  responseCallback? : (what : string, object : any[]) => void) =>
        this.sendMessage(isID, what, object, id, responseCallback)
    
    sendMessageAll = (what : string, object : any, responseCallback? : (what : string, object : any[]) => void) =>
        this.sendMessage(isAll, what, object, "", responseCallback)
    
    onMessage(what : string, object : any) : any {
        return true;
    }

    logme(message : string) {
        const newMessage : MessageConsole = {
            message
        }
        this.sendMessage(isAll, WHAT_CONSOLE, newMessage,"")
    }
    sendAlert(title : string, message : string) {
        sendAlertMessage(title, message);
    }
}
