
import PossibleApp from '@/scripts/PossibleApp';
import PossibleVue from '@/scripts/PossibleFramework';
import { Options } from 'vue-class-component';


@Options({
  components: {
    
  },
})

export default class Home extends PossibleVue {
  userMessage = "Hello World Message"
  showMessage = false
  loginEmail = "";
  loginPassword =  "";

  isSigningIn = false

  clickReset(){
    this.displayLoadingDEP(true);
    this.showMessage  =false;
    this.apiDEP().resetPassword(this.loginEmail, ()=>{
     
      this.userMessage = "Password reset email sent"
      this.showMessage = true;
      this.displayLoadingDEP(false);
    }, (msg)=>{
      this.userMessage = msg
      this.showMessage = true;
      
    })
  }
  clickLogin(){
    this.showMessage = false;
    this.isSigningIn = true
    this.apiDEP().login(this.loginEmail.trim(), this.loginPassword.trim(), ()=>{
      //Success login
      this.isSigningIn = false
      this.loginEmail ="";
      this.loginPassword= "";

      this.$router.push({path: "/manage"})

    }, (error) => {
      this.isSigningIn = false
      this.showMessage = true;
      this.userMessage = error;
    })
    
  }
}
