<template>
    <main>
        <article> 
           <div class='wrapper'>
               <div class='signform'>
                   <img id='logo' class="onlymobile" src="../assets/logo.png"/>
                   <h1>Account Details</h1>
                   <h3>Name</h3>
                   <input class='app-stdinput'  v-model="newFirstname"  type="text" placeholder="First Name">
                   <input class='app-stdinput'  v-model="newLastname"   type="text" placeholder="Last Name">
                   <h3>Email</h3>
                   <input class='app-stdinput'  v-model="newEmail"      type="text" autocomplete="false" placeholder="Email"/>
                   <h3>Password</h3>
                   <input class='app-stdinput' autocomplete="false" v-model="newPassword" type="password" placeholder="Password"/>
                   <input class='app-stdinput' autocomplete="false" v-model="confirmPassword" type="password" placeholder="Repeat Password"/>
                    <h3>Sign-up Promotion (Optional)</h3>
                   <div class='promotab'>
                      <input class= 'app-stdinput' v-model="promoCode" type="test" placeholder="Promo Code"/> 
                   </div>
                   <h3>Privacy & Terms of Service</h3>
                   <div>
                       <input class='app-stdinput' v-model="agree" type="checkbox" id="agreement" name="agreement" value="Agreement"/>
                        <label for='agreement'>I agree to the <router-link to="/terms" target="_blank" >
                        Terms of Service</router-link> and <router-link target="_blank" to="/privacy">Privacy Policy</router-link></label>
                   </div>
                   <button class='form-button' @click="clickNewAccount()">Create New Account</button>
                    <div v-if="showMessage" class="error">
                        <p>{{ userMessage }}</p>
                    </div>
               </div>
           </div>
        </article>
    </main>
</template>

<script lang="ts">
import { AccountDetails } from '@/scripts/PossibleAPI';
import PossibleVue from '@/scripts/PossibleFramework';
import { PossibleUser } from 'possibleme-db';

export default class Signup extends PossibleVue {
    newFirstname ="";
    newLastname  = "";
    newEmail = "";
    newPassword = ""
    confirmPassword = ""
    showMessage = false;
    userMessage = "Incorrect Email or Password"
    named = "Popcorn";
    agree = false

    promoCode = "";

    show(message : string){
        this.userMessage = message;
        this.showMessage = true;
    }
    hide = ()=> this.showMessage = false;
    

    async clickNewAccount(){
        this.showMessage = false;
        const fname = this.newFirstname.trim();
        const lname = this.newLastname.trim();
        const email = this.newEmail.trim();
        const password = this.newPassword.trim();
        
      
        //Check Firstname and lastname validity
        if(fname == "" || lname == "" ){
            this.show("First name & last name must not be empty")
            return;
        }
        //check has uder agreed to 
        if(!this.agree){
            this.show("You must agree to Terms of Service and Privacy policy to make an account")
            return;
        }
        if(this.newPassword != this.confirmPassword){
            this.show("Repeat password must match password")
            return;
        }
        this.displayLoadingDEP(true);
        //form is valid sign an account
        const newUser: PossibleUser = {
            firstname : fname,
            lastname : lname,
            ndisNo : "",
            birthday : "",
            options: {
            }
        }
        if(this.promoCode.trim() != ""){
            if(newUser.options) newUser.options.startPromo = this.promoCode.trim();//? op not working????
        }
        
        const newDetails : AccountDetails = {
            details : newUser,
            email,
            password
        }
        
        if(this.promoCode.trim() != ""){
            const isPromo = await this.apiDEP().verifyPromo(this.promoCode);
            if(!isPromo){
                this.show("Trial promotion is not a valid promo-code")
                this.displayLoadingDEP(false);
                return;
            }
        }
        this.apiDEP().newAccount(newDetails, ()=>{
            this.$router.push({path: "/manage"});
        }, (err) =>{
            this.displayLoadingDEP(false);
            this.show(err);
        }).catch(e =>{
            this.show(e.message);
            this.displayLoadingDEP(false);
        })
    }
}
</script>

<style scoped>
@media (max-width:500px) {
        #logo {
        display: block;
        margin: auto;
        margin-bottom:1rem ;
        width: 70px;
        height: 70px;
        } 
    }
    a {
        font-family: sans-serif;
    }
    .wrapper{
        display: flex;;
        justify-content: center;
    }

    input[type=checkbox]{
       width: 1rem;
       height: 1rem;
       vertical-align: middle;
    }
    label {
        margin-left: 1rem;
        vertical-align: middle;
        line-height: 1rem;
    }
    h3{
        font-size: 1.5rem;;
    }

    .promotab {
        display: flex;
        justify-content: space-between;
    }
</style>