<template>
    <div class='p-2'>
        <button class='text-center mr-3 p-2 pl-3 pr-3 shadow-gray-500 shadow-md min-w-[5rem] meauth rounded-3xl transition-all cursor-pointer'>
            {{labelText}}
        </button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  components : {
  }
})
export default class MeButton extends Vue {
    @Prop()
    labelText! : string
}
</script>

<style>
.meauth {
    background-color: purple;
    width: 100%;
    border-style: solid;
    border-color: salmon;
    
}
.meauth:hover {
    background-color: var(--color--accent);
    border-radius: 1rem;
}
</style>