<template>
    <div v-if="showPopup" class='app--fixed object-popup'>
        <div class='object-content mobile-full compose' :class="aclass" :style="astyle" >
            <div v-if="hideHeader != true"  class='object-header'>
                <h2 class='object-title'>{{title}}</h2>
                <button class='material-icons' v-if="cancelable || cancelable == undefined"  @click="closePopup(true)">close</button>
            </div>
            <div>
                <slot name="body"/>
                <slot name="footer"/>
            </div>
        </div>
        <div class='  app--fixed object-close' v-if="showConfirm" >
            <div class='widget-dialog object-close--inner'>
                <h3>Confirm Close</h3>
                <div style="max-width:300px" >Are you sure you want to leave?Changes you made will not be saved. </div>
                <div class='close-options'>
                    <button class='dialog--btn' @click="confirm(true)">Yes</button>
                    <button class='dialog--btn' @click="confirm(false)">No</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator"
export type PopupToggler = (state? : boolean, command? : PopupCommand) => void
export type PopupCommand = "done"

@Options({
    emits : ["toggler", "setConfirm"]
})
export default class PhoenixPopup extends Vue {
    @Prop()
    aclass! : string 
    @Prop()
    astyle! : string   
    @Prop()
    title! : string

    @Prop()
    cancelable! : boolean

    @Prop()
    confirmable = true

    @Prop()
    hideHeader! : boolean

    showPopup = false
    showConfirm = false

    toggler : PopupToggler = (state? : boolean, command? : PopupCommand) => {
        if(state === false && this.showPopup && command =="done") 
            this.showPopup = false
        else if(state === true && this.showPopup === false) 
            this.showPopup = true
        else if(state === undefined && this.showPopup === false) 
            this.showPopup = true
        else if(state === false && this.showPopup === true) 
            this.closePopup(true)  
    }
    clkMe() {
        return true;
    }
    setConfirmable(confirm: boolean) {
        this.confirmable = confirm;
    }

    mounted() {
        this.$emit("toggler", this.toggler)
        this.$emit("setConfirm", this.setConfirmable)
    }

    confirm(confirm : boolean) {
        if(confirm) {
            this.showPopup = false
            this.showConfirm = false
        } 
        else 
            this.showConfirm = false
    }

    closePopup(close : boolean) {
        if(close && !this.confirmable) 
            this.showPopup = false;
        else if(close && this.confirmable) 
            this.showConfirm = true
    }
}
</script>

<style scoped>
.object-title{
    padding-top: var(--stm-dialog--margin__top);
    padding-bottom: var(--stm-dialog--margin__top) ;
}

.object-popup {
    padding: 1rem;
    background-color: var(--color--blackout);
    display: flex;
    justify-content: center;
    z-index: 500;
}
.object-header {
    background-color: black;
    display: flex;
    justify-content: space-between;
    color: white;
    padding-left:var(--stm-dialog--margin__side); 

    position:sticky;
    top: 0;
    z-index: 500;
    
}
.object-header button {
    border-radius: 2rem;
    margin: var(--stm--margin__shrink);
    padding:var(--stm--margin__shrink);
    background: slateblue;
    color:white;
   
}
.object-header button:hover {
    background: tomato;
    color: white;
}

.object-close{
    display: flex;
    justify-content: center;
    background-color: var(--color--blackout);
    z-index: 600;
}
.object-close--inner{
    background-color: white;
    margin: auto;
}

@media(min-width :800px){
    .object-content {
        min-width: 800px;
    }
}

.object-content {
    background-color: white;
    margin: auto;
    overflow-y: auto;
  
    max-height: 100%;
    border-radius: var(--stm-widget-dialog--radius);
    
}
.close-options {
    display: flex;
    justify-content: space-between;
}

</style>