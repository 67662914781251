import PhoenixContext from "../core/PhoenixContext";
import CloudObject, { CloudArrayCallback } from "./CloudObject";
import { collectionDb, Dispose, ready, ReadyCall } from "../CloudDB"
import { Selection } from "../../scripts-possibleme/client/ClientHelpers";

export default class CloudList<T> { 
    private _list : CloudObject<T>[] = []
    private callback : CloudArrayCallback<T>;
    private disconn : Dispose | null = null
    private disposeAuth : Dispose | null = null
    private _path : string | null = null

    private cleared = true;
 
    //called when user state chanegs
    onUserReady : ReadyCall = (uid) => {
        if(!uid)
            this.resetList()
    }
    private localCall : CloudArrayCallback<T> = (items) => {
        this._list = items;
        this.callback(items);
    }

    constructor(context : PhoenixContext, callback : CloudArrayCallback<T>, triggers : Selection<any>[]) {
        this.callback = callback;
        for(let i=0; i < triggers.length; i++) {
            
            triggers[i].subscribe(context, "ongoing", sub => {
                this.trigger();
            }); 
        }
    }
    private resetList(){
        this._list = []
        this.disconn?.()
        this.disconn = null
        this._path = null
    }

    connect(context : PhoenixContext, collectionPath : string){
        if(!this.disposeAuth)
            this.disposeAuth = ready(this.onUserReady)

        this.cleared = false;
        if(this._path != null && this._path == collectionPath)
            return;
        this.disconn?.();
        this._path = collectionPath;
        this.disconn = collectionDb(context, collectionPath, this.localCall);
    }
    list() : CloudObject<T>[] {
        return this._list;
    }
    path(): string | null {
        return this._path;
    }
    trigger(){
        if(this._list.length > 0)
            this.callback(this._list);
    }
}