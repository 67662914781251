
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  components : {
  }
})
export default class MeButton extends Vue {
    @Prop()
    labelText! : string
}
