import PaymentAPI, { SubDetails, SubStatus } from "./PaymentAPI";
import { AccountDetails } from "./PossibleAPI";
import firebase from "firebase/app";
import { loadStripe } from '@stripe/stripe-js';
import { possibleEnv } from "@/Conf";

const STRIPE_PUBLIC_KEY = possibleEnv.stripeApiKey

export default class StripePay implements PaymentAPI {
    trialListen? : ()=>void

    constructor(){
        firebase.auth().onAuthStateChanged(user => {
            if(user){
                this.trialListen = firebase.firestore().doc(`trials/${user.uid}`).onSnapshot(snap =>{
                    if(snap.exists){
                        const timeTrial = snap.data();
                        const trialEnd = new Date(timeTrial?.trial_end.seconds * 1000);
                    } 
                })
            }
            else
                this.trialListen?.();
        })
    }
    pullSubscription(isSub? : (details: SubDetails)=>void, noSub? : ()=>void): void {
        const db = firebase.firestore();
     
        db.collection('customers')
            .doc(firebase.auth().currentUser?.uid)
            .collection('subscriptions')
            .where('status', 'in', ['trialing', 'active'])
            .onSnapshot(async (snapshot) => {
            // In this implementation we only expect one active or trialing subscription to exist.
            if(snapshot.size > 0){
                const data = snapshot.docs[0].data();

                const startPeriod = new Date(data.current_period_start.seconds * 1000);
                const endPeriod = new Date(data.current_period_end.seconds * 1000);

                const subId = data.items[0].price.id
             
                switch(data.status){
                    case "active": {
                         const subDetails : SubDetails = {
                            status : data.status,
                            sku_stripe : subId,
                            active : {
                                current_start : startPeriod,
                                current_end : endPeriod
                            }
                        }  
                        isSub?.(subDetails); 

                    }break;
                    case "trialing":{
                        const trialDetrails : SubDetails = {
                            status : "trial-active",
                            trial : {
                                trial_start : startPeriod,
                                trial_end : endPeriod
                            },
                            sku_stripe : subId
                        }
                        isSub?.(trialDetrails);
                    }
                }
            }
            else{
                const trialDoc = await db.collection('trials').doc(firebase.auth().currentUser?.uid).get();
                if(trialDoc.exists){
                    const trialData = trialDoc.data();
                    const startDate = new Date(trialData?.trial_start.seconds * 1000);
                    const endDate = new Date(trialData?.trial_end.seconds * 1000);
                    
                    const subDetails : SubDetails = {
                        status : "trial",
                        trial : {
                            trial_start : startDate,
                            trial_end : endDate
                        }
                    }
                    isSub?.(subDetails);
                    return;
                }
                noSub?.();
            }
  });
    }
    
    async subscribe(sku: string, details: AccountDetails, success?: () => void, err?: (e?: any) => void): Promise<void> {
        const stripePay = await loadStripe(STRIPE_PUBLIC_KEY);

        const docRef = await firebase.firestore()
        .collection("customers")
        .doc(firebase.auth().currentUser?.uid)
        .collection("checkout_sessions")
        .add({
            //TODO: Fix redirtect URLS
            price: sku,
            success_url: possibleEnv.stripeSuccess,
            cancel_url: possibleEnv.stripeCancel
        });

        docRef.onSnapshot((snap) =>{
            const data : any = snap.data();
            const { error, sessionId } = data;
            if(error)
                console.error(`Error: ${error.message}`);

            if(sessionId){
                stripePay?.redirectToCheckout({ sessionId });
            }
        })
    }
    isSubscribed(): boolean {
        return false;
    }
}
