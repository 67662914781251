
import { AccountDetails } from '@/scripts/PossibleAPI';
import PossibleVue from '@/scripts/PossibleFramework';
import { PossibleUser } from 'possibleme-db';

export default class Signup extends PossibleVue {
    newFirstname ="";
    newLastname  = "";
    newEmail = "";
    newPassword = ""
    confirmPassword = ""
    showMessage = false;
    userMessage = "Incorrect Email or Password"
    named = "Popcorn";
    agree = false

    promoCode = "";

    show(message : string){
        this.userMessage = message;
        this.showMessage = true;
    }
    hide = ()=> this.showMessage = false;
    

    async clickNewAccount(){
        this.showMessage = false;
        const fname = this.newFirstname.trim();
        const lname = this.newLastname.trim();
        const email = this.newEmail.trim();
        const password = this.newPassword.trim();
        
      
        //Check Firstname and lastname validity
        if(fname == "" || lname == "" ){
            this.show("First name & last name must not be empty")
            return;
        }
        //check has uder agreed to 
        if(!this.agree){
            this.show("You must agree to Terms of Service and Privacy policy to make an account")
            return;
        }
        if(this.newPassword != this.confirmPassword){
            this.show("Repeat password must match password")
            return;
        }
        this.displayLoadingDEP(true);
        //form is valid sign an account
        const newUser: PossibleUser = {
            firstname : fname,
            lastname : lname,
            ndisNo : "",
            birthday : "",
            options: {
            }
        }
        if(this.promoCode.trim() != ""){
            if(newUser.options) newUser.options.startPromo = this.promoCode.trim();//? op not working????
        }
        
        const newDetails : AccountDetails = {
            details : newUser,
            email,
            password
        }
        
        if(this.promoCode.trim() != ""){
            const isPromo = await this.apiDEP().verifyPromo(this.promoCode);
            if(!isPromo){
                this.show("Trial promotion is not a valid promo-code")
                this.displayLoadingDEP(false);
                return;
            }
        }
        this.apiDEP().newAccount(newDetails, ()=>{
            this.$router.push({path: "/manage"});
        }, (err) =>{
            this.displayLoadingDEP(false);
            this.show(err);
        }).catch(e =>{
            this.show(e.message);
            this.displayLoadingDEP(false);
        })
    }
}
