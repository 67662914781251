<template>
    <div >
        <textarea :class="areaClass"  :disabled="model.disabled" v-model="model.text" class='p-2 w-full rounded-lg' rows="3" :placeholder="hint"></textarea>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"

export type TextBlock = {
    text : string
    disabled? : boolean
}

@Options({
  components : {
  },
  emits : ["text"]
})
export default class MeText extends Vue {
    @Prop()
    hint! : string

    @Prop()
    model! : TextBlock

    @Prop()
    areaClass! : string

    @Watch("model.disabled")
    onDisable(){
        return;
    }
    
    mounted(){
        if(!this.model){
            this.model = {
                text : ""
            }
        }
        if(this.model.disabled == undefined)
            this.model.disabled = false
    }
}
</script>

<style>
.metext {
    background-color: #F7F7F7;
}
</style>