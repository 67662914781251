import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"
import "firebase/database"
import "firebase/functions"
import "firebase/analytics"

let isConfig = false

export type PossibleEnv = {
    isEmulate : boolean
    isDebug : boolean

    isDevelopment : boolean
    skuYear : string,
    skuMonth : string,
    skuHalfYear : string,

    stripeApiKey : string,
    stripeCancel : string,
    stripeSuccess : string

    appHost : string,
    appHostPortal : string
}

const isDevelopment = process.env["NODE_ENV"] == "development"

export const possibleEnv : PossibleEnv = {
    appHost : process.env["VUE_APP_HOST"],
    appHostPortal : process.env["VUE_APP_HOST_PORTAL"],

    isEmulate : process.env["VUE_APP_EMULATE"] == "true",
    isDevelopment,

    isDebug : process.env["VUE_APP_IS_DEBUG"] == "true" || isDevelopment,
    

    skuYear : process.env["VUE_APP_SUB_YEAR"],
    skuHalfYear : process.env["VUE_APP_SUB_HALFYEAR"],
    skuMonth : process.env["VUE_APP_SUB_MONTH"],

    stripeApiKey : process.env["VUE_APP_STRIPE_APIKEY"],
    stripeCancel : process.env["VUE_APP_STRIPE_CANCEL"],
    stripeSuccess : process.env["VUE_APP_STRIPE_SUCCESS"]
}

export function getFirebaseConfig() {
    const firebaseConfig = {
        apiKey: process.env.VUE_APP_FIRE_APIKEY,
        authDomain: process.env.VUE_APP_FIRE_AUTHDOMAIN,
        databaseURL: process.env.VUE_APP_FIRE_DATABASEURL,
        projectId: process.env.VUE_APP_FIRE_PROJECTID,
        storageBucket: process.env.VUE_APP_FIRE_STORAGEBUCKET,
        appId: process.env.VUE_APP_FIRE_APPID,
        measurementId: process.env.VUE_APP_FIRE_MEASUREMENTID
    }
    return firebaseConfig
}

export function emulateEnv(app : firebase.app.App) {
    app.firestore().useEmulator("localhost", 8081)
    app.auth().useEmulator("http://localhost:9099")
    app.storage().useEmulator("localhost", 9199)
    app.database().useEmulator("localhost", 9000)
    app.functions().useEmulator("localhost", 5001)
}

export function initConfig(emulate? : boolean) {
    if(isConfig) 
        return

    const app = firebase.initializeApp(getFirebaseConfig())
    firebase.analytics().setAnalyticsCollectionEnabled(true)
    if(emulate)
        emulateEnv(app)
    isConfig  = true
}