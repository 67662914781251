import PhoenixContext from "../core/PhoenixContext";
import CloudObject, { CloudObjectCallback } from "./CloudObject";
import { connectDb, Dispose, DocumentInit, ready, ReadyCall } from "../CloudDB"
import { Selection } from "../../scripts-possibleme/client/ClientHelpers";

export default class CloudList<T> { 
    private _document : CloudObject<T> | null = null
    private callback : CloudObjectCallback<T>;
    private disconn : Dispose | null = null
    private disposeAuth : Dispose | null = null
    private _path : string | null = null

    onUserReady : ReadyCall = (uid) => {
        if(!uid)
            this.resetList()
    }

    private localCall : CloudObjectCallback<T> = (document)=>{
        this._document = document;
        this.callback(document);
    }

    constructor(context : PhoenixContext, callback : CloudObjectCallback<T>, triggers : Selection<any>[]){
        this.callback = callback;
        for(let i=0; i < triggers.length; i++){
            triggers[i].subscribe(context, "ongoing", sub=>{
                this.trigger();
            });
        }
    }
    private resetList(){
        this._document = null
    }

    connect(context : PhoenixContext, document : string, init? : DocumentInit<T>) : void  {
        if(!this.disposeAuth)
            this.disposeAuth = ready(this.onUserReady)

        if(this._path != null && this._path == document)
            return;
        this.disconn?.();
        this._path = document;
        this.disconn = connectDb(context, document, this.localCall, init);
        
    }
    document() : CloudObject<T> | null {
        return this._document;
    }
    path() : string | null {
        return this._path;
    }
    trigger() : void {
        if(this._document != null)
            this.callback(this._document)
    }
}