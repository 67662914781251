<template>
    <div></div>
</template>



<script lang="ts">
import PossibleVue from '@/scripts/PossibleFramework';


export default class Account extends PossibleVue {

    created(){
        return
    }
    onReadyDEP(uid : string){
        if(this.apiDEP().currentUid()){
            this.$router.push("/manage");
        }
        else{
            this.$router.push("/login");
        }
    }
}

</script>

<style scoped>
    .content {
        
        background-color: steelblue;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        left: 25rem;
    }
</style>
