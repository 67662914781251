<template>
    <div :class="runClass" class='app-icon inline-block min-w-[2rem] min-w-[2rem] rounded-full p-2 cursor-pointer transition-all'>
         {{icon}}
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  components : {
  }
})
export default class MeDone extends Vue {
    @Prop()
    cancel! : boolean

    icon = "done"

    runClass = "medone"

    mounted(){
        if(this.cancel){
            this.icon = "close"
            this.runClass = "mecancel"
        }
    }
}
</script>

<style scoped>
.medone {
    background-color: var(--color--accent);
    color:white;
}
.mecancel {
    color: white;
    background-color: crimson;
}
.mecancel:hover {
    background-color: rgb(10, 10, 10);
    transform: scale(1.2);
}
.medone:hover {
    background-color: purple;
    transform: scale(1.2);
}
</style>
