
import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import MeButton from "@/components/possibleme/helper/MeButton.vue"
import { MessageAlert, WHAT_ALERT } from "@/scripts-core/PhoenixMessage";

import MeDone from "./MeDone.vue";

const TEXT_SIZE = 100

type RunClass = "regular" | "grow"

@Options({
    components : {
        MeDone,
        MeButton
    }
})
export default class MeAlert extends FireComponent {
    title = ""
    content : string[] = []
    show = false

    runClass : RunClass = "regular"
    
    componentClass = () => "mealert"
    onUserReady(): void {
        return;
    }
    
    onMessage(what : string, object : any){
        if(what == WHAT_ALERT){
            const obAlert : MessageAlert = object;
            this.title = obAlert.title;
            this.content = obAlert.message.split("\n");
            if(obAlert.message.length > TEXT_SIZE)
                this.runClass = "grow"
        }
        this.show = true;
    }
}
