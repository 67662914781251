
import { Options} from "vue-class-component"
import { Prop } from "vue-property-decorator"
import ListDefine, {ListStyle} from '@/scripts-core/model/ListModel'
import PhoenixListItem from './PhoenixListItem.vue'
import PhoenixComponent from "../PhoenixComponent"

@Options({
    props:{
        listData : Object,
    },
    components :{
        PhoenixListItem
    }
})

export default class PhoenixList extends PhoenixComponent {
    listData! : ListDefine[]
    @Prop()
    listStyle!: ListStyle;

    
    componentClass(){
        return "phoenix-list"
    }

}
