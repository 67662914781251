<template>
    <div class='compose ndis-banner'>
        <MobileMenu/>
        <h1 class='p-2 pl-4 plan-banner'>
            Selected Plan - {{planName}}
        </h1>
        <div class='custom-banner pl-4 custom pb-2'>
            <slot name="body"/>
        </div>
        
    </div>
</template>

<script lang="ts">
import { subscribeCurrentPlan, getCurrentPlan } from "@/scripts-possibleme/client/PossibleClient"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { Options } from "vue-class-component"
import {CLASS_PLAN_BANNER, WHAT_PLAN_TITLE} from "../CompDef"
import MobileMenu from "@/components/mobile/MobileMenu.vue"

const EMPTY_PLAN = ""

@Options({
    components : {
        MobileMenu
    }
})
export default class PlanBanner extends FireComponent {
    planName = EMPTY_PLAN

    mounted(){
        const plan = getCurrentPlan();
        if(plan != null)
            this.planName = plan.value(this).name
    }

    onUserReady() : void {
    
        subscribeCurrentPlan(this.getContext(), plan =>{
            if(!plan)
                this.planName = EMPTY_PLAN
            else
                this.planName = plan.value(this).name
        })
     }
    componentClass(): string {
        return CLASS_PLAN_BANNER
    }
    onMessage(what : string, object : any){
        if(what == WHAT_PLAN_TITLE)
            this.planName = object
    }
 
}
</script>

<style scoped>

.plan-banner{
    
    color:white;
    display: inline-block;
    padding: var(--stm--margin__shrink);
    padding-left: var(--stm--margin);;
    padding-right: var(--stm--margin);;
    margin-bottom: .5rem;
    z-index: 50;
}



.ndis-banner {
    background-color:var(--color--accent);
    position: sticky;
    top:0;
    z-index: 10;
    
}
.custom {
    background-color:white ;
}
a {
    font-size: 1.4rem;
}
.portal {
    
    gap:.5rem;
    justify-content: left;
    padding-top: 1rem;
}

</style>