
import { PossibleUser, UserTrial } from "possibleme-db"
import { Options } from "vue-class-component"
import PossibleApp from '@/scripts/PossibleApp';
import PossibleVue from '@/scripts/PossibleFramework';
import * as dateutil from "possibleme-db/lib/util/DateHelper"
import ManagedUser from "@/components/possibleme/widgets/ManagedUser.vue"
import PlanBanner from "../components/possibleme/PlanBanner.vue"
import MeAction from "../components/possibleme/helper/MeAction.vue"
import { possibleEnv } from "../Conf";


const select_color = "var(--prime)";
@Options({
    components : {
        ManagedUser,
        PlanBanner,
        MeAction
    }
})
export default class Manage extends PossibleVue {
    isVerified = true;
    currentEmail = "N/A"

    subImage = "";

    changePassCurrent = ""
    changePassNew = ""
    

    newFirstname = "";
    newLastname = "";
    newNdis = "";
    newBirthday = "";

    trialStart = "N/A";
    trialEnd = "N/A";

    skuMonth = possibleEnv.skuMonth
    skuYear = possibleEnv.skuYear

    newSubscription = "";
    status = "pending";

    selectMonth = "";
    selectHalf = "";
    selectYear = "";

    fieldEm = ""
    fieldPa= ""

    subscribeDetails = "TODO Details"

    trialInterval = "from"

    //handler for loading
    handlerPortal = (isLoading: boolean) => { return }
    handlerSubscribe = (isLoading: boolean) => { return }

    /* Resemnts Email Verification */
    clickResend(){
        this.apiDEP().sendEmailVerification(() => {
            this.sendAlert("Verify Email", "An email verification email has been sent. Please check your inbox")
        })
    }
    setImageSub(sku : string){
        switch(sku){
            case this.skuMonth:this.subImage = "/sub_month.png";break;
            case this.skuYear:this.subImage = "/sub_year.png";break;
        }
    }
    onReadyDEP(uid: string | undefined){
        this.contextDEP().send(1, false);
        this.fieldPa = "";
        this.fieldEm = "";

        if(uid){
            this.isVerified = this.apiDEP().isEmailVerified();
            /*Check email verified*/
            if(!this.apiDEP().isEmailVerified()){
                this.isVerified = false;
                this.currentEmail = this.apiDEP().currentEmail();
                //Query verified is a override as redirect from /verify does not update verified straight away
                const queryVerified = this.$route.query.emailVerified == "true"
                if(queryVerified)
                    this.isVerified = true;
            }
            PossibleApp.Instance().getPayment().pullSubscription((sub) => {
                if(sub.status == "trial-active") {
                    this.setImageSub(sub.sku_stripe?? "");
                    this.subscribeDetails = `Subscription will start at end of trial period`
                }
                else if(sub.status == "active"){
                    const start = dateutil.toPossibleDate(sub.active?.current_start ?? new Date())      
                    const end = dateutil.toPossibleDate(sub.active?.current_end ?? new Date())        
                    this.setImageSub(sub.sku_stripe?? "");
                    this.subscribeDetails = `Plan period is from ${start} - ${end}`
                }
                this.status = sub.status;
            }, ()=>{
                this.status = 'none'
            })
            /*New Seconds */
            this.fastDocument(`trials/${uid}`, data =>{
                const mytrial  = data as UserTrial

                this.uiSetTrial(mytrial.trial_start.toDate(), mytrial.trial_end.toDate());
            })
        }
        this.apiDEP().watch(this.contextDEP(), `users/${uid}`, caller => {
            this.newFirstname = caller.firstname?? "";
            this.newLastname = caller.lastname ?? "";
            this.newNdis = caller.ndisNo ?? "";
            try{
                const parts = caller.birthday.split("/");
                this.newBirthday = `${parts[2]}-${parts[1]}-${parts[0]}`
            }catch(e){
                return;
            }   
        });
        /* New Section */
    }
    newSubscribe(price : string ){
        this.selectMonth = "";
        this.selectHalf = "";
        this.selectYear = "";

        if(price == this.skuMonth)
            this.selectMonth = select_color;
        if(price == this.skuYear)
            this.selectYear = select_color
        this.newSubscription = price;
    }
    clickChangeEmail(){
      
        PossibleApp.Instance().sendMessage(this.contextDEP(), 1, true);
        this.apiDEP().changeEmail(this.fieldEm, this.fieldPa, () =>{
            this.sendAlert("Email Changed", "You have successfully changed your email")
            //alert("Email Successfully Changed")
            PossibleApp.Instance().sendMessage(this.contextDEP(), 1, false);
        }, msg =>{
            this.sendAlert("Email Error", msg);
           
            //PossibleApp.Instance().sendMessage(this.contextDEP(), 1, false);
        });
    }

    clickChangePassword(){
        this.apiDEP().changePassword(this.changePassCurrent, this.changePassNew, () => {
            this.sendAlert("Password Changes", "Your password has been successfully changed")
            
        }, (msg) =>{
            console.error(msg);
            this.sendAlert("Login Error", msg);
        })
    }
    clickUpdate() {
        this.handlerPortal(true)
        PossibleApp.Instance().sendMessage(this.contextDEP(), 1, true);
        this.apiDEP().redirectPortal();
    }
    clickCheckout(){
        this.handlerSubscribe(true)
        PossibleApp.Instance().sendMessage(this.contextDEP(), 1, true);
        PossibleApp.Instance().getPayment().subscribe(this.newSubscription);
    }
    clickDetails(){
        const parts = this.newBirthday.split("-")
        const dateFormatted = `${parts[2]}/${parts[1]}/${parts[0]}`
        /*
        firstname : this.newFirstname,
            lastname : this.newLastname,
            email : "",
            password : "",
            ndisNo : this.newNdis,
            birthday : dateFormatted
        */
       const updateUser : PossibleUser = {
           firstname : this.newFirstname,
           lastname : this.newLastname,
           birthday : dateFormatted,
           ndisNo : this.newNdis
           
       }
        this.apiDEP().updateUser(updateUser, () =>{
            this.sendAlert("Details Updated", "Your details have been updated successfully")
            // /alert("User Details Updated");
        }, (msg) =>{
            return;
        })
    }

    uiSetTrial(start : Date, end : Date){
      this.trialInterval = `Trial is from ${start.toDateString()} - ${end.toDateString()}`
    }
}
