<template>
    <div :class="runClass" class='module-meaction pt-2 pr-2 pb-2 inline-block'>
        <button :class="runClass" class='text-center mr-3 p-2 pl-3 pr-3 meaction rounded-full transition-all cursor-pointer mobile-full'>
            {{labelText}}
        </button>
        <div v-if="isLoading" class='pt-2 pb-2'>
            <div class='loader action-loader'></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"

@Options({
  components : {
  }, 
  emits: ["loadingHandler"] 
})
export default class MeAction extends Vue {
    @Prop()
    labelText! : string

    @Prop()
    full! : boolean

    isLoading = false

    runClass = ""
    mounted(){
        if(this.full)
            this.runClass = "w-full pl-2"
        this.$emit("loadingHandler", this.setLoading)
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }
}
</script>

<style scoped>

@media(min-width: 500px) {
    .module-meaction {
        min-width: 10rem;
    }
}

@media(max-width: 500px) {
    .module-meaction {
        
        font-size: .2rem;
    }
    button {
        font-size: .7rem;
    }
}

.meaction {
    background-color: var(--color--primary);
    
    display: inline-block;
    color: white;
}
.meaction:hover {
    background: var(--color--accent);
    transform: scale(1.1);
}
.meaction:active {
    background: var(--accent-dark);
}

.action-loader {
    width: 16px;
    height: 16px;
    padding: .5rem;
    margin: auto;
}

@media(max-width: 500px) {
    
}

</style>