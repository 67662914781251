

import { Vue, Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"

export type LoadHandler = (loading : boolean, msg? : string) => void

@Options({
    emits: ["handler"]
})
export default class PhoenixButton extends Vue {
    @Prop()
    labelText! : string

    message = ""

    @Prop()
    click ! : (handle : LoadHandler) => void
    loading = false
    showError = false

    show : LoadHandler = (loading, msg) => {
        this.loading = loading
        if(msg)
            this.message = msg
    }
    mounted() {
        this.$emit("handler", this.show)
    }
    onClick() {
        if(!this.loading)
            this.click?.(this.show)
    }
}
