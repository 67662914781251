import { HelpObject, HelpPath, PathHelper, PojoHelper, PossibleHelper } from "../Helper"
import { sha256 } from "js-sha256"

export default class ImpPossibleHelper implements PossibleHelper {
    object: HelpObject = (object) => new ImpPojoHelper<any>(object);
    path: HelpPath = (path) => new ImpPathHelper(path);

}

class ImpPathHelper implements PathHelper {
    private _path : string

    constructor(path : string){
        this._path = path
    }

    lastSegment(): string {
       return this._path.substr(this._path.lastIndexOf("/")+1)
    }
}

/* Copied from 'cloud-coffee' */
class ImpPojoHelper<T> implements PojoHelper<T> {
    private val : T
    constructor(val : T) {
        this.val = val;
    }
    same(another: T): boolean {
        const compare = new ImpPojoHelper(another);
        return this.sha256() === compare.sha256();
    }
    sha256(): string {
        return sha256(this.json())
    }
    is(another: T): boolean {
        return Object.is(this.val, another);
    }
    copy(): T {
        return JSON.parse(JSON.stringify(this.val));
    }
    hasKeys(keys: string[]): boolean {
        for(let i=0; i < keys.length; i++)
            if(!this.hasKey(keys[i]))
                return false;
        return true;
    }
    hasKey(key: string): boolean {
        return this.getPath(key) != undefined
    }
    setPath(path: string, val: any): void {
        try{
            const seg = path.split(".");
            let object = this.val as any;
            for(let i=0; i < seg.length-1; i++)
                object = object[seg[i]];
            object[seg[seg.length - 1]] = val;
        }catch(e) {
            return undefined
        }
    }
    getPath(path: string) {
        try{
            const seg = path.split(".");
            let object = this.val as any;
            for(let i=0; i < seg.length; i++)
                object = object[seg[i]];
            return object;
        }catch(e){
            return undefined
        }
    }
    json(): string {
        return JSON.stringify(this.val, Object.keys(this.val).sort());
    }
}
