
import CloudComponent from "./CloudComponent.vue";
import firebase from 'firebase/app'

type AuthDispose = () => void

export default abstract class FireComponent extends CloudComponent {
    onUserReady?(uid : string | null) : void
    onEnter?(uid : string) : Promise<void>
    onLeave?() : Promise<void>
    authDispose? : AuthDispose | null

    redirect404(msg? : string) {
         this.$router.push({
            path : "/404"
        })
    }

    mounted() {
        this.authDispose = firebase.auth().onAuthStateChanged(user => {
            const uid : string | null = user?.uid ?? null
            this.onUserReady?.(uid);
            if(uid)
                this.onEnter?.(uid)
            else
                this.onLeave?.()
        });
    }
    unmounted() {
       this.authDispose?.()
       this.authDispose = null
    }
    /* Use Case Functions */
    openUrlLinkNewTab(url : string){
       window.open(url, "_blank")
    }
}
