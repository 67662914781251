<template>
    <div class='app--fixed dialog-action'   v-if="show">
        <div class='action-dialog-inner widget-dialog'>
            <h1>{{title}}</h1>
            <div class='mt-2' v-for="msg in display" :key="msg">
                {{msg}}
            </div>
            <div class='mt-2 alert-dialog-button'>
                <button @click="onYes">Yes</button>
                <button @click="onNo">No</button>
            </div> 
        </div>
    </div>
</template>

<script lang="ts">

import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator";
import MeButton from "../../possibleme/helper/MeButton.vue";

export type ActionHandler = () => void
export type ActionClick = () => void

@Options({
    components : {
        MeButton
    },
    emits : ["handler"]
})
export default class ActionDialog extends Vue {
    @Prop()
    title! : string
    @Prop()
    message! : string
    show = false;
    
    @Prop()
    yes! : ActionClick
    @Prop()
    no ! : ActionClick 

    display : string[] = []

    handler : ActionHandler = () => {
        this.show = true
    }

    mounted() {
        this.$emit("handler", this.handler)
        this.display = this.message?.split("\n") ?? ""
    }

    onYes() {
        this.show = false
        this.yes?.()
    }

    onNo() {
        this.show = false;
        this.no?.()
    }
}
</script>

<style scoped>
.dialog-action {
    display: flex;
    justify-content: center;
    background-color: rgb(0, 0, 0, .2);
    z-index: 200;
}

h1 {
    font-size: 2rem;
}

.action-dialog-inner {
    max-width: 400px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    background: white;
    display: block;
}
.alert-dialog-button {
    bottom: 0;
    right: 0;
    margin-top: .5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}
</style>