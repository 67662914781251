<template>
<div>
    <div :class="itemStyle.titleClass">
        <div @click="clickItem" class="phx-listitem_inner ">
            <img :src="itemDefine.prefix_icon" v-if="false" alt="">
            <span class="material-icons">
            {{micon}}
            </span> 
            <h1>
                {{itemDefine.title}}
            </h1>
        </div>
        <slot v-if="hasSlot('header')" name="header" :class="itemStyle.headerClass"/>
        <slot v-if="hasSlot('body')" name="body" :class="itemStyle.bodyClass"/>
        <slot v-if="hasSlot('footer')" name="footer" :class="itemStyle.footerClass"/>
        
        
    </div>
</div>
    
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator"
import ListDefine, { ListStyle } from "@/scripts-core/model/ListModel"
import PhoenixComponent from "../PhoenixComponent";


export const  DEFAULT_LIST_STYLE : ListStyle = {
    titleClass : "phxclass-default-list-title",
    headerClass : "phxclass-default-list-header",
    bodyClass : "phxclass-default-list-body",
    footerClass : "phxclass-default-list-footer"
}

export default class PhoenixListItem extends PhoenixComponent {
    componentClass(): string {
        return "phoenix-list-item"
    }
    @Prop()
    itemHandler!: (index : number, object : any)=>void

    @Prop()
    itemObject!:any

    @Prop()
    itemDefine!: ListDefine
    micon = ""
    @Prop()
    itemIndex! : number

    @Prop()
    itemTitleId! : string

    @Prop({default : DEFAULT_LIST_STYLE})
    itemStyle!: ListStyle;

    hasSlot(name : string){
        return this.$slots[name];
    }

    clickItem(){
        if(this.itemDefine.route){
            this.$router.push({path : this.itemDefine.route, query : this.itemDefine.route_params ?? "",params : this.itemDefine.route_params ?? ""})
        }
        else {
            this.itemHandler?.(this.itemIndex, this.itemObject)
        }
    }

    getMaterialIcon(path : string):string {
        const parts = path.split("/");
        const icon = parts[1];
        return icon;
    }

    created(){
        if(this.itemDefine.prefix_icon?.startsWith("@micon")){
            const icon = this.getMaterialIcon(this.itemDefine.prefix_icon);
            this.micon = icon
        }
    }
}
</script>

<style>
    .phx-listitem_inner {
        display: flex;
        padding: 0rem;
        margin: 0px;
        padding: 0px;
       
    }

    span {
        margin-top: auto;
        margin-bottom:auto
    }
    .phxclass-default-list-title {
        background-color: lavenderblush;
        font-size: .5rem;
    }

    .phxclass-default-list-title:hover {
        background-color: lightblue;
    }
    .phxclass-default-list-title h1 {
        font-size: 1.5rem;
        font-family:Arial, Helvetica, sans-serif
    }

    h1 {
        margin-top:auto;
        margin-bottom:auto ;
    }
</style>

