import PhoenixApp, { PhoenixDebugger, PhoneixConfig } from "@/scripts-core/core/PhoenixApp";

let possibleMeApplication : PhoenixApp

class PossibleMe extends PhoenixApp {
 
    constructor(appname : string, config : PhoneixConfig) {
        super(appname, config);
    }

    static Instance() : PossibleMe {
        return possibleMeApplication
    }
}

export function initPossibleMeApplication(config : PhoneixConfig) : PossibleMe {
    if(possibleMeApplication)
        return possibleMeApplication
    possibleMeApplication = new PossibleMe("PossibleMe", config)
    return possibleMeApplication
}

export function application() : PhoenixApp {
    return possibleMeApplication
}

export function debugBlock(tag : string, callback : (debug : PhoenixDebugger) => void) {
    possibleMeApplication.debugBlock(tag, callback)
}