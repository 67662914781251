/* Helper methods to control output on Possible ViewCLient*/
import CloudObject, { CloudAccess, ObjectHelper } from "../../scripts-core/cloud/CloudObject";

import { dateInside, DateInterval, fromMillis, toInterval } from "possibleme-db/lib/util/DateHelper";
import { ManagedUser, NDISPlan, Support} from "possibleme-db";

export function validPlan(access : CloudAccess, plans : CloudObject<NDISPlan>[], planId: string | null,  start : Date, end : Date){
    
    for(let i=0; i < plans.length; i++){
        if(planId && planId == plans[i].id())
            continue

        const iplan = plans[i].value(access);
        const planInterval = toInterval(iplan.dateStart, iplan.dateEnd);
        
        if(dateInside(start,planInterval)) return false;
        if(dateInside(end, planInterval)) return false;
    }
    return true;
}

export function currentPlan(array : CloudObject<NDISPlan>[]) : CloudObject<NDISPlan> | null {
    if(array.length == 0) return null;
    return array[0];
    //TODO:implement Plan select
}

export function bucketPlans(caller : CloudAccess, sub : CloudObject<NDISPlan>[]) : [current : CloudObject<NDISPlan>[], upcoming : CloudObject<NDISPlan>[],future : CloudObject<NDISPlan>[]] {
    const now = Date.now();
    const planCurrent = ObjectHelper.filter(caller, sub , a => a.dateStart <= now && a.dateEnd >= now) ?? []
    const planUpcoming = ObjectHelper.filter(caller, sub , a => a.dateStart > now) ?? []
    const planOld = ObjectHelper.filter(caller, sub , a => a.dateEnd < now) ?? []

    return [planCurrent, planUpcoming, planOld]
}

/**
 * Checks if 2 plans with same user has a conflict
 * @param caller 
 * @param toAdd 
 * @param existing 
 * @param checkUser 
 * @param plans 
 * @returns 
 */
export function containsPlanConflict(caller : CloudAccess, checkInterval : DateInterval, existing : CloudObject<NDISPlan> | null, checkUser : CloudObject<ManagedUser> | null, plans : CloudObject<NDISPlan>[]): boolean {
    
    
    for(let i=0; i < plans.length; i++){
        //skip check if existing is same reference as current
        const iPlanRef = plans[i];
        const iPlan = iPlanRef.value(caller);
        if(existing && iPlanRef?.id() === existing?.id())
            continue
        //check user is the same
        let sameUser = false;
        if(checkUser && typeof checkUser.id == "function"){
            sameUser = checkUser?.id() == iPlan.managedUser;
        }
        else
            sameUser = iPlan.managedUser == null
    
        if(!sameUser)
            continue
        //User should be the valid same to check conflict
        const planInterval = toInterval(iPlan.dateStart, iPlan.dateEnd);
      
        if(planInterval.overlaps(checkInterval) || checkInterval.overlaps(planInterval))
            return true;
        
    }
    return false;
}