<template>
    <div class='compose--app compose--flex bg-gray-200'>
        <div class='possible-sideframe app--hide-mobile possible--side-style app--top-level'>
            <img class='side-logo' src="/logo.png" alt="">
            <h4 class='menu-title'>PossibleMe</h4>
            <div>{{email}}</div>
            <div class='mb-3'>{{name}}</div>
            <PossibleMenu2>
            </PossibleMenu2>
            <MeAuth @click="logout"  :labelText="'Logout'"></MeAuth>
           
        </div>
        <div class='possible-mainframe pb-3'>
            <router-view/>
        </div>
    </div>
</template>

<script lang="ts">
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { Options } from "vue-class-component"
import PossibleMenu2 from "@/components/possibleme/PossibleMenu2.vue"
import { uuid } from "vue-uuid";
import { startClient } from "@/scripts-possibleme/client/PossibleClient"
import { signout, connectDb } from "@/scripts-core/CloudDB"
import { PossibleUser } from "possibleme-db"
import { WHAT_ALERT, MessageAlert } from "@/scripts-core/PhoenixMessage"
import ObjectPopup from "@/components/phoenix/ObjectPopup.vue"
import { PopupToggler } from "@/components/phoenix/ObjectPopup.vue";
import { resumeState } from "@/scripts-possibleme/client/PossibleClient"
import MeAuth from "../../components/possibleme/helper/MeAuth.vue"
import { APP_MAIN, logEventPageView } from "@/possibleme/PossibleAnalytics";

@Options({
    components :{
        PossibleMenu2,
        ObjectPopup,
        MeAuth
    }
})
export default class AppMain extends FireComponent {
    showMenu = false;
    email = ""
    dismount!  : ()=>void

    name = "";

    //alert : MessageAlert | null = null
    alertTitle ! : string
    alertContent : string[] = []

    toggler! : PopupToggler

    onAlertPop(toggler : PopupToggler){
        this.toggler = toggler;
    }
    onClose(){
        this.toggler(false, "done");
    }

    mounted(){
        logEventPageView(APP_MAIN)
        if(this.$route.query.select){
            const select = this.$route.query.select as any
            resumeState({
                select
            })
        }
    }
    async logout(){
        try{
            await signout();
        }catch(e){console.error()}
    }

    unmounted(){
        this.dismount?.();
    }
    onUserReady(uid: string | null) : void {
        if(uid == null) {
            this.$router.push({
                path : "/"
            })
            return;
        }
        this.dismount = connectDb<PossibleUser>(this.getContext(), `/users/${uid}`, puser => {
            const iuser =puser.value(this);
            this.name = `${iuser.firstname} ${iuser.lastname}`
        })
        //User here logic
        startClient()//TODO:? move this to main?????
        return;
    }
    onMessage(what : string, object : any){
        if(what == WHAT_ALERT){
            const obAlert : MessageAlert = object;
            this.alertTitle = obAlert.title;
            this.alertContent = obAlert.message.split("\n");
            this.toggler(true);
        }
    }
    componentClass(): string {
        return "app-main"
    }
    genNumber(){
        return uuid.v4()
    }
}
</script>

<style>

:root {
    --sideframe_width: 300px;
 
}
.menu-wrap {
    width: 100%;
}

.app-alert{
  background: white;
  display: block;
  position: fixed;
  top :0;
}

.side-logo {
    width: 100px;
    height: 100px;
    display: block;

    text-align: center;
    margin: auto;
    padding: 0;
}

.possible-sideframe {
    overflow-y: auto;
    overflow-x: hidden;
    min-width: var(--sideframe_width)
}

.possible-mainframe {
    overflow-y: auto;
    flex-grow: 1;
}

.possible-mainframe .indent {
    padding-left: var(--stm--margin);
    padding-right: var(--stm--margin);
}
.menu-title{
    margin-top: 1.2rem;
}

.possible-mobile-menu {
    z-index: 2000;
}

.possible-menu--logout {
    width: 100%;
    background-color: var(--color--accent);
    color: white;
    
    padding: .3rem;
    margin-top: var(--stm--margin);
  }
.possible-menu--logout:hover {
    background-color: var(--color--unspent);
}


/* Child Header class for componenets*/
.main-frame--header {
    padding-bottom: var(--stm--margin);
}
  /* Style Sample 1 */
.possible--side-style {
    background-color: var(--color--primary);
    color:rgb(255, 255, 255);
}
.alert-dialog {
    min-width: 0px;
}

.actions {
    display: flex;
    justify-content: right;
}


.content-search{
  display: flex;
}

</style>