
import { Watch } from "vue-property-decorator"
import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import MoneyInput from "@/components/possibleme/helper/MoneyInput.vue"
import Submit, { LoadHandler } from "@/components/phoenix/PhoenixSubmit.vue"

import MeAction from "../../components/possibleme/helper/MeAction.vue"
import MeAlert from "../../components/possibleme/helper/MeAlert.vue"
import MeArea, { TextBlock } from "../../components/possibleme/helper/MeArea.vue"
import MeButton from "../../components/possibleme/helper/MeButton.vue"
import { pushDb } from "@/scripts-core/CloudDB";

@Options({
    components : {
        MoneyInput,
        Submit,
        MeButton,
        MeAction,
        MeAlert,
        MeArea
    }
})
export default class DebugVue extends FireComponent {
    blocks : TextBlock = {
        text : "this is some data"
    }

    aTitle = ""
    aMessage = ""

    click! : LoadHandler

    vtext = ""

    @Watch("blocks.text")
    print(){
        //DebugNote(this.blocks.text);
    }

    componentClass = () => "@debug-fail:DebugVue.vue"
    onUserReady(): void {
        return;
    }
    onAmount = (args : string) => {return}

    loader = (handle : LoadHandler) => {
        this.click = handle
        handle(true)

        setTimeout(()=>{
            handle(false, "Some message here")
        }, 5000)

        
    }
    btnAlert() {
        this.sendAlert(this.aTitle, this.aMessage);
    }
    btnSubmit(){
        pushDb<any>(this, "/test/app-debug/list", {   
            data : this.vtext
        })
    }
    
}
