import { sha256 } from "js-sha256";
import Context, { Cloud } from "../../Cloud";
import { mapClassType } from "../CloudDB"

const UPDATE_RESOLUTION = 1000;

type ObjectSpec<T> = {
    object  : T
    path : string
}
export type ValueMsg = "request-made" | "request-error"
export type ValueCall = (msg : ValueMsg) => void

export function createObject<T>(context  : Cloud, access : CloudAccess, spec : ObjectSpec<T>): CloudObject<T> {
    spec.object = mapClassType(spec.path, spec.object);
    return new PossibleObject(context, access, spec);
}

export type CloudError = {
    message: string,
    caller : CloudAccess,
    error : any
}

export type CloudComplete = (success: boolean, err?:CloudError)=>void
export type CloudObjectCallback<T> = (object : CloudObject<T>) =>void
export type CloudArrayCallback<T> = (array : CloudObject<T>[])=>void
export interface CloudAccess {
    getAccessName():string
    onError?(objectError : ObjectError) : void
}

export interface ObjectError {
    objectPath :string
    object? : any
    message : string,
    err : any
}


export default interface CloudObject<T> {
    object : T

    copy() : T
    hashContent() : string
    json() : string
    id() : string
    path() : string
    collection() : string
    value(access : CloudAccess, callback? : ValueCall) : T
    update(access : CloudAccess) : Promise<void>
    write(access: CloudAccess, callback : (data : T) => void): Promise<void>

    delete(access : CloudAccess) : Promise<void>
    cast<C>(access : CloudAccess, callback? : ValueCall) : C

    apply(callback : (val : T) => void) : CloudObject<T>
}


class PossibleObject<T> implements CloudObject<T> {
    private _cloud : Cloud
    
    private isUpdating = false;

    private objectId : string
    private objectPath : string
    object : T
    private contentHash : string
    private ownerAccess : CloudAccess

    private disableProxy = false;

    constructor(cloud : Cloud, access : CloudAccess, spec : ObjectSpec<T>){
        this._cloud = cloud
        this.ownerAccess = access;
        this.objectId = cloud.helper.path(spec.path).lastSegment()
        this.objectPath = spec.path;
        this.object = spec.object;
        this.contentHash = this.hashContent();

        const conv = spec.object as any

        const objectProxy = new Proxy(conv , {
            set :((target, p, value)=> {
                target[p] = value;
                if(this.disableProxy)
                    return target;
                this.value(this.ownerAccess);
                
                return target;
            }),
            get :((target, param) => {
                if(this.disableProxy)
                    return target[param];
                this.value(this.ownerAccess);
                return target[param]
            })
        })
        this.object = objectProxy
    }
    /* Disables the proxy AUTO update as some functions may cause the proxy to run in infinite loop */
    private __doDisableProxy(func : ()=>void) {
        this.disableProxy = true;
        func();
        this.disableProxy = false;
    }

    cloud():Cloud {
        return this._cloud;
    }

    copy(): T {
        let aCopy : T = {} as T
        this.__doDisableProxy(()=> {
            aCopy = JSON.parse(JSON.stringify(this.object));
        })
        return aCopy
    }
    getAccessName(): string {
        return "local-object"
    }
    hashContent():string {
        return sha256(JSON.stringify(this.object));
    }
    json():string {
        return JSON.stringify(this.object);
    }

    id(){
        return this.objectId;
    }
    path(){
        return this.objectPath;
    }

    collection():string {
        return this.objectPath.substring(0, this.objectPath.lastIndexOf("/"));
    }

    put(access: CloudAccess, key : string, value : any){
        const data = this.value(access) as any;
        data[key]= value;
    }
    get(access : CloudAccess, key : string):any{
        const data = this.value(access) as any;
        return data[key];
    }
    samePath(another : CloudObject<T>){
        return another.path() === this.path();
    }
   
    async update(access : CloudAccess){
        return this._cloud.data.update<T>(this._cloud, access, this.objectPath, this.object)
    }

    cast<C>(access  : CloudAccess, callback : (arg : ValueMsg)=>void) : C {
        return (this.value(access, callback)) as any
    }
    apply(callback : (val : T) => void) : CloudObject<T> {
        callback(this.object)
        return this;
    }

    value(access : CloudAccess, callback? : (args : ValueMsg) => void) : T {
        if(this.isUpdating)
            return this.object;
        const copy = this.copy()
        setTimeout(()=>{
            const newHash = this.hashContent();
            if(this.contentHash != newHash){
                callback?.("request-made");
                this.contentHash = newHash
                
                this.update(access).catch(e => {
                    this.object = copy;
                    callback?.("request-error")
                });
            } 
            this.isUpdating = false;
        }, UPDATE_RESOLUTION)
        this.isUpdating = true;
        return this.object;
    }
    write(access: CloudAccess, callback : (data : T) => void): Promise<void> {
        const copy = this.copy();
        //ensures proxy does not auto update
        this.__doDisableProxy(() => {
            callback(this.object);
        })
       
        //call update function
        return this._cloud.data.update(this._cloud, access, this.path(), this.object).catch(e => {
            this.__doDisableProxy(()=> this.object = copy);
        })
    }
    pendingUpdate():boolean {
        return this.isUpdating;
    }
    async delete(access : CloudAccess){
       return this._cloud.data.delete(this._cloud, access, this.objectPath);
    }
}

export class ObjectHelper {

    static resolveId<T>(id : string, list : CloudObject<T>[]) : CloudObject<T> | null {
        for(const index in list)
            if(list[index].id() === id)
                return list[index]
        return null;
    }
    static resolvePath<T>(path : string, list : CloudObject<T>[]) : CloudObject<T> | null {
        for(const index in list)
            if(list[index].path() === path)
                return list[index]
        return null;
    }

    static sort<T>(caller : CloudAccess, array : CloudObject<T>[], sort : (a : T, b : T) => number){
        array.sort((a, b)=>{
            const a1 = a.value(caller);
            const b1 = b.value(caller);
            return sort(a1, b1);
        })
    }
    static filter<T>(caller : CloudAccess, array : CloudObject<T>[], filter : (a : T) => boolean, set? : SetFilter) {
        return array.filter((a) => {
            let exclude = false;
            if(set){
                for(const index in set.exclude)
                    if(set.exclude[index].id() === a.id())
                        exclude = true;
            }
            if(exclude)
                return false

            const a1 = a.value(caller);
            return filter(a1);
        })
    }
    static convertRaw<T>(array : CloudObject<T>[]) : T[] {
        const convert : T[] = []
        for(const index in array){
            const ilocal= array[index] as any;
            convert.push(ilocal["object"])
        }
        return convert;
    }
    static createEmpty<T>(access : CloudAccess,  val : T) : CloudObject<T> {
        return createObject(Context, access, {
            object : val,
            path : "@empty"
        })
    }

    static containsId<T>(array : CloudObject<T>[], id : string) {
        for(let i=0; i < array.length; i++){
            if(array[i].id() == id)
                return true;
        }
        return false;
    }
}

type SetFilter = {
    exclude : CloudObject<any> []
}