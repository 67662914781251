import firebase from "firebase/app";

const CSS_TARGET_WIDTH = 550

const EVENT_CSS_BELOW_TARGET = "css_below_target"


export const APP_MAIN =         "appmain"
export const PAGE_ACHIEVEMENT = "achievement"
export const PAGE_ACTIVITY=     "activity"
export const PAGE_BUDGET=       "budget"
export const PAGE_DASHBOARD=    "dashboard"
export const PAGE_GOALS=        "goals"
export const PAGE_PAY_SUCCESS=  "paysuccess"
export const PAGE_PLAN=         "plan"
export const PAGE_PROVIDER=     "provider"
export const PAGE_SUPPORT=      "support"

export function logEventPageView(pageName: string) {
    firebase.analytics().logEvent(`page_view_${pageName}`)
}

export function logWidthUsage() {
    if(window.innerWidth < CSS_TARGET_WIDTH) {
        firebase.analytics().logEvent(EVENT_CSS_BELOW_TARGET)
    }
}