<template>
    <div v-if="show" class="app-grey_frame">
        <div class="alert-dialog-inner app-dialog_frame">
            <h1>{{title}}</h1>
            <div>{{message}}</div>
            <div class="alert-dialog-button"><button @click="onClose">Ok</button></div> 
        </div>
    </div>
</template>

<script lang="ts">

import { Options, Vue } from "vue-class-component"

export type AlertHandler = (title : string, message : string) => void

@Options({
    emits : ["handler"]
})
export default class AlertDialog extends Vue {
    title = ""
    message = ""
    show = false

    handler : AlertHandler = (title, message) => {
        this.title = title ?? ""
        this.message = message ?? ""
        this.show = true
    }
    mounted() {
        this.$emit("handler", this.handler)
    }
    onClose() {
        this.show = false
    }
}
</script>

<style scoped>
h1{
    font-size: 2rem;
}

.alert-dialog-inner {
    max-width: 400px;
    min-height: 200px;
    position: relative;

    margin-top: auto;
    margin-bottom: auto;
    background: white;
    display: block;
        
}
.alert-dialog-button{
    bottom: 0;
    right: 0;
    margin-right: var(--stm_dialog_marginx);
    margin-bottom: var(--stm_dialog_marginy);
    margin-top: .5rem;
    background: seagreen;
    display: flex;
    justify-content: flex-end;
    position: absolute;
}
</style>