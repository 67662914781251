import { createRouter, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import Account from "../views/legacy/Account.vue"
import Login from "../views/Login.vue"
import SignUp from "../views/Signup.vue";
import Verify from "../views/Verify.vue";
import AppMain from "../views/app/AppMain.vue"
import Manage from "../views/Manage.vue"
import PlanReport from "../views/reports/PlanReport.vue"
import BudgetReport from "../views/reports/BudgetReport.vue"

import AppDebug from "../views/debug/AppDebug.vue"
/* Report Screens */


const routes: Array<RouteRecordRaw> = [
  {
    path: '/app-debug',
    name: 'DebugMe',
    component: AppDebug
  },
  {
    path: '/',
    name: 'Home',
    component: Account
  },
  {
    path : '/signup',
    name : "Sign Up",
    component : SignUp
  },
  {
    path : '/verify',
    name : "Verify Account",
    component : Verify
  },
  {
    path : '/privacy',
    name : 'Privacy Policy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path : '/terms',
    name : 'Terms Of Service',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/manage',
    name: 'Manage',
    redirect : '/app/dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Manage
  },
  {
    path : '/report/plan',
    component  : PlanReport,
    name : 'Plan Report'
  },
  {
    path : '/report/budget',
    component  : BudgetReport,
    name : 'Budget Report'
  },
  {
    path : '/success',
    name : 'Payment Success',
    component: () => import('../views/app/VuePaySuccess.vue')
  },
  {
    path: '/app',
    name : 'App',
    component : AppMain,

    children :[{
      path : 'activity',
      component : ()=> import('../views/app/VueActivity.vue')
    },
    {
      path : 'dashboard',
      component : () => import('../views/app/VueDashboard.vue')
    },
    {
      path : "plan",
      component : ()=> import('../views/app/VuePlan.vue')
    },
    {
      path : "support",
      component :()=> import('../views/app/VueSupport.vue')
    },
    {
      path : "goals",
      component :()=> import('../views/app/VueGoals.vue')
    },
    {
      path : "provider",
      component :()=> import('../views/app/VueProvider.vue')
    },
    {
      path : "achievement",
      component :()=> import('../views/app/VueAchievement.vue')
    },
    {
      path : "budget",
      component :()=> import('../views/app/VueBudget.vue')
    },
    {
      path : "manage",
      component : Manage
    },
    {
      path : "",
      component : () => import('../views/app/VueDashboard.vue')
    },{
      path : "debug",
      component : AppDebug
    }]

  },
  {
    path : '/login',
    name : 'Login',
    component : Login
  }
]



const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
