
import FireComponent from '@/components/phoenix/core/FireComponent.vue';
import  CloudObject, { ObjectHelper } from '@/scripts-core/cloud/CloudObject';
import { BaseUser, Goals, ManagedUser, NDISPlan, PossibleUser, Provider, Support, SupportIdea } from 'possibleme-db';
import { Options } from 'vue-class-component';
import { document, listDb } from "@/scripts-core/CloudDB"
import { computeReviewList, GoalReview, SupportReview } from 'possibleme-db/lib/Compute';
import ReportHeader from "@/components/possibleme/helper/ReportHeader.vue"
import { volUserManagedUser } from 'possibleme-db/lib/Paths';

type PullContact = {
    contactName : string,
    contactCompany : string,
    contactNumber : string
}

@Options({
  components: {
      ReportHeader
  }
})
export default class ReportPlan extends FireComponent {
    showReport = false;

    planId : string | null = null
    path  : string | null = null
    user : BaseUser | null = null

    supports : Support[] =[]

    /* Report Data*/
    reportGoals : string[] = []
    reviews : SupportReview[] = []
    ideas : CloudObject<SupportIdea>[] = []
    
    //providers : Provider[] = []
    pulledContacts : PullContact[] = []

    plan! : NDISPlan
    
    async onUserReady(uid: string | null) {
        if(uid == null) return;
        this.planId = (this.$route.query.planId as string) ?? null

        try {
            const refUser = await document<PossibleUser>(this, `/users/${uid}`);
            const refPlan = await document<NDISPlan>(this, `/users/${uid}/plans/${this.planId}`);
            const refGoals = await document<Goals>(this, `/users/${uid}/plans/${this.planId}/data/goals`);
            const refSupports = await listDb<Support>(this, `/users/${uid}/plans/${this.planId}/support`);
            const refIdeas = await listDb<SupportIdea>(this, `/users/${uid}/plans/${this.planId}/ideas`);
            let refProviders = await listDb<Provider>(this, `/users/${uid}/providers`);
            
            this.supports = ObjectHelper.convertRaw(refSupports);
            
            const pids : string[] =[];
            const contactsPulled : string[] = []


            this.supports.forEach(item => {
                if(!pids.includes(item.providerId))
                    pids.push(item.providerId);
                //check contact pulled
                if(!contactsPulled.includes(item.contact.path)){
                    contactsPulled.push(item.contact.path)
                    this.pulledContacts.push({
                        contactName : item.contact.name,
                        contactCompany : item.provider.providerName,
                        contactNumber : item.contact.phone
                    })
                }
            });
            refProviders = refProviders.filter(pProv => pids.includes(pProv.id()))

            if(refUser == null || refPlan == null || refGoals == null)
                return this.redirect404(`User, plan or goals null`);
            this.user = refUser.value(this);
        
            
            this.ideas = refIdeas

            
            this.plan = refPlan.value(this)
            /* Filter providers */
        
            /* Check plan user */
            const mUser = this.plan.managedUser
        
            if(mUser){
                console.time('doUser')
                const localUser = await document<ManagedUser>(this, `${volUserManagedUser(uid)}/${mUser}`)
                console.timeEnd('doUser')

                
                if(localUser != null)
                    this.user = localUser.value(this);
            }

            /* Set Report data */
            const goals = refGoals.value(this);
            for(let index in goals.goalList){
                const ilocal = goals.goalList[index];
                if(ilocal.shown)
                    this.reportGoals.push(ilocal.name);
            }
            /* Compute Reviews */
            const ideas = ObjectHelper.convertRaw(refIdeas);
            this.reviews = computeReviewList(ideas, refGoals.value(this), true);

            this.showReport = true;
                
        }catch(e) {
            /*
            const code : ErrorCloud = "unknown"
            if(e instanceof CloudError){
                const err = e as CloudError
                code = err.code
            }*/
            console.error(e);
        }
    }
   

    findSupport(supportIdeaId : string) : Support[] {
        return this.supports.filter(item => item.ideaId === supportIdeaId);
    }
    calcSupportHours(array : Support[]) : string {
        
        let totalMillis = 0 //TODO
        const conv = 1000 * 60 * 60 //TODO: MOVE elsewhere
        array.forEach(item => {
            const delta = (item.dateEnd - item.dateStart);
            totalMillis += delta;
        });
        return Number(totalMillis / conv).toFixed(2);
    }
    findReviews(idea : SupportIdea): GoalReview[] {
        for(let i=0; i < this.reviews.length; i++){
            if(this.reviews[i].support == idea.name)
                return this.reviews[i].reviews
        }
        return []
    }

    componentClass() : string {
        return "report-plan"
    }
    calcHours(idea : CloudObject<SupportIdea>) : string {
        const foundSupports = this.findSupport(idea.id())
        return this.calcSupportHours(foundSupports)
    }
}
