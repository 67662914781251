<template>
<div>
    <PlanBanner></PlanBanner>
<main>
      <article>
        <div class="content">
            <div v-if="!isVerified" class="highlight">Email Verification sent to: <span>{{currentEmail}}. <a id="reset" @click="clickResend()">Resend</a> </span> </div>
            <div class='signform'> 
                <h1>Account</h1>
                <h3>Details</h3>
                <input class='app-stdinput' v-model="newFirstname" type="text" placeholder="First Name">
                <input class='app-stdinput' v-model="newLastname" type="text" placeholder="Last Name">
                <input class='app-stdinput' v-model="newBirthday" type="date" placeholder="Birthday">
                <input class='app-stdinput' v-model="newNdis" type="text" placeholder="NDIS No">
                <MeAction :full="true" :labelText="'Update Details'" @click="clickDetails()"  ></MeAction>

                <h3>Change Email</h3>
                <input class='app-stdinput' autocomplete="off" v-model="fieldEm" type="text" placeholder="New Email">
                <input class='app-stdinput' autocomplete="off" v-model="fieldPa" type="password" placeholder="Confirm Password">
                <MeAction :full="true" :labelText="'Update Email'"  @click="clickChangeEmail()"></MeAction>

                <h3>Change Password</h3>
                <input class='app-stdinput' autocomplete="off" v-model="changePassCurrent" type="password" placeholder="Current Password">
                <input class='app-stdinput' autocomplete="off" v-model="changePassNew" type="password" placeholder="New Password">
                <MeAction :full="true" :labelText="'Change Password'" @click="clickChangePassword()" ></MeAction>
                <ManagedUser class='w-full' />
               
                

                <div v-if="false"  style="width:100%;">
                    <h2>Download Android App</h2>
                    <p>The PossibleMe App can be downloaded from here <a href="https://play.google.com/store/apps/details?id=au.com.possiblemeV4">Download PossibleMe</a> or by scanning the QR Code below.
                    You must verify your email before attempting to login.</p>
                    <img id="qrcode" src="/possibleme_qrcode.png" style="margin:auto;display:block;"/>
                </div>
                 <div class="detailbox" id="trialBar" v-if="status === 'trial' || status == 'trial-active'">
                    <h3>Trial Status</h3>
                    <div>
                        <p>{{trialInterval}}</p>
                    </div>
                </div>
            </div>
            <div class="signform" v-if="status == 'active' || status == 'trial-active'">
                <h3>Current Plan</h3><div class="detailbox"> 
                    <h2>Current Period</h2>
                    {{subscribeDetails}}
                </div>
                <div id="subbox">
                    <img  alt="" :src="subImage"  >
                </div>
                <MeAction @loadingHandler="(loader) => { handlerPortal = loader}" :full="true" :labelText="'Update Subscription'" @click="clickUpdate()" ></MeAction>
            </div>
            <div v-if="status === 'pending'">
                Checking Subscription
            </div>
            <div style="text-align:center;" class="" v-if="status == 'none' || status == 'trial'">
                <h3>New Subsription</h3>
                <div class="subform">
                    <div @click="newSubscribe(`${skuMonth}`)" v-bind:style="{ 'background-color': selectMonth }">
                         <img src="/sub_month.png" alt="">
                    </div>
                    <div @click="newSubscribe(`${skuYear}`)" v-bind:style="{ 'background-color': selectYear}">
                        <img src="/sub_year.png" alt="">
                    </div>
                </div>
                <div class="signform">  
                     <MeAction @loadingHandler="(loader) => {handlerSubscribe = loader}"  :full="true" :labelText="'Subscribe'"  @click="clickCheckout()"/>
                </div>
            </div>
            
        </div>
    </article>  
    </main>    
</div>
    
</template>


<script lang="ts">
import { PossibleUser, UserTrial } from "possibleme-db"
import { Options } from "vue-class-component"
import PossibleApp from '@/scripts/PossibleApp';
import PossibleVue from '@/scripts/PossibleFramework';
import * as dateutil from "possibleme-db/lib/util/DateHelper"
import ManagedUser from "@/components/possibleme/widgets/ManagedUser.vue"
import PlanBanner from "../components/possibleme/PlanBanner.vue"
import MeAction from "../components/possibleme/helper/MeAction.vue"
import { possibleEnv } from "../Conf";


const select_color = "var(--prime)";
@Options({
    components : {
        ManagedUser,
        PlanBanner,
        MeAction
    }
})
export default class Manage extends PossibleVue {
    isVerified = true;
    currentEmail = "N/A"

    subImage = "";

    changePassCurrent = ""
    changePassNew = ""
    

    newFirstname = "";
    newLastname = "";
    newNdis = "";
    newBirthday = "";

    trialStart = "N/A";
    trialEnd = "N/A";

    skuMonth = possibleEnv.skuMonth
    skuYear = possibleEnv.skuYear

    newSubscription = "";
    status = "pending";

    selectMonth = "";
    selectHalf = "";
    selectYear = "";

    fieldEm = ""
    fieldPa= ""

    subscribeDetails = "TODO Details"

    trialInterval = "from"

    //handler for loading
    handlerPortal = (isLoading: boolean) => { return }
    handlerSubscribe = (isLoading: boolean) => { return }

    /* Resemnts Email Verification */
    clickResend(){
        this.apiDEP().sendEmailVerification(() => {
            this.sendAlert("Verify Email", "An email verification email has been sent. Please check your inbox")
        })
    }
    setImageSub(sku : string){
        switch(sku){
            case this.skuMonth:this.subImage = "/sub_month.png";break;
            case this.skuYear:this.subImage = "/sub_year.png";break;
        }
    }
    onReadyDEP(uid: string | undefined){
        this.contextDEP().send(1, false);
        this.fieldPa = "";
        this.fieldEm = "";

        if(uid){
            this.isVerified = this.apiDEP().isEmailVerified();
            /*Check email verified*/
            if(!this.apiDEP().isEmailVerified()){
                this.isVerified = false;
                this.currentEmail = this.apiDEP().currentEmail();
                //Query verified is a override as redirect from /verify does not update verified straight away
                const queryVerified = this.$route.query.emailVerified == "true"
                if(queryVerified)
                    this.isVerified = true;
            }
            PossibleApp.Instance().getPayment().pullSubscription((sub) => {
                if(sub.status == "trial-active") {
                    this.setImageSub(sub.sku_stripe?? "");
                    this.subscribeDetails = `Subscription will start at end of trial period`
                }
                else if(sub.status == "active"){
                    const start = dateutil.toPossibleDate(sub.active?.current_start ?? new Date())      
                    const end = dateutil.toPossibleDate(sub.active?.current_end ?? new Date())        
                    this.setImageSub(sub.sku_stripe?? "");
                    this.subscribeDetails = `Plan period is from ${start} - ${end}`
                }
                this.status = sub.status;
            }, ()=>{
                this.status = 'none'
            })
            /*New Seconds */
            this.fastDocument(`trials/${uid}`, data =>{
                const mytrial  = data as UserTrial

                this.uiSetTrial(mytrial.trial_start.toDate(), mytrial.trial_end.toDate());
            })
        }
        this.apiDEP().watch(this.contextDEP(), `users/${uid}`, caller => {
            this.newFirstname = caller.firstname?? "";
            this.newLastname = caller.lastname ?? "";
            this.newNdis = caller.ndisNo ?? "";
            try{
                const parts = caller.birthday.split("/");
                this.newBirthday = `${parts[2]}-${parts[1]}-${parts[0]}`
            }catch(e){
                return;
            }   
        });
        /* New Section */
    }
    newSubscribe(price : string ){
        this.selectMonth = "";
        this.selectHalf = "";
        this.selectYear = "";

        if(price == this.skuMonth)
            this.selectMonth = select_color;
        if(price == this.skuYear)
            this.selectYear = select_color
        this.newSubscription = price;
    }
    clickChangeEmail(){
      
        PossibleApp.Instance().sendMessage(this.contextDEP(), 1, true);
        this.apiDEP().changeEmail(this.fieldEm, this.fieldPa, () =>{
            this.sendAlert("Email Changed", "You have successfully changed your email")
            //alert("Email Successfully Changed")
            PossibleApp.Instance().sendMessage(this.contextDEP(), 1, false);
        }, msg =>{
            this.sendAlert("Email Error", msg);
           
            //PossibleApp.Instance().sendMessage(this.contextDEP(), 1, false);
        });
    }

    clickChangePassword(){
        this.apiDEP().changePassword(this.changePassCurrent, this.changePassNew, () => {
            this.sendAlert("Password Changes", "Your password has been successfully changed")
            
        }, (msg) =>{
            console.error(msg);
            this.sendAlert("Login Error", msg);
        })
    }
    clickUpdate() {
        this.handlerPortal(true)
        PossibleApp.Instance().sendMessage(this.contextDEP(), 1, true);
        this.apiDEP().redirectPortal();
    }
    clickCheckout(){
        this.handlerSubscribe(true)
        PossibleApp.Instance().sendMessage(this.contextDEP(), 1, true);
        PossibleApp.Instance().getPayment().subscribe(this.newSubscription);
    }
    clickDetails(){
        const parts = this.newBirthday.split("-")
        const dateFormatted = `${parts[2]}/${parts[1]}/${parts[0]}`
        /*
        firstname : this.newFirstname,
            lastname : this.newLastname,
            email : "",
            password : "",
            ndisNo : this.newNdis,
            birthday : dateFormatted
        */
       const updateUser : PossibleUser = {
           firstname : this.newFirstname,
           lastname : this.newLastname,
           birthday : dateFormatted,
           ndisNo : this.newNdis
           
       }
        this.apiDEP().updateUser(updateUser, () =>{
            this.sendAlert("Details Updated", "Your details have been updated successfully")
            // /alert("User Details Updated");
        }, (msg) =>{
            return;
        })
    }

    uiSetTrial(start : Date, end : Date){
      this.trialInterval = `Trial is from ${start.toDateString()} - ${end.toDateString()}`
    }
}
</script>

<style scoped>
/* Legeacy buttons have this border */
.widget-btn {
    border-radius: .5rem;
}


#reset {
        color: wheat;
        text-decoration: underline;
    }
  #reset:hover {
    color: orange;
    text-decoration: underline;
    cursor: pointer;
    
  }
    

    #subbox {
        margin-right: 2rem;
        width: 15rem;
    }

    .highlight{
        padding: 1rem;
        background-color: cornflowerblue;
        border-radius: 1rem;
        color: white;
        margin-bottom: 1rem;
    }

    .detailbox {
        border-radius: 1rem;
        width: 100%;
        padding: 1rem;
        background-color: var(--prime);
        color: white;
        padding-top:.5rem;
        margin-top: 0px;
        padding-bottom:.5rem;
    }

    h3 {
        margin-top: .5rem;
    }
    h2 {
        font-size: 1.3rem;
        margin-bottom:0px;
    }

    .subform{
        justify-content: center;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }

    .signform > img {
        width: 4rem;
    }

    .subform > div {
        padding: 1rem;
        margin-right: 1rem;
        cursor: pointer;
       
        width: 300px;
        border-radius: 1rem;
       
        
    }

    .subform > div:hover{
        background-color: cornflowerblue;
    }

    /*

    button {
        line-height: 1rem;
      
        border-radius: .5rem;
        color: white;
        background-color: var(--accent);
        
        
        padding: .7rem;
        margin-top: 1rem;
    }
    button:hover {
        color :white;
        background-color: var(--prime);
    }*/

    #trialBar {
        margin-top:1rem;
       
      
    }

    input {
        height: 2rem;
        margin-bottom: .5rem;
    }
</style>
