import { CloudAccess } from "../../scripts-core/cloud/CloudObject";
import { NDISPlan } from "possibleme-db";
import { Selector } from "./ClientHelpers";
import {  bucketPlans } from "./VClientUtil"

const access : CloudAccess = {
    getAccessName(){return "PossibleHelper.ts"}
}

export const CurrentPlanSelect : Selector<NDISPlan> = list => {
    const [current, upcoming, old] = bucketPlans(access, list);

    if(current.length > 0)
        return current[0];
    if(upcoming.length > 0)
        return upcoming[0];
    if(old.length > 0)
        return old[0];
    return null;
}

export const CustomPlanSelect : Selector<NDISPlan> = (list, selection)=>{
    const current = selection.get();
    if(current == null && list.length > 0)
        return list[0];
    if(current == null || list.length == 0)
        return null;
    if(list.length == 1)
        return list[0];
    /* Have at least 2 items current is not null */

    for(let i=0; i < list.length; i++){
        if(list[i].id() == current.id())
            return list[i];
    }
    return list[0];
}