
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator"
export type PopupToggler = (state? : boolean, command? : PopupCommand) => void
export type PopupCommand = "done"

@Options({
    emits : ["toggler", "setConfirm"]
})
export default class PhoenixPopup extends Vue {
    @Prop()
    aclass! : string 
    @Prop()
    astyle! : string   
    @Prop()
    title! : string

    @Prop()
    cancelable! : boolean

    @Prop()
    confirmable = true

    @Prop()
    hideHeader! : boolean

    showPopup = false
    showConfirm = false

    toggler : PopupToggler = (state? : boolean, command? : PopupCommand) => {
        if(state === false && this.showPopup && command =="done") 
            this.showPopup = false
        else if(state === true && this.showPopup === false) 
            this.showPopup = true
        else if(state === undefined && this.showPopup === false) 
            this.showPopup = true
        else if(state === false && this.showPopup === true) 
            this.closePopup(true)  
    }
    clkMe() {
        return true;
    }
    setConfirmable(confirm: boolean) {
        this.confirmable = confirm;
    }

    mounted() {
        this.$emit("toggler", this.toggler)
        this.$emit("setConfirm", this.setConfirmable)
    }

    confirm(confirm : boolean) {
        if(confirm) {
            this.showPopup = false
            this.showConfirm = false
        } 
        else 
            this.showConfirm = false
    }

    closePopup(close : boolean) {
        if(close && !this.confirmable) 
            this.showPopup = false;
        else if(close && this.confirmable) 
            this.showConfirm = true
    }
}
