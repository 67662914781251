<template>
    <div class='app--component phoenix-button-outer'>
        <div class ='error-text' v-if="message != ``">
            {{message}}
        </div>
        <button @click="onClick" class='phoenix-button'>{{labelText}}</button>
        <transition name="fade">
            <div v-if="loading" class='compose--frame inner-text'>
                <div class='loader'></div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">

import { Vue, Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"

export type LoadHandler = (loading : boolean, msg? : string) => void

@Options({
    emits: ["handler"]
})
export default class PhoenixButton extends Vue {
    @Prop()
    labelText! : string

    message = ""

    @Prop()
    click ! : (handle : LoadHandler) => void
    loading = false
    showError = false

    show : LoadHandler = (loading, msg) => {
        this.loading = loading
        if(msg)
            this.message = msg
    }
    mounted() {
        this.$emit("handler", this.show)
    }
    onClick() {
        if(!this.loading)
            this.click?.(this.show)
    }
}
</script>

<style>

.phoenix-button-outer { 
    display: inline-block;
}
.error-text {
    color: white;
    background-color: tomato;
    padding: .1rem;
    text-align: center;
}

.phoenix-button {
    color: white;
    appearance: none;
    background-color: var(--color--accent);
    outline: none;
    padding: 1rem;
    width: 100%;
}

.phoenix-button:hover {
    background-color: var(--color--primary);
}

.phoenix-button:active {
     background-color: darkgoldenrod;
}

.inner-label {
    margin: auto;
}

.inner-text {
    background-color: lightseagreen;
    display: flex;
    justify-content: center;
    margin-bottom: auto;  
}

.fade-enter-to {
    opacity: 1;
}

.fade-enter-active {
    transition: opacity .1s ease;
}
.fade-enter-from {
    opacity: 0;
}
</style>
