import { Cloud } from "../Cloud";
import { PossibleData } from "../Data";
import { CloudAccess } from "../scripts-core/cloud/CloudObject";

import { removeDb, updateDb } from "../scripts-core/CloudDB"


export default class ImpPossibleData implements PossibleData {
    delete(cloud: Cloud, access: CloudAccess, path : string): Promise<void> {
        return removeDb(path);
    }
    update<T>(cloud: Cloud, access: CloudAccess, path: string, object: any): Promise<void> {
        return updateDb(access, path, object)
    }
}