

import { Options, Vue } from "vue-class-component"

export type AlertHandler = (title : string, message : string) => void

@Options({
    emits : ["handler"]
})
export default class AlertDialog extends Vue {
    title = ""
    message = ""
    show = false

    handler : AlertHandler = (title, message) => {
        this.title = title ?? ""
        this.message = message ?? ""
        this.show = true
    }
    mounted() {
        this.$emit("handler", this.handler)
    }
    onClose() {
        this.show = false
    }
}
