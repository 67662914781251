import LightArray from "../util/LightArray";
import { Deregister, PhoneixID } from "../util/PhoenixObject";
import PhoenixContext, {ContextDetails, ObjectLifecycle} from "./PhoenixContext";

let RUNTIME_APP : PhoenixApp

export default class PhoenixApp { 
    private managedContexts : LightArray<PhoenixContext> = new LightArray();
    private appObjects : LightArray<ObjectLifecycle> = new LightArray();
    private appMap : Map<PhoneixID, ObjectLifecycle> = new Map();

    private appname : string
    private config : PhoneixConfig

    constructor(appname : string, config : PhoneixConfig){
        this.appname = appname;
        this.config = config;
    }
    isDebug():boolean{
        return this.config.isDebug;
    }
    
    containsLifecycle(lifecycle : ObjectLifecycle):boolean {
        return this.appObjects.contains(lifecycle);
    }
    ejectLifecycle(lifecycle : ObjectLifecycle){
        this.appObjects.eject(lifecycle);
        
        for (const [key, value] of this.appMap) {
            if(value == lifecycle)
                this.appMap.delete(key);
        }
    }
    pushLifecycle(lifecycle : ObjectLifecycle, pxd : PhoneixID){
        this.appObjects.push(lifecycle);
        this.appMap.set(pxd, lifecycle);
    }
    objectSize():number {
        return this.appObjects.size();
    }

    registerContext(context : PhoenixContext):Deregister {
        this.managedContexts.push(context);
        const localContext = this.managedContexts;

        return {
            detach(){
                localContext.eject(context);
            }
        }
    }
    
    contextRemove = (context : PhoenixContext) => this.managedContexts.eject(context);
    contextSize = () => this.managedContexts.size();

    contextFindNamespace(namespace : string):PhoenixContext | undefined {
        for(let i=0; i < this.managedContexts.size(); i++){
            const iContext = this.managedContexts.at(i);
            if(iContext.getNamespace() == namespace)
                return iContext
        }
    }

    appDetails():AppDetails {
        return {
            objectSize : this.appObjects.size(),
            mapSize :this.appMap.size,
            contextSize : this.managedContexts.size()
        }
    }
    
    contextDetails():ContextDetails {
        return {
            contextSize : this.managedContexts.size(),
            contextNames : this.managedContexts.convertStringArray("contextNamespace")
        }
    }

    debugBlock(tag : string, callback : (debug : PhoenixDebugger) => void){
        if(this.config.isDebug)
            callback(new DefaultDebugger(tag))
    }
  
    static initApp(app : PhoenixApp){
        RUNTIME_APP = app;
    }
    static getApp():PhoenixApp{
        return RUNTIME_APP;
    }
}

export interface PhoneixConfig {
    isDebug : boolean,
}

export interface PhoenixDebugger {
    log(msg : any) : void
}

interface AppDetails {
    contextSize : number,
    objectSize : number,
    mapSize : number
}

class DefaultDebugger implements PhoenixDebugger {

    constructor(private tag : string){}

    log( msg : any) : void {
        console.log(`${this.tag} >> ${msg}`)
    }
}