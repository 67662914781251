
import { Options, Vue } from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"

@Options({
  components : {
  }, 
  emits: ["loadingHandler"] 
})
export default class MeAction extends Vue {
    @Prop()
    labelText! : string

    @Prop()
    full! : boolean

    isLoading = false

    runClass = ""
    mounted(){
        if(this.full)
            this.runClass = "w-full pl-2"
        this.$emit("loadingHandler", this.setLoading)
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }
}
