

import { Options } from "vue-class-component"
import AppBar from "@/components/AppBar.vue"
import PossibleApp from "./scripts/PossibleApp"
import { Watch } from "vue-property-decorator"
import PossibleVue from "./scripts/PossibleFramework"
import * as WHAT from "@/scripts/what"
import AlertDialog from "@/components/phoenix/features/AlertDialog.vue"
import MeAlert from "./components/possibleme/helper/MeAlert.vue"
import { logWidthUsage } from "@/possibleme/PossibleAnalytics"

@Options({
    components: {
        AppBar,
        AlertDialog,
        MeAlert
    },
})
export default class App extends PossibleVue {
    showTerms = true;

    appbarContext = PossibleApp.Instance().newStartedContext("appbar")

    user_inital = "Login"
    showNav = true;

    @Watch("$route", { immediate : true, deep : true })
    onUrlChange(newVal: any) {
        try {
            const path = newVal.path as string
           
            const segments = path.split("/");
            if (segments[1] == "app" || segments[1] == "report")
                this.showNav = false
            else this.showNav = true
            //hide terms if login page
            if (path === "/login" || path == "/signup") 
                this.showTerms = false
            else
                this.showTerms = true
        } 
        catch (e) {
            console.error(e)
        }
    }
    onApplication() {
        this.$router.push({
            path : "/app"
        })
    }
  
    mounted() {
        PossibleApp.Instance().getApi().listenUser(this.onUser)
        logWidthUsage()
    }

    clickLogout() {
        this.apiDEP().signout()
        this.$router.push("/login")
    }

    clickSignup() {
        this.contextDEP().send(WHAT.SIGNIN, "Hello World Messages")
        this.$router.push({ path: "/signup" })
    }
    onUser(uid: string | undefined) {
        if (!uid) {
            this.user_inital = "Login"
            return
        }
        const userDoc = `users/${uid}`
        PossibleApp.Instance()
        .getApi()
        .watch(this.appbarContext, userDoc, (data) => {
            const fname = data.firstname as string
            const lname = data.lastname as string
            this.user_inital =
            fname.charAt(0).toUpperCase() + "" + lname.charAt(0).toUpperCase()
        });
    }
    clickAccount() {
        const uid = this.apiDEP().currentUid()
        if (uid) 
            this.$router.push({ path: "/manage" })
        else 
            this.$router.push({ path: "/login" })
    }
    clickHome() {
        this.$router.push({ path: "/" });
    }
    logoClick() {
        this.$router.push({ path: "/" });
    }
}
