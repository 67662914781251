
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  components : {
  }
})
export default class MeDone extends Vue {
    @Prop()
    cancel! : boolean

    icon = "done"

    runClass = "medone"

    mounted(){
        if(this.cancel){
            this.icon = "close"
            this.runClass = "mecancel"
        }
    }
}
