
import { subscribeCurrentPlan, getCurrentPlan } from "@/scripts-possibleme/client/PossibleClient"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { Options } from "vue-class-component"
import {CLASS_PLAN_BANNER, WHAT_PLAN_TITLE} from "../CompDef"
import MobileMenu from "@/components/mobile/MobileMenu.vue"

const EMPTY_PLAN = ""

@Options({
    components : {
        MobileMenu
    }
})
export default class PlanBanner extends FireComponent {
    planName = EMPTY_PLAN

    mounted(){
        const plan = getCurrentPlan();
        if(plan != null)
            this.planName = plan.value(this).name
    }

    onUserReady() : void {
    
        subscribeCurrentPlan(this.getContext(), plan =>{
            if(!plan)
                this.planName = EMPTY_PLAN
            else
                this.planName = plan.value(this).name
        })
     }
    componentClass(): string {
        return CLASS_PLAN_BANNER
    }
    onMessage(what : string, object : any){
        if(what == WHAT_PLAN_TITLE)
            this.planName = object
    }
 
}
