<template>
    <header>
        <button @click="clickHome()">Home</button>
        <button @click="clickPrice()">Pricing</button>
        
    </header>

    <nav v-if="true">
        <div>3872</div>
    </nav>
    
</template>

<script lang="ts">

import { Options } from "vue-class-component";
import PossibleVue from "@/scripts/PossibleFramework";

@Options({
  props: {

  }
  
})
export default class AppBar extends PossibleVue {
    

    clickHome(){
        this.$router.push({path : "/"});
    }
    clickPrice(){
        this.$router.push({path : "/app/pricing"});
    }
  //context = PossibleApp.Instance().newStartedContext("DebugContext", [this.example]);  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    nav{
        width: 25rem;
        height: 100%;
        position: fixed;
    }

    div{
        height: 100%;
        width: 100%;
        background-color: springgreen;
    }


    menu {
        width: 30rem;
        height: 100%;
        background-color: slateblue;
    }

    header {
        padding: 1rem;
        background-color: crimson;
        display: flex;
        justify-content: space-between;
    }

    button {
        color: white;
        background-color: slateblue;
        border-radius: .7rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    button:hover {
        background-color: darkcyan;
        
    }
</style>
