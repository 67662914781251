

import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator";
import MeButton from "../../possibleme/helper/MeButton.vue";

export type ActionHandler = () => void
export type ActionClick = () => void

@Options({
    components : {
        MeButton
    },
    emits : ["handler"]
})
export default class ActionDialog extends Vue {
    @Prop()
    title! : string
    @Prop()
    message! : string
    show = false;
    
    @Prop()
    yes! : ActionClick
    @Prop()
    no ! : ActionClick 

    display : string[] = []

    handler : ActionHandler = () => {
        this.show = true
    }

    mounted() {
        this.$emit("handler", this.handler)
        this.display = this.message?.split("\n") ?? ""
    }

    onYes() {
        this.show = false
        this.yes?.()
    }

    onNo() {
        this.show = false;
        this.no?.()
    }
}
