<template>
    <div class='debug-frame p-10 z-20'>
        <h1>Debug Frame</h1>
        <MoneyInput :labelText="'Label Here'" @amount="onAmount">
        </MoneyInput>
        <Submit class='wide-500' @handler="loader" :labelText="'Submit Test'">
        </Submit>

        <MeButton :labelText="'My Button'"></MeButton>
        <MeButton :labelText="'Next Button'"></MeButton>
        <div>
            <input type="text" v-model="aTitle" placeholder="Title">
            <input type="text" v-model="aMessage" placeholder="Message">
            <MeAction :labelText="'Send Message'" @click="btnAlert"  />
        </div>
        <MeArea :model="blocks" :hint="'Add Some Text'" />

        <MeButton :labelText="'Print Text Block'"/>

        <MeButton :labelText="'Clear Text'" @click="blocks.text = ''" />

        <p>{{blocks.text}}</p>

        <div>
            <input type="text" v-model="vtext" placeholder="VText">
            <MeButton :labelText="'Submit VText'" @click="btnSubmit"/>
        </div>

        <MeAlert/>
    </div>
</template>

<script lang="ts">
import { Watch } from "vue-property-decorator"
import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import MoneyInput from "@/components/possibleme/helper/MoneyInput.vue"
import Submit, { LoadHandler } from "@/components/phoenix/PhoenixSubmit.vue"

import MeAction from "../../components/possibleme/helper/MeAction.vue"
import MeAlert from "../../components/possibleme/helper/MeAlert.vue"
import MeArea, { TextBlock } from "../../components/possibleme/helper/MeArea.vue"
import MeButton from "../../components/possibleme/helper/MeButton.vue"
import { pushDb } from "@/scripts-core/CloudDB";

@Options({
    components : {
        MoneyInput,
        Submit,
        MeButton,
        MeAction,
        MeAlert,
        MeArea
    }
})
export default class DebugVue extends FireComponent {
    blocks : TextBlock = {
        text : "this is some data"
    }

    aTitle = ""
    aMessage = ""

    click! : LoadHandler

    vtext = ""

    @Watch("blocks.text")
    print(){
        //DebugNote(this.blocks.text);
    }

    componentClass = () => "@debug-fail:DebugVue.vue"
    onUserReady(): void {
        return;
    }
    onAmount = (args : string) => {return}

    loader = (handle : LoadHandler) => {
        this.click = handle
        handle(true)

        setTimeout(()=>{
            handle(false, "Some message here")
        }, 5000)

        
    }
    btnAlert() {
        this.sendAlert(this.aTitle, this.aMessage);
    }
    btnSubmit(){
        pushDb<any>(this, "/test/app-debug/list", {   
            data : this.vtext
        })
    }
    
}
</script>

<style scoped>
.debug-frame{
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    background-color:white ;
}
.wide-500 {
    width: 500px;
}

</style>