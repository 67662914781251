import { Provider } from "possibleme-db"
import { ClassConvert, setClassMapping} from "../../scripts-core/CloudDB"

import { SupportClass } from "possibleme-db/lib/model/Support"
import { ProviderClass } from "possibleme-db/lib/model/Provider"

const provider : ClassConvert<Provider, ProviderClass> = {
    mapping : () => new ProviderClass(),
    path : "/users/~/providers/~",
    name : "Providers"
}
const support : ClassConvert<SupportClass, SupportClass> = {
    mapping : () => new SupportClass(),
    path : "/users/~/plans/~/support/~",
    name : "Supports"
}

const possibleMapping : ClassConvert<any, any>[] = [
    provider,
    support
]

export function setModelMapping(){
    setClassMapping(possibleMapping)
}