
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { Options } from "vue-class-component"
import PossibleMenu2 from "@/components/possibleme/PossibleMenu2.vue"
import { uuid } from "vue-uuid";
import { startClient } from "@/scripts-possibleme/client/PossibleClient"
import { signout, connectDb } from "@/scripts-core/CloudDB"
import { PossibleUser } from "possibleme-db"
import { WHAT_ALERT, MessageAlert } from "@/scripts-core/PhoenixMessage"
import ObjectPopup from "@/components/phoenix/ObjectPopup.vue"
import { PopupToggler } from "@/components/phoenix/ObjectPopup.vue";
import { resumeState } from "@/scripts-possibleme/client/PossibleClient"
import MeAuth from "../../components/possibleme/helper/MeAuth.vue"
import { APP_MAIN, logEventPageView } from "@/possibleme/PossibleAnalytics";

@Options({
    components :{
        PossibleMenu2,
        ObjectPopup,
        MeAuth
    }
})
export default class AppMain extends FireComponent {
    showMenu = false;
    email = ""
    dismount!  : ()=>void

    name = "";

    //alert : MessageAlert | null = null
    alertTitle ! : string
    alertContent : string[] = []

    toggler! : PopupToggler

    onAlertPop(toggler : PopupToggler){
        this.toggler = toggler;
    }
    onClose(){
        this.toggler(false, "done");
    }

    mounted(){
        logEventPageView(APP_MAIN)
        if(this.$route.query.select){
            const select = this.$route.query.select as any
            resumeState({
                select
            })
        }
    }
    async logout(){
        try{
            await signout();
        }catch(e){console.error()}
    }

    unmounted(){
        this.dismount?.();
    }
    onUserReady(uid: string | null) : void {
        if(uid == null) {
            this.$router.push({
                path : "/"
            })
            return;
        }
        this.dismount = connectDb<PossibleUser>(this.getContext(), `/users/${uid}`, puser => {
            const iuser =puser.value(this);
            this.name = `${iuser.firstname} ${iuser.lastname}`
        })
        //User here logic
        startClient()//TODO:? move this to main?????
        return;
    }
    onMessage(what : string, object : any){
        if(what == WHAT_ALERT){
            const obAlert : MessageAlert = object;
            this.alertTitle = obAlert.title;
            this.alertContent = obAlert.message.split("\n");
            this.toggler(true);
        }
    }
    componentClass(): string {
        return "app-main"
    }
    genNumber(){
        return uuid.v4()
    }
}
