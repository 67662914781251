export interface Deregister {
    detach():void
}

export interface PhoenixIDFactory {
    useString(uuid : string):PhoneixID
    default():PhoneixID
}

export class PhoneixID {
    private uuid;
    
    constructor(uuid : string){
        this.uuid = uuid;
    }

    phoenixID(){
        this.uuid;
    }
}