
/** Light array is a simple array*/
export default class LightArray<T> {
    private array : T[] = []
    private setMode : boolean;

    constructor(setMode? : boolean){
        this.setMode = setMode ?? false;
    }

    push (object : T) {
        if((this.setMode && this.contains(object)) || object == undefined || object == null)
            return;
        this.array.push(object);
    }
        
    size = ():number => 
        this.array.length;
    isEmpty = () =>
        this.array.length == 0;
    isNotEmpty = () =>
        !this.isEmpty();


    at (index :number):T {
        return this.array[index]
    }
    toArray():T[] {
        const local : T[] = [];
        for(let i=0;i < this.array.length; i++){
            const copy = JSON.parse(JSON.stringify(this.array[i]));
            local.push(copy);
        }
        return local;
    }
    
    eject(object : T, callback?: (object : T)=>void):boolean{
        for(let i=0; i < this.array.length; i++){
            if(Object.is(object, this.array[i])){
                callback?.(this.array[i])
                this.remove(i);
                return true;
            }
        }
        return false;
    }

    forEach (callback : (object : T, index? :number)=>void){
        this.array.forEach(callback);
    }
        
    remove (index :number) {
        this.array.splice(index, 1)[0];
    }
    
    clear(callback? : (object : T)=>void){
        for(let i=0; i < this.array.length; i++)
            callback?.(this.array[i]);
        this.array = []
    }
        
    contains (object : T):boolean{
        for(let i=0; i < this.array.length; i++)
            if(Object.is(object, this.array[i]))
                return true;
        return false;
    }

    containsKeyValue(key : string, value : any):boolean {
        const findKeyPair = this.findKeyValue(key, value);
        return findKeyPair != undefined
    }
    findKeyValue(key : string, value : any):T | undefined{
        for(let i=0; i < this.size(); i++){
            const object = this.at(i) as any;
            if(object[key] == value)
                return object;
        }
    }

    convertStringArray(key : string):string[]{
        const newArray : string[] = []
        for(let i=0; i < this.size(); i++){
            const ilocal = this.at(i) as any
            newArray.push(ilocal[key])
        }
        return newArray;
    }


}