import { CloudAccess, ObjectError } from "../cloud/CloudObject";
import LightArray from "../util/LightArray";
import { Deregister, PhoenixIDFactory, PhoneixID } from "../util/PhoenixObject";
import PhoniexApp from "./PhoenixApp";
import {sendAlertMessage} from "../../components/phoenix/PhoenixComponent"

//const GLOBAL_OBJECTS : LightArray<ObjectLifecycle> = new LightArray();
//const GLOBAL_MAP : Map<PhoneixID, ObjectLifecycle> = new Map();
const DEFAULT_IDEN : PhoenixIDFactory = {
        useString(uuid : string):PhoneixID{
            return new PhoneixID(uuid);
        },
        default(){
            //TODO: auto gen id
            return new PhoneixID("298");
        }
}

export default class PhoenixContext implements Deregister, CloudAccess {
    private alive = true;
    private app : PhoniexApp;
    private contextNamespace : string
    private detachFromApp : Deregister | undefined
    private managedObjects : LightArray<ObjectLifecycle> = new LightArray();

    constructor(app : PhoniexApp, contextNamespace : string){
        this.app = app;
        this.contextNamespace = contextNamespace;

        const existing = app.contextFindNamespace(contextNamespace);
        if(existing){
            console.warn(`returning duplicate context: ${contextNamespace}`)
            return existing;
        }
        this.detachFromApp = app.registerContext(this);
    }
    onError(err : ObjectError){
        sendAlertMessage("Object Error", err.message);
        console.error(`Context Handler Error: ${err.message}`)
    }
    getAccessName(): string {
        return `Context:${this.contextNamespace}`
    }
    detach(): void {
        this.managedObjects.clear((objectLifecycle =>{
            this.app.ejectLifecycle(objectLifecycle)
            objectLifecycle.onDispose();
        }))
        this.detachFromApp?.detach();
        this.alive = false;
    }
    getNamespace = () => this.contextNamespace;

    registerLifecycle(object : ObjectLifecycle):()=>void{
        if(this.managedObjects.contains(object) || this.app.containsLifecycle(object)){
            //TODO:implementr better register
           
            return ()=>{return;}
        }
        this.managedObjects.push(object);
       
        const generatedID = object.onGenerateID?.(DEFAULT_IDEN) ?? DEFAULT_IDEN.default();
        this.app.pushLifecycle(object, generatedID);
       
        object.onCreate(generatedID);

        return ()=>{
            this.managedObjects.eject(object);
            this.app.ejectLifecycle(object);
            object.onDispose();
        };
        
    }

    isAlive(){
        return this.alive;
    }

    globalObjectSize():number {
        return this.app.objectSize();
    }
    application(){
        return this.app
    }
}

export interface ContextDetails {
    contextNames: string[]
    contextSize : number
}

export interface ObjectLifecycle {
    onGenerateID? (factory: PhoenixIDFactory):PhoneixID
    onCreate(uuid: PhoneixID):void
    onDispose():void
}