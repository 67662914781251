
import { Options, Vue } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"

export type TextBlock = {
    text : string
    disabled? : boolean
}

@Options({
  components : {
  },
  emits : ["text"]
})
export default class MeText extends Vue {
    @Prop()
    hint! : string

    @Prop()
    model! : TextBlock

    @Prop()
    areaClass! : string

    @Watch("model.disabled")
    onDisable(){
        return;
    }
    
    mounted(){
        if(!this.model){
            this.model = {
                text : ""
            }
        }
        if(this.model.disabled == undefined)
            this.model.disabled = false
    }
}
