<template>
    <main>
        <article> 
           <div class="wrapper"></div>
           <div class="signform" >
            <h1>Verification Management</h1>
               <div v-if="mode == 'resetPassword'">
                    <h3>Reset Email</h3>
                    <p>{{email}}</p>

                    <h3>Reset Password</h3>
                    <input class='app-stdinput' v-model="password" type="password" placeholder="Password">
                    <input class='app-stdinput' v-model="repeatPassword" type="password" placeholder="Repeat Password">
                    <button @click="clickResetPassword()">Reset Password</button>
               </div>
                <ToastMessage>
                </ToastMessage>
           </div>
        </article>
    </main>
</template>

<script lang="ts">
import PossibleVue from '@/scripts/PossibleFramework';
import firebase from 'firebase/app'

import ToastMessage from '@/components/ToastMessage.vue'
import { Options } from 'vue-class-component';

@Options({
  components: {
    ToastMessage
  }
})


export default class Verify extends PossibleVue {
    mode = "resetPassword"
    actionCode  = ""
    email = "";

    password = ""
    repeatPassword = ""
    
    app : firebase.app.App | null = null;

    onReadyDEP(uid: string | undefined){
      
        
        switch(this.mode){
            case "verifyEmail":{
                this.handleVerifyEmail();
            }break;
            case "recoverEmail":{
                this.handleRecoverEmail();
            }break;
            case "resetPassword":{
                this.handleResetPassword();
                //this.displayLoading(false);
            }break;
        }
    }

    mounted(){
        //TODO:Remove
        this.displayLoadingDEP(true);
        

        this.mode = this.$route.query.mode as string;
        this.actionCode = this.$route.query.oobCode as string;
        
        const queryKey = this.$route.query.apiKey as string;
        const config = {
            apiKey : queryKey
        }

        this.app = firebase.initializeApp(config, "verify-app");
        
    }
    handleResetPassword(){
        firebase.auth().verifyPasswordResetCode(this.actionCode).then((email) =>{
            this.displayLoadingDEP(false);
            this.email = email;

        }).catch(error =>{
            this.displayLoadingDEP(false);
        });
    }

    handleVerifyEmail(){
        this.showToastDEP("Verifying Email...")
        this.app?.auth().applyActionCode(this.actionCode).then(success =>{
            this.showToastDEP("Email verification successful redirecting to account page...");

            setTimeout(() =>{
                this.$router.push({path: "/manage", query: {emailVerified : 'true'}});
            },1000)


            
        }).catch(e =>{
            console.error(e);
            this.showToastDEP(e.message);
            this.displayLoadingDEP(false);
        })
    }

    handleRecoverEmail(){
        var restoredEmail = "";
        this.showToastDEP("Restoring Account email...")
        firebase.auth().checkActionCode(this.actionCode).then((info)=>{

            restoredEmail = info['data']['email'] as string;

            return firebase.auth().applyActionCode(this.actionCode);

        }).then(()=> {
            this.displayLoadingDEP(false);
            //alert("Account Restored");
            this.showToastDEP("Account email has been restored. It is recommended that you change your password. Reset password link has been send to restored email.");


            firebase.auth().sendPasswordResetEmail(restoredEmail).catch(err =>{
                alert("Password reset failed. Try again");
                this.displayLoadingDEP(false);
            })

            //TODO: send reset email???

        }).catch(err =>{
            //invlaid action code
            this.displayLoadingDEP(false);
            console.error(err);
            
        })
    }


    clickResetPassword(){
        this.hideToastDEP();
    
        if(this.password != this.repeatPassword){
            this.showToastDEP("Repeat password does not match password");
            return;
        }
        this.displayLoadingDEP(true);

        try{
            firebase.auth().verifyPasswordResetCode(this.actionCode).then((email)=>{
            
                firebase.auth().confirmPasswordReset(this.actionCode, this.password).then((resp)=>{
                    //alert("Password has been reset");
                    firebase.auth().signInWithEmailAndPassword(email, this.password).then(()=>{
                        //redirect user to management page
                        this.$router.push({path: "/manage"});
                    })
                    .catch(err=>{
                        this.displayLoadingDEP(false);
                        this.showToastDEP("Error logging in to account. Try again.")
                    })

                })

        }).catch(err =>{
            this.handleError(err);
            console.error(err);
        });

        }catch(e){
            this.handleError(e);     
        }
    }

    handleError(err : any){
        this.displayLoadingDEP(false);
        try{
            switch(err.code){
                case "auth/invalid-action-code":this.showToastDEP("Invalid reset/verify code. Token may have expired or be incorrect. Try send reset/verify link again");break;
                case "auth/argument-error":this.showToastDEP("Reset token error, please try again or send another reset password link");break;
            }

        }catch(e){
            console.error(e);
        }
    }
   

}

</script>

<style scoped>
    .signform {
        margin-top: 1rem;
    }
    
</style>