<template>
    <button class='text-center p-2 pl-3 pr-3 min-w-[5rem] mebutton rounded-xl transition-all cursor-pointer'>
        {{labelText}}
    </button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  components : {
  }
})
export default class MeButton extends Vue {
    @Prop()
    labelText! : string
}
</script>

<style scoped>


@media(max-width: 500px) {
    button {
        font-size: .7rem;
        width: 1rem;
    }
}


.mebutton {
    background-color: var(--color--primary);
    display: inline-block;
    color: white;
}
.mebutton:hover {
    background: var(--color--accent);
    border-radius: 2rem;
}
.mebutton:active {
    background: var(--accent-dark);
}

@media(max-width: 500px) {
    button {
        max-width: 20px;
    }
}

</style>