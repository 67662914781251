
import PossibleVue from '@/scripts/PossibleFramework';


export default class Account extends PossibleVue {

    created(){
        return
    }
    onReadyDEP(uid : string){
        if(this.apiDEP().currentUid()){
            this.$router.push("/manage");
        }
        else{
            this.$router.push("/login");
        }
    }
}

