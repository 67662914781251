

export const WHAT_ALERT = "#phoenix-alert"
export type MessageAlert = {
    title : string,
    message : string
}

export const WHAT_REFRESH = "#phoenix-refresh"

export const WHAT_CONSOLE = "#phoenix-cmessage"
export type MessageConsole = {
    message : string
}