import { PossibleAPI } from "../scripts/PossibleAPI";
import PaymentAPI from "./PaymentAPI";

const STATE_DEFINED = 0;
const STATE_IDLE = 1;
const STATE_ALIVE = 2;
const STATE_DESTROYED = -1;

export interface AppRuntime {
    contextSize : number
}

export default class PossibleApp {
    private api : PossibleAPI
    private payment : PaymentAPI
    private contextList : Set<PossibleContext> = new Set()

    static __app : PossibleApp

    constructor(api : PossibleAPI, payment : PaymentAPI) {
        this.api = api
        this.payment = payment
    }

    getAppDetails() : AppRuntime {
        return {
            contextSize : this.contextList.size
        }
    }

    getApi() : PossibleAPI {
        return this.api
    }
    getPayment() : PaymentAPI {
        return this.payment
    }

    newIdleContext(name : string, init? : PossibleCycle[]) : PossibleContext {
        const newContext = new PossibleContext(this, name, init, false)
        this.contextList.add(newContext)
        return newContext
    }
    newStartedContext(name : string, init? : PossibleCycle[]) : PossibleContext {
        const newContext = new PossibleContext(this, name, init, true)
        this.contextList.add(newContext)
        return newContext
    }
    delist(context : PossibleContext) {
        this.contextList.delete(context);
    }
    sendMessage(caller : PossibleContext, what : number, object : any) { 
        this.contextList.forEach(context => {
            context.PROTECTED_invoke(what, object)
        })
    }

    static Instance() : PossibleApp {
        if(!PossibleApp.__app)
            console.error("Must Initilize app first my caller PossibleApp.config(...)");
        return PossibleApp.__app
    }

    static config(confApi: PossibleAPI, payment : PaymentAPI) : PossibleApp {
        if(PossibleApp.__app)
            console.error("Must only initlize app once for project. Multiple calls to config not allowed")
        PossibleApp.__app = new PossibleApp(confApi, payment)
        return PossibleApp.__app
    }
}

export class PossibleContext {
    app : PossibleApp
    private name: string

    private currentState : PossibleState
    private cycleObjects : Set<HookManager> = new Set()
    private handlers : Set<{(what : number, object: any) : void}> = new Set()

    constructor(app : PossibleApp, name : string, initSet? : PossibleCycle[], started? : boolean) {
        this.app = app
        this.name = name
        if(started)
            this.currentState = PossibleState.ALIVE
        else
            this.currentState = PossibleState.IDLE

        if(initSet) {
            for(let i=0; i < initSet.length; i++) {
                const localCycle = initSet[i]
                this.addCycle(localCycle)
            }
        }
    }
    PROTECTED_invoke(what : number, object : any) {
        this.handlers.forEach(handler => {
            handler(what, object)
        })
    }

    cycleDetails() : string[] {
        const array : string[] = []
        this.cycleObjects.forEach(cycle => {
            array.push(cycle.description())
        })
        return array
    }

    setName(name: string) {
        this.name= name
    }

    addCycle(cycle : PossibleCycle) {
        //TODO: check cycle not already managed.
        if(this.currentState == PossibleState.DESTROYED){
            console.error("Cannot add obejct to contect. Context is destroyed");
            return;
        }
        const newManager = new HookManager(cycle)
        newManager.create()
        if(this.currentState == PossibleState.ALIVE)
            newManager.start()
        this.cycleObjects.add(newManager)
    }

    onMessage(caller : (what : number, object : any) => void) {
        this.handlers.add(caller)
    }
    send(what : number, object : any) {
        this.app.sendMessage(this, what,object)
    }

    start() {
        if(this.currentState != PossibleState.IDLE) {
            console.error("Context can only be started from an IDLE state")
            return
        }
        //start all cycles
        this.cycleObjects.forEach(cycle => {
            cycle.start()
        });
        this.currentState = PossibleState.ALIVE
    }
    stop() {
        if(this.currentState != PossibleState.ALIVE) {
            console.error("Stop Context can only be called when context is alive")
            return
        }
        this.cycleObjects.forEach(cycle => {
            cycle.stop()
        })
        this.currentState = PossibleState.IDLE
    }
    destory(){
        this.app.delist(this)
        if(this.currentState == PossibleState.DESTROYED) {
            console.error("Context is already destroyed")
            return
        }
        if(this.currentState == PossibleState.ALIVE)
            this.stop()
        this.cycleObjects.forEach(cycle => {
            cycle.destory()
        })
        this.currentState = PossibleState.DESTROYED
        this.cycleObjects.clear()
        this.handlers.clear()
    }
}

class HookManager {
    private hookCaller : PossibleCycle
    private currentState : PossibleState

    constructor(hookCaller : PossibleCycle) {
        this.hookCaller = hookCaller
        this.currentState = PossibleState.DEFINED
    }

    description() : string {
        const state = PossibleStateName(this.currentState)
        return `${this.hookCaller.cycleId()} | ${state}`
    }

    create() : boolean {
        if(this.currentState != PossibleState.DEFINED) {
            console.warn("create() can only be called once during defintion. Call Ignored")
            return false
        }
        this.hookCaller.onCreate?.()
        this.currentState = PossibleState.IDLE
        return true
    }

    start() : boolean {
        if(this.currentState != PossibleState.IDLE) {
            console.warn("start() Must only be called when in idle State. Call ignored")
            return false
        }
        this.hookCaller.onStart?.()
        this.currentState = PossibleState.ALIVE
        return true;
    }

    stop() : boolean {
        if(this.currentState != PossibleState.ALIVE) {
            console.error("dfdjf")
            return false
        }
        this.hookCaller.onStop?.()
        this.currentState = PossibleState.IDLE
        return true
    }

    destory() : boolean {
        if(this.currentState == PossibleState.DESTROYED) {
            console.error("Hook.destory() is Already Destroyed")
            return false;
        }
        if(this.currentState == PossibleState.ALIVE)
            this.stop()
        this.hookCaller.onDestory?.()
        this.currentState= PossibleState.DESTROYED
        return true
    }

    getState() : PossibleState {
        return this.currentState;
    }
}

export class BaseCycle implements PossibleCycle {
    static counter = 0
    private id : string

    constructor(name : string) {
        this.id = `${name}:-${BaseCycle.counter++}`
    }

    cycleId() : string {
        return this.id
    }
}

export interface PossibleCycle {
    cycleId() : string
    onCreate?() : void,
    onStart?() : void,
    onStop?() : void,
    onDestory?() : void,
}

enum PossibleState {
    DEFINED = STATE_DEFINED,
    IDLE = STATE_IDLE,
    ALIVE = STATE_ALIVE,
    DESTROYED = STATE_DESTROYED
}

export function PossibleStateName(state : PossibleState) : string {
    switch(state){
        case PossibleState.DEFINED: return "defined";
        case PossibleState.ALIVE : return "alive";
        case PossibleState.DESTROYED : return "destroyed";
        case PossibleState.IDLE : return "idle";
        default : return ""
    }
}

