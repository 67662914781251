
import { Prop } from "vue-property-decorator"
import ListDefine, { ListStyle } from "@/scripts-core/model/ListModel"
import PhoenixComponent from "../PhoenixComponent";


export const  DEFAULT_LIST_STYLE : ListStyle = {
    titleClass : "phxclass-default-list-title",
    headerClass : "phxclass-default-list-header",
    bodyClass : "phxclass-default-list-body",
    footerClass : "phxclass-default-list-footer"
}

export default class PhoenixListItem extends PhoenixComponent {
    componentClass(): string {
        return "phoenix-list-item"
    }
    @Prop()
    itemHandler!: (index : number, object : any)=>void

    @Prop()
    itemObject!:any

    @Prop()
    itemDefine!: ListDefine
    micon = ""
    @Prop()
    itemIndex! : number

    @Prop()
    itemTitleId! : string

    @Prop({default : DEFAULT_LIST_STYLE})
    itemStyle!: ListStyle;

    hasSlot(name : string){
        return this.$slots[name];
    }

    clickItem(){
        if(this.itemDefine.route){
            this.$router.push({path : this.itemDefine.route, query : this.itemDefine.route_params ?? "",params : this.itemDefine.route_params ?? ""})
        }
        else {
            this.itemHandler?.(this.itemIndex, this.itemObject)
        }
    }

    getMaterialIcon(path : string):string {
        const parts = path.split("/");
        const icon = parts[1];
        return icon;
    }

    created(){
        if(this.itemDefine.prefix_icon?.startsWith("@micon")){
            const icon = this.getMaterialIcon(this.itemDefine.prefix_icon);
            this.micon = icon
        }
    }
}
