<template>
    <div class='mealert fixed p-3 top-0 left-0 bottom-0 right-0' v-if="show">
        <div class='mealert-in mobile-full p-2 m-auto bg-white compose'>
            <h2>{{title}}</h2>
            <p v-for="(item, index) in content" :key="`Msg:${index}`" class='mt-2'>{{item}}</p>
            
            <div class='flex justify-end'>
                <MeButton @click="show = !show" :labelText="'Ok'"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import MeButton from "@/components/possibleme/helper/MeButton.vue"
import { MessageAlert, WHAT_ALERT } from "@/scripts-core/PhoenixMessage";

import MeDone from "./MeDone.vue";

const TEXT_SIZE = 100

type RunClass = "regular" | "grow"

@Options({
    components : {
        MeDone,
        MeButton
    }
})
export default class MeAlert extends FireComponent {
    title = ""
    content : string[] = []
    show = false

    runClass : RunClass = "regular"
    
    componentClass = () => "mealert"
    onUserReady(): void {
        return;
    }
    
    onMessage(what : string, object : any){
        if(what == WHAT_ALERT){
            const obAlert : MessageAlert = object;
            this.title = obAlert.title;
            this.content = obAlert.message.split("\n");
            if(obAlert.message.length > TEXT_SIZE)
                this.runClass = "grow"
        }
        this.show = true;
    }
}
</script>

<style scoped>
.mealert {
    background-color: var(--color--blackout);
    display: flex;
    justify-content: center;
    
    
}
.mealert-in {
    border-radius: var(--stm-widget-dialog--radius); 
    max-width: 20rem;
}




</style>