
import FireComponent from '@/components/phoenix/core/FireComponent.vue';
import { Options } from 'vue-class-component';
import { document, listDb } from "@/scripts-core/CloudDB"
import { Category, NDISPlan, PossibleUser, Support, ManagedUser} from 'possibleme-db';
import {ObjectHelper} from '@/scripts-core/cloud/CloudObject';
import { generateReport, ReportItem } from "possibleme-db/lib/util/BusinessUtil"
import { getCategories } from "possibleme-db/lib/model/NDISPlan"
import { alphaSort } from "possibleme-db/lib/Util"
import { volUserManagedUser } from "possibleme-db/lib/Paths"
import { displayDate } from "possibleme-db/lib/util/DateHelper"
import createBig, { BigNum, displayMoney } from "possibleme-db/lib/util/BigNum"
import ReportHeader from "@/components/possibleme/helper/ReportHeader.vue"

type BudgetGroup = {
    category : Category
    items : Support[]
    report : ReportItem
    previous : BigNum
    amount : BigNum
}

@Options({
  components: {
      ReportHeader
  }
})
export default class ReportPlan extends FireComponent {
    showReport = false

    path : string | null = null
    planId : string | null = null



    plan : NDISPlan | null = null
    user : PossibleUser | null = null
    groups : BudgetGroup[] = []
  

    /*Report Fields*/
    titleReport = ""
 
    componentClass = ():string => "report-budget"

    getPrevious(category : Category):string {
        return `(${displayMoney(this.plan?.funding[category.idRef].amount ?? "0")})` 
    }

    displaySpan(start : number, end : number):string {
        return `${displayDate(start)}`
    }
    displayInvoice(support : Support):string {
        if(!support.isPaid)
            return "Unpaid"


        const num = support.invoiceNumber;
        if(!num)
            return "--"
        return num;
    }
    displayAmount = displayMoney
    
    async onUserReady(uid : string | null) {
        if(uid == null)
            return

        this.planId = (this.$route.query.planId as string) ?? null;
        this.path = `/users/${uid}/plans/${this.planId}`

        try {
            const refUser = await document<PossibleUser>(this, `/users/${uid}`);
            if(refUser == null)
                return this.redirect404()
            const refPlan = await document<NDISPlan>(this, this.path);
            if(refPlan == null)
                return this.redirect404();
            const refSupports = await listDb<Support>(this, `${refPlan.path()}/support`);
            this.plan = refPlan.value(this)

            const supports = ObjectHelper.convertRaw(refSupports);

            this.user = refUser.value(this);
            this.titleReport = `NDIS Report ${this.plan.name}`

            this.groups =  this.calculateBudgetGroup(this.plan, supports);

            const mUser = this.plan.managedUser
        
            if(mUser){
                console.time('doUser')
                const localUser = await document<ManagedUser>(this, `${volUserManagedUser(uid)}/${mUser}`)
                console.timeEnd('doUser')

                
                if(localUser != null)
                    this.user = localUser.value(this);
            }

            this.showReport = true;

        }catch(e : any){
            console.error(e);
            console.warn(e.code);
            this.redirect404()
        }
    }
    calculateBudgetGroup(plan : NDISPlan, supports : Support[]) : BudgetGroup[] {
        /* find categories */
        const report  = generateReport(plan, supports)
        const categories = getCategories(plan);
        alphaSort(categories, "display")
        const group : BudgetGroup[] = []

        for(let icat in categories){
            const cat = categories[icat];
            const catSupports = this.findSupportCategory(cat, supports);
            const item = report.getItem(cat);
            if(!item)
                continue

            const igroup : BudgetGroup = {
                category : cat,
                items : catSupports,
                report : item,
                previous : createBig(plan.funding[cat.idRef].init),
                amount : createBig(plan.funding[cat.idRef].amount)
            }
            group.push(igroup);
        }
        return group;
    }

    findSupportCategory(cat : Category, list : Support[]) : Support[]{
        const array : Support[] = []
        for(let index in list){
            const ilocal = list[index];
            if(ilocal.category.idRef === cat.idRef)
                array.push(ilocal);
        }
        return array;

    }
    
}
