
import { Options } from 'vue-class-component';
import FireComponent from '../phoenix/core/FireComponent.vue';
import PossibleMenu2 from "@/components/possibleme/PossibleMenu2.vue"
import { toPossibleDate } from "possibleme-db/lib/util/DateHelper"
import MeAction from "../possibleme/helper/MeAction.vue"
import { signout } from "@/scripts-core/CloudDB"

@Options({
    components: {
        PossibleMenu2,
        MeAction
    }
})
export default class MobileMenu extends FireComponent {
    componentClass = () => "MobileMenu"
    showMenu = false
    currentDate = "1/1/1970"

    closeMenu = () => this.showMenu = false

    mounted() {
        this.currentDate = toPossibleDate(Date.now()) 
    }

    async clickLogout() {
        await signout()
        this.$router.push("/login")
    }
}
