<template>
    <div @click="clkMenu(menu)" class='menu-item' :class="menu.mClass" v-for="(menu, index) in menuItems" :key="`menu-${index}`">
        <div class='menu-span'>
            <span class='material-icons menu-icon'>{{ menu.prefix_icon }}</span>
            <div>{{ menu.title }}</div>
        </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import PhoenixList from "../phoenix/list/PhoenixList.vue";
import ListDefine from '@/scripts-core/model/ListModel'
import { Prop } from "vue-property-decorator";

@Options({
    components: {
        PhoenixList
    },
    emits: ["onMenuClick"]
})

export default class PossibleMenu2 extends Vue {
    selected : ListDefine | null = null

    clkMenu(item : ListDefine) {
        
        if(this.selected)
            this.selected.mClass = "regular"
        item.mClass = "select"
        this.selected = item
        const select = this.$route.query.select ?? null

        const route : any = {
            path : item.route ?? "/",
            query : {
            }
        }
        if(select){
            route.query.select = select
        }
        this.$router.push(route)
        this.$emit("onMenuClick")
    }

    menuItems : ListDefine[] = [
    {
        title :"Dashboard",
        route : "/app",
        prefix_icon : "home",
        mClass : "regular"
    },
    {
      title : "Notifications",
      route : "/app/activity",
      prefix_icon : "notifications",
       mClass : "regular"
    },
    {
      title : "Plans",
      route : "/app/plan",
      prefix_icon : "text_snippet",
       mClass : "regular"
    },
    {
      title : "Supports",
      route : "/app/support",
      prefix_icon : "accessibility_new",
      mClass : "regular"
    },
    {
      title : "Goals",
      route : "/app/goals",
      prefix_icon : "thumb_up",
       mClass : "regular"
    },
    {
      title : "Providers",
      route : "/app/provider",
      prefix_icon : "favorite",
       mClass : "regular"
    },
    {
      title : "Achievements",
      route : "/app/achievement",
      prefix_icon : "beenhere",
       mClass : "regular"
    },
    {
      title : "Budgets",
      route : "/app/budget",
      prefix_icon : "pie_chart",
       mClass : "regular"
    },
    {
      "title" : "Manage",
      route : "/app/manage",
      prefix_icon : "settings",
       mClass : "regular"
    }]
}
</script>

<style scoped>
.select {
    background-color: purple;
}
.regular {
    background-color: var(--color--primary);
    color: var(--color--white);
    
}

.menu-item {
  padding-top: var(--stm-menu--spacing);
  padding-bottom: var(--stm-menu--spacing);
  display: block;
  transition: all .1s ease;
  
  
  
  cursor: pointer;
}
.menu-item:hover {
  background-color: var(--color--accent);
  color:white;
  transition: all .1s ease;
  
}
.menu-icon {
  margin-right: 1rem;
}
.menu-span{
  display: flex;
}
</style>