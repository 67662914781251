
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject, { ObjectHelper } from "@/scripts-core/cloud/CloudObject";
import { ManagedUser } from "possibleme-db"
import { volUserManagedUser } from "possibleme-db/lib/Paths"
import { pushDb, user, collectionDb } from "@/scripts-core/CloudDB"
import { fromInputDate, toPossibleDate } from "possibleme-db/lib/util/DateHelper"
import {Options} from "vue-class-component"

import MUserRow from "@/components/possibleme/model/MUserRow.vue"
import MeAction from "../helper/MeAction.vue"
import MeDone from "../helper/MeDone.vue"

@Options({
    components : {
        MUserRow,
        MeAction,
        MeDone
    }
})
export default class MUser extends FireComponent {
    showInput = false
    managedUsers : CloudObject<ManagedUser>[] = []

    formFirstname = ""
    formLastname = ""
    formNDISNo = ""
    formBirthday = ""

    onUserReady(uid : string | null) : void {
        if(!uid) return
        //user here
        collectionDb<ManagedUser>(this.getContext(), volUserManagedUser(uid), users => {
            ObjectHelper.sort(this, users, (a, b)=>{
                if(a.firstname > b.firstname)
                    return 1;
                return -1;
            } )
            this.managedUsers = users;
        })

    }
    componentClass(): string {
        return "live-estimator"
    }
    onAddUser(){
        this.showInput = true
    }
    

    async onSubmit() {
        const parseDate = fromInputDate(this.formBirthday);
        let birthday : string | null = null
        if(parseDate)
            birthday = toPossibleDate(parseDate)
      
        const mUser : ManagedUser = {
            firstname: this.formFirstname,
            lastname : this.formLastname,
            birthday,
            ndisNo : this.formNDISNo
        }
        try {
            const uid = user();
            if(!uid)
                throw "no user here"
            await pushDb(this, volUserManagedUser(uid), mUser);
            this.formFirstname = ""
            this.formLastname = ""
            this.formNDISNo = ""
            this.formBirthday = ""
            this.showInput = false
        }
        catch(e){

            console.error(e);
        }
    }
 
}
