import firebase from "firebase/app"
import CloudObject, { createObject,  CloudAccess, CloudArrayCallback, CloudObjectCallback } from "./cloud/CloudObject";
import PhoenixContext from "./core/PhoenixContext";
import CloudRuntime, { Cloud } from "../Cloud"
import { Contact } from "possibleme-db";

const dbListeners : DbCallback[] =[]
/**
 * Listens to API calls to receive events
 * @param callback Database callback
 */
export function addDbListener(callback : DbCallback) : void {
    dbListeners.push(callback);
}

function triggerDb(method : DbMethod, path : string){
    dbListeners.forEach(call => call({
        method,
        path
    }))   
}

type DbMethod = "push" | "update" | "write" | "document" | "connect" | "collection" | "list" | "remove" | "file"
type DbCall = {
    method : DbMethod,
    path : string
}
type DbCallback = (action : DbCall) => void
type Snapshot = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
type QuerySnapshot = firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
type WhereMethod = "=="
type SimpleQuery = {
    where : {
        field : string,
        method : WhereMethod,
        value : any
    }
}

export type DocumentInit<T> = () => T
type QueryBuild = firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | firebase.firestore.Query
type UserReady = {
    uid : string,
    email : string,
    verified : boolean
}
export type ReadyCall = (ready : UserReady | null) => void


/*--------------------------- HELPER FUNCTIONS ------------------------- */


/**
 * Sanitizes an object that is safe to post to the database
 * props starting with '_' will be stripped. 
 * functions will also be stripped. Convert to raw Pojo
 * @param object Request object to sanitize
 * @returns the sanitized object
 */
export function sanitizeObject(object : any) : any {
    const request : any = {}
    for(const key in object){
        if(key.startsWith("_"))
            continue
        const prop = object[key];
        if(typeof prop == "function" || prop === undefined)
            continue
        request[key] = prop
    }
    return request;
}

/**
 * Builds a Query object from a collection an SimpleQuery object
 * @param collection (Collection) path to build query
 * @param query Optional simple query to build
 * @returns a firebase QueryBuilder 
 */
function buildQuery(collection : string, query? : SimpleQuery) : QueryBuild {
    let myQuery : QueryBuild = firebase.firestore().collection(collection);
    if(query){
        if(query.where)
            myQuery = myQuery.where(query.where.field, query.where.method, query.where.value);
    }
    return myQuery;
}


async function awaitFetch(url : string) : Promise<ArrayBuffer> {
    try{
        const result = await fetch(url);
        const buffer = await result.arrayBuffer() as ArrayBuffer
        return Promise.resolve(buffer);
    }catch(e){
        return Promise.reject(wrapError(e));
    }
}

function convertQuerySnapshot<T>(access : CloudAccess, snap : QuerySnapshot) : CloudObject<T>[]{
    const array : CloudObject<T>[] = [];
    for(let i=0; i < snap.docs.length; i++){
        const cloud = convertSnapshot<T>(access, snap.docs[i])
        if(cloud != null)
            array.push(cloud);
    }
    return array;
}
function convertSnapshot<T>(access : CloudAccess, snap : Snapshot) : CloudObject<T> | null {
    const object = snap.data() as T;
    if(!snap.exists || !object)
        return null;
    const path = snap.ref.path
    //object = MapClassType(path, object);
    
   
    return createObject(CloudRuntime, access, {object, path})
}
function registerDispose(context : PhoenixContext, dispose : Dispose){
    context.registerLifecycle({
        onCreate : ()=>{return},
        onDispose : ()=>{dispose()}
    })
}

/**
 *   Export Stuff
 */



export type Dispose = ()=>void
export function Details() : any {
    return firebase.app().options
}

export interface SaveFile {
    filename : string,
    filePath : string,
    fileBytes : number
}
/**
 * Returns the path of the object to get if user path join a uid
 * @param path to convert to a User path if contains  '@user'
 * @returns 
 */
 export function userPath(path : string) : string {
    path = path.trim();
    if(path == "" || path == "/")
        throw "Cannot accept empty path"
    let start = 0;
    let end = path.length;
    if(path.startsWith("/"))
        start += 1;
    if(path.endsWith("/"))
        end -= 1;
    //substr @ start, end
    path = path.substring(start, end);

    const uid = user();
    
    const segs = path.split("@user");
    if(segs.length > 1 && uid == null)
        throw "Attempting to join user path with no user"
    const joinPath = segs.join(uid ?? "null");
    if(joinPath.includes("@"))
        throw "user variable path not valid"
    return joinPath;
}


/*------------------------ AUTH FUNCTIONS --------------------------- */

/**
 * Created a new user account for authentication
 * @param email of new User
 * @param password of new User
 * @returns 
 */
export async function account(email : string, password: string){
    try{
        const result = await firebase.auth().createUserWithEmailAndPassword(email, password);
        return Promise.resolve(result.user?.uid);
    }catch(e){
        return Promise.reject(wrapError(e));
    }
}
/**
 * Logs in a new User with email, password authentication
 * @param email of User to login to
 * @param password of User to login to
 * @returns Promise whether login was success 
 */
 export async function login(email : string, password : string) : Promise<string> {
    try{
        const result = await firebase.auth().signInWithEmailAndPassword(email, password);
        const uid = result.user?.uid ?? null
        if(uid == null)
            return Promise.reject("User is null")
        return uid
    }catch(e){
        return Promise.reject(wrapError(e));
    }
}
/**
 * @returns Signs out a user
 */
export async function signout(): Promise<void> {
    return firebase.auth().signOut();
}

export async function authRefresh(): Promise<void> {
    await firebase.auth().currentUser?.getIdTokenResult(true).then(()=> {
        console.log("Token Refreshed")
    }).catch(e => {
        console.error(e)
    })
}

/*-------------------------------- DATABASE --------------------------- */
/**
 * Gets a CloudObject reference based on path on the object
 * @param access Caller access token
 * @param path of Document to obtain
 * @returns CloudObject pointer to new object
 */
export async function document<T>(access: CloudAccess, path : string) : Promise<CloudObject<T>| null> {
    triggerDb("document", path);
    try{
        const snap = await firebase.firestore().doc(userPath(path)).get();
        const cloud = convertSnapshot<T>(access, snap);
        return cloud;
    }
    catch(e){
        return Promise.reject(wrapError(e));
    }
}

/**
 * Callback to listen to user auth event,   ARRIVE & LEAVE
 * @param callback User ready callback
 * @returns Disposable to remove the callback
 */
export function ready(callback : ReadyCall): Dispose {
    const dereg = firebase.auth().onAuthStateChanged(cred =>{
        if(cred?.uid)
            callback({
                email : cred.email ?? "",
                uid : cred.uid,
                verified : cred.emailVerified
            });
        else
            callback(null);
    });
    return dereg;
}

/**
 * Obtains the user uid that is logged on
 * @returns the user 'uid' or  null if not present
 */
export function user(): string | null {
    return firebase.auth().currentUser?.uid ?? null;
   
}
export function uidThrow(): string {
    const uid = user();
    if(!uid)
        throw "User should be avaliable"
    return uid;
}
/**
 * Posts an object to the database
 * @param access Caller access token
 * @param collection (Collection) path to push data to
 * @param object The object to post at the location
 * @returns CloudObject reference of newly added object
 */
export async function pushDb<T>(access : CloudAccess, collection : string, object : T) : Promise<CloudObject<T>> {
    triggerDb("push", collection);
    try {
        const snap = await firebase.firestore().collection(userPath(collection)).add(sanitizeObject(object));

        const cloudObject : CloudObject<T> = createObject(CloudRuntime, access, {object, path : snap.path })
        return Promise.resolve(cloudObject);
    }
    catch(e){ 
        //console.error(e);
        return Promise.reject(e);
    }
}
/**
 * 
 * @param access Caller access token
 * @param collection (Collection) path to push data to
 * @param query Optional simple query token 
 * @returns Array of CloudObject references
 */
export async function listDb<T>(access : CloudAccess, collection : string, query?: SimpleQuery) : Promise<CloudObject<T>[]> {
    triggerDb("list", collection);
    try{
        const myQuery = buildQuery(userPath(collection), query);
        const snap = await myQuery.get();
        const array = convertQuerySnapshot<T>(access, snap);
        return Promise.resolve(array);
    }catch(e){
       
        return Promise.reject(wrapError(e));
    }
}
/**
 * Updates a document given some path and request object. NOTE: Will fail if object does not exist
 * @param access Caller access token
 * @param path (Document) path to update to
 * @param object Request object to update this path with
 * @returns Whether update was success or fail
 */
export async function updateDb<T>(access : CloudAccess, path : string, object : T) : Promise<void> {
    triggerDb("update", path);
    try {
        object  = sanitizeObject(object);

        if(Object.keys(object).length == 0)
            return Promise.reject(rejectCode("request-empty"))

        await firebase.firestore().doc(userPath(path)).update(object);
        const cloudObject : CloudObject<T> = createObject(CloudRuntime, access, { object, path })
     
    }
    catch(e){
        return Promise.reject(e);
    }
}
/**
 * Writes a document to database. Will create it if it doesn't exist
 * @param access Caller access token
 * @param path (Document) path to update to
 * @param object Request object to update this path with
 * @returns Whether update was success or fail
 */
export async function writeDb<T>(access : CloudAccess, path : string, object : T):Promise<CloudObject<T>>{
    triggerDb("write", path);
    try {
        object  = sanitizeObject(object);
        if(Object.keys(object).length == 0)
            return Promise.reject(rejectCode("request-empty"))

        await firebase.firestore().doc(userPath(path)).set(object, {merge : true});
        const cloudObject : CloudObject<T> = createObject(CloudRuntime, access, { object, path })
        return cloudObject;
    }
    catch(e){
        return Promise.reject(e);
    }
}
/**
 * Removes an object from the database in path
 * @param path (Document) path to remove to
 * @returns success of failed operation
 */
export async function removeDb(path : string) : Promise<void> {
    triggerDb("remove", path);
    return firebase.firestore().doc(userPath(path)).delete();
}
/**
 * 
 * @param context Attached lifecycle context
 * @param collection (Collection) path to receive live updates from
 * @param callback Array callback
 * @returns disposable function to cancel callback
 */
export function collectionDb<T>(context : PhoenixContext, collection : string, callback : CloudArrayCallback<T>) : Dispose {
    triggerDb("collection", collection);
    const dispose : any = firebase.firestore().collection(userPath(collection)).onSnapshot(snap =>{
        const array = convertQuerySnapshot<T>(context, snap);
        callback(array);
    });
    registerDispose(context, dispose);
    return dispose;
}
/**
 * 
 * @param context Attached lifecycle context
 * @param document (Document) path to receive live updates from
 * @param callback Object callback with data
 * @param init Will be called if object path is empty. Allows for create an init object
 * @returns disposable function to cancel callback
 */
export function connectDb<T>(context : PhoenixContext, document : string, callback : CloudObjectCallback<T>, init? : DocumentInit<T>) : Dispose {
    triggerDb("connect", document);
    const dispose = firebase.firestore().doc(document).onSnapshot(async snap =>{ 
        const cloud = convertSnapshot<T>(context,snap);
        if(cloud != null)
            callback(cloud);
        else if(init) {
            const dat = await writeDb(context, document, init())
            callback(dat);
        }
    })
    registerDispose(context, dispose)
    return dispose;
}

export type UploadResult = {
    folder : string,
    filename : string,
    size : number
}



/* ------------------------- Storage ------------------------ */
/**
 * Gets a Binary file from storage
 * @param folder Directory
 * @param filename The filename
 * @returns Binary file result
 */
export async function download(folder : string, filename : string): Promise<Uint8Array> {
    return new Promise((resolve, reject)=>{
        const path = `${folder}/${filename}`;
        firebase.storage().ref(path).getDownloadURL().then(async url=>{
            const buff = await awaitFetch(url);
            const uint8 = new Uint8Array(buff);
            resolve(uint8);
        }).catch(e=>reject(e));
    })
}
/**
 * Gets a URL of some storage file
 * @param folder Directoire of file
 * @param filename The filename to get
 * @returns URL of file
 */
export async function downloadURL(path : string): Promise<string> {
    return new Promise((resolve, reject) => {
        firebase.storage().ref(path).getDownloadURL().then(url=>{
            resolve(url);
        }).catch(e=>reject(e));
    })
}
/**
 * Gets downloadable URL of some User file
 * @param filename of User file
 * @returns url of storage file
 */
export async function downloadUserURL(filename : string): Promise<string> {
    return downloadURL(userPath(`users/@user/${filename}`));
}
/**
 * Saves a file to the user's storage bucket
 * @param data File (Data)
 * @param filename The filename to use
 * @returns Save file with result of operation
 */
export async function saveUserFile(data: Blob | Uint8Array | ArrayBuffer, filename: string) : Promise<SaveFile> {
    try {
        if(!filename)
            throw "saveUserFile requries valid filename"
        const filePath = `${userPath("users/@user")}/${filename}` 

        const res = await firebase.storage().ref(filePath).put(data);
        dbListeners.forEach(call => call({ //TODO:fix in proper place
            method : "file",
            path : filePath
        }))
        return {
            filename,
            filePath,
            fileBytes : res.totalBytes
        }
        
    }
    catch(e){
        return Promise.reject(e);
    }
}
export async function deleteFile(path : string): Promise<void> {
    return firebase.storage().ref(path).delete();
}

export async function deleteUserFile(filename: string): Promise<void> {
    try {
        const filePath = `${userPath("users/@user")}/${filename}`
        return firebase.storage().ref(filePath).delete();
    }
    catch(e){
        return Promise.reject(e);
    }
}
export async function listUserFile(): Promise<string[]> {
    try {
        const filePath = userPath("users/@user");
        const dir = await firebase.storage().ref(filePath).listAll();
        
        const fileDir : string[] = []
        dir.items.forEach(res => {
            fileDir.push(res.name);
        })
        return fileDir;
    }
    catch(e){
        return Promise.reject(e);
    }
}


/*------------------------------------------- FUNCTIONS --------------------------------------------- */



/**
 * Gets a HTTP callable function
 * @param name of Callable function
 * @returns callable
 */
export async function callHttp(name : string){
    return await firebase.functions().httpsCallable(name);
}

function wrapError(e : any) : CloudError{
    const nativeCode = e?.code ?? "unknown";
    let code : ErrorCloud = "unknown"

    switch(nativeCode){
        case "unknown":code = "unknown";break;
        case "permission-denied":code = "request-denied";break;
    }
    return new CloudError(code);
}
function rejectCode(code : ErrorCloud) : CloudError {
    return new CloudError(code);
}

export type ErrorCloud = "unknown" | "request-denied" | "user-null" | "request-empty"
/* Cloud Error Class */
export class CloudError {
    code : ErrorCloud
    constructor(code : ErrorCloud){
        this.code = code;
    }
}


/**
 * Wraps a primary contact (self reference) as a CloudObject
 * @param access Caller access toke
 * @param contact Local context to wrap
 * @returns A CloudObject of a primary contact
 */
export function wrapContact(access : CloudAccess,  contact : Contact){
    return createObject(CloudRuntime, access, {
        object : contact,
        path : "@primary"
    })
}

/**
 * Maps a plain object to a class based functional object
 * @param path (Document) path of the object
 * @param object Object to convert
 * @returns the class converted object
 */
export function mapClassType<T>(path : string, object : any) : T {
    for(let i=0; i < classTypeMap.length; i++){
        const convert = classTypeMap[i];
        if(convert.matcher.test(path)){
            //DebugNote(`Converting path: ${path}`)
            return convertClass<any, T>(object, convert.mapper)
        }
    }
    return object;
}


function convertClass<In, Out>(raw : In, mapper : (cloud : Cloud) => Out) : Out {
    const struct = mapper(CloudRuntime) as any;
    for(const key in raw){
        struct[key] = raw[key];
    }
    return struct as  Out
}
/**
 * Sets the class mapper for raw database objects
 * @param list The class mapping specification
 */
export function setClassMapping(list : ClassConvert<any, any>[]){
    const localMap : ClassMapping[] = []

    for(let i=0; i < list.length; i++){
        const spec = list[i];

        if(spec.path.startsWith("/"))
            spec.path = spec.path.substring(1);

        const regex = new RegExp(`^${spec.path. split("~").join("[a-zA-Z0-9_-]+")}$`);
        localMap.push({
            mapper : spec.mapping,
            matcher : regex,
            name : `@classmap/${spec.name}`
        })
    }
    classTypeMap = localMap
}

let classTypeMap : ClassMapping[] = []

type ClassMapping = {
    matcher : RegExp,
    mapper : (cloud : Cloud) => any
    name  : string
}

/* Class Convertor */
export type ClassConvert<In, Out> = {
    path : string,
    mapping : (cloud : Cloud)=> Out
    name : string
}

