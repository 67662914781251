/* eslint-disable */
import { Vue } from 'vue-class-component';
import { PossibleAPI } from './PossibleAPI';
import PossibleApp, { PossibleContext} from './PossibleApp';
import * as WHAT from '@/scripts/what';
import { Demount } from '@/Cloud'
import { sendAlertMessage } from '@/components/phoenix/PhoenixComponent';

export interface PossibleFrame {
    possibleNameDEP():string
}

/** @deprecated Please use fire componenet */
export default abstract class PossibleVue extends Vue implements PossibleFrame {
    
    private priv_context : PossibleContext = PossibleApp.Instance().newStartedContext("");
    private connections : Demount[] = [];

    isApplicationPath() : boolean {
        return this.$route.path.startsWith("/app")
    }

    /** @deprecated Please use fire componenet */
    onReadyDEP(_uid : string | undefined) : void {
        return;
    }

    sendAlert(title : string, message : string){
        sendAlertMessage(title, message);
    }

    created() : void {
        this.priv_context.setName(this.possibleNameDEP());
        this.apiDEP().listenUser(this.onReadyDEP);
    }

    /** @deprecated Please use fire componenet */
    possibleNameDEP(): string {
        return "";
    }

    /** @deprecated Please use fire componenet */
    contextDEP():PossibleContext {
        return this.priv_context;
    }

    /** @deprecated Please use fire componenet */
    apiDEP():PossibleAPI{
        return PossibleApp.Instance().getApi();
    }

    unmounted(){
        this.priv_context.destory();
        this.priv_context = PossibleApp.Instance().newStartedContext("");
        for(let i=0; i < this.connections.length; i++)
            this.connections[i]();
        this.connections = [];
    }

    /** @deprecated Please use fire componenet */
    showToastDEP(message: string) :void {
        this.contextDEP().send(WHAT.TOAST_MESSAGE, message);
    }

    /** @deprecated Please use fire componenet */
    hideToastDEP(): void{
        this.contextDEP().send(WHAT.HIDE_TOAST, {});
    }

    /** @deprecated Please use fire componenet */
    displayLoadingDEP(show : boolean):void{
        this.contextDEP().send(WHAT.SHOW_LOAD, show);
    }

    /** @deprecated Please use fire componenet */
    fastDocument(path : string, callback :(object : any)=>void):void{
        const demount = this.apiDEP().connectDocument(path, callback);
        this.connections.push(demount);
    }

}