<template>
    <div  class='report-plan'>
        <div v-if="showReport">
            <ReportHeader :headerTitle="'Summary'" :showReadit="true" :user="user" :plan="plan">
            </ReportHeader>
           
            <h1 class='report-header py-2'>Area of Development</h1>
            <table class='widget-table widget-report'>
                <tr>
                    <th>Goals</th>
                </tr>
                <tr v-for="(goal, index) in reportGoals" :key="`goal:${index}`">
                    <td v-if="goal" >{{goal}}</td>
                </tr>
            </table>
          
            <div class='mt-2 pgbreak-before' > 
                <h3 class='report-header pt-2'>Self Assessment</h3>
                <p>Note: The percentage point is based on user's self-assessment of the service after attending every 5 sessions or over the period of 2 months.</p>
           
                <table class='widget-table widget-report'>
                    <tr>
                        <th>Service</th>
                        <th>Goals Supported</th>
                        <th>Total Hours</th>
                    </tr>
                    <tr v-for="(ref, index) in ideas" :key="`support:${index}`">
                        <td>{{ref.object.name}}</td>
                        <td>
                           <div v-for="(rev, revI) in findReviews(ref.object)" :key="`Review:${revI}`">
                                {{rev.name}} - ({{Number(rev.percent * 100).toFixed(1)}} %)
                            </div>
                            <div v-if="findReviews(ref.object).length == 0">
                                Did not assess
                            </div>
                        </td>
                        <td>{{calcHours(ref)}}</td>
                    </tr>
                </table>
            </div>
            <div class='pgbreak-before' >
                <h1 class='report-header mt-2 py-2'>Support Team</h1>
                <table class='widget-table widget-report'>
                    <tr>
                        <th>Name</th>
                        <th>Company</th>
                        <th>Contact Number</th>
                    </tr>
                    <tr v-for="(contact, index) in pulledContacts" :key="`provider:${index}`"> 
                        <td>{{contact.contactName}}</td>
                        <td>{{contact.contactCompany }}</td>
                        <td>{{contact.contactNumber}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-if="!showReport">
            <h2>... Report is generating. Please wait a moment</h2>
        </div>
    </div>
</template>

<script lang="ts">
import FireComponent from '@/components/phoenix/core/FireComponent.vue';
import  CloudObject, { ObjectHelper } from '@/scripts-core/cloud/CloudObject';
import { BaseUser, Goals, ManagedUser, NDISPlan, PossibleUser, Provider, Support, SupportIdea } from 'possibleme-db';
import { Options } from 'vue-class-component';
import { document, listDb } from "@/scripts-core/CloudDB"
import { computeReviewList, GoalReview, SupportReview } from 'possibleme-db/lib/Compute';
import ReportHeader from "@/components/possibleme/helper/ReportHeader.vue"
import { volUserManagedUser } from 'possibleme-db/lib/Paths';

type PullContact = {
    contactName : string,
    contactCompany : string,
    contactNumber : string
}

@Options({
  components: {
      ReportHeader
  }
})
export default class ReportPlan extends FireComponent {
    showReport = false;

    planId : string | null = null
    path  : string | null = null
    user : BaseUser | null = null

    supports : Support[] =[]

    /* Report Data*/
    reportGoals : string[] = []
    reviews : SupportReview[] = []
    ideas : CloudObject<SupportIdea>[] = []
    
    //providers : Provider[] = []
    pulledContacts : PullContact[] = []

    plan! : NDISPlan
    
    async onUserReady(uid: string | null) {
        if(uid == null) return;
        this.planId = (this.$route.query.planId as string) ?? null

        try {
            const refUser = await document<PossibleUser>(this, `/users/${uid}`);
            const refPlan = await document<NDISPlan>(this, `/users/${uid}/plans/${this.planId}`);
            const refGoals = await document<Goals>(this, `/users/${uid}/plans/${this.planId}/data/goals`);
            const refSupports = await listDb<Support>(this, `/users/${uid}/plans/${this.planId}/support`);
            const refIdeas = await listDb<SupportIdea>(this, `/users/${uid}/plans/${this.planId}/ideas`);
            let refProviders = await listDb<Provider>(this, `/users/${uid}/providers`);
            
            this.supports = ObjectHelper.convertRaw(refSupports);
            
            const pids : string[] =[];
            const contactsPulled : string[] = []


            this.supports.forEach(item => {
                if(!pids.includes(item.providerId))
                    pids.push(item.providerId);
                //check contact pulled
                if(!contactsPulled.includes(item.contact.path)){
                    contactsPulled.push(item.contact.path)
                    this.pulledContacts.push({
                        contactName : item.contact.name,
                        contactCompany : item.provider.providerName,
                        contactNumber : item.contact.phone
                    })
                }
            });
            refProviders = refProviders.filter(pProv => pids.includes(pProv.id()))

            if(refUser == null || refPlan == null || refGoals == null)
                return this.redirect404(`User, plan or goals null`);
            this.user = refUser.value(this);
        
            
            this.ideas = refIdeas

            
            this.plan = refPlan.value(this)
            /* Filter providers */
        
            /* Check plan user */
            const mUser = this.plan.managedUser
        
            if(mUser){
                console.time('doUser')
                const localUser = await document<ManagedUser>(this, `${volUserManagedUser(uid)}/${mUser}`)
                console.timeEnd('doUser')

                
                if(localUser != null)
                    this.user = localUser.value(this);
            }

            /* Set Report data */
            const goals = refGoals.value(this);
            for(let index in goals.goalList){
                const ilocal = goals.goalList[index];
                if(ilocal.shown)
                    this.reportGoals.push(ilocal.name);
            }
            /* Compute Reviews */
            const ideas = ObjectHelper.convertRaw(refIdeas);
            this.reviews = computeReviewList(ideas, refGoals.value(this), true);

            this.showReport = true;
                
        }catch(e) {
            /*
            const code : ErrorCloud = "unknown"
            if(e instanceof CloudError){
                const err = e as CloudError
                code = err.code
            }*/
            console.error(e);
        }
    }
   

    findSupport(supportIdeaId : string) : Support[] {
        return this.supports.filter(item => item.ideaId === supportIdeaId);
    }
    calcSupportHours(array : Support[]) : string {
        
        let totalMillis = 0 //TODO
        const conv = 1000 * 60 * 60 //TODO: MOVE elsewhere
        array.forEach(item => {
            const delta = (item.dateEnd - item.dateStart);
            totalMillis += delta;
        });
        return Number(totalMillis / conv).toFixed(2);
    }
    findReviews(idea : SupportIdea): GoalReview[] {
        for(let i=0; i < this.reviews.length; i++){
            if(this.reviews[i].support == idea.name)
                return this.reviews[i].reviews
        }
        return []
    }

    componentClass() : string {
        return "report-plan"
    }
    calcHours(idea : CloudObject<SupportIdea>) : string {
        const foundSupports = this.findSupport(idea.id())
        return this.calcSupportHours(foundSupports)
    }
}
</script>

<style scoped>

.align{
    text-align: right;
}

.report-plan {
    padding: 16px;
    overflow:auto;
}
table {
    width: 100%;
}

</style>