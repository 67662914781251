import CloudObject, { CloudAccess } from "../scripts-core/cloud/CloudObject";
import { uidThrow,  listDb, writeDb, pushDb, document, callHttp, removeDb, userPath } from "../scripts-core/CloudDB";
import * as model from "possibleme-db";
import { RepeatAlgo, nextDate, fromMillis, DateVal, convert, toPossibleDate } from "possibleme-db/lib/util/DateHelper";
import * as paths from "possibleme-db/lib/Paths"


export async function getUser(access : CloudAccess): Promise<CloudObject<model.PossibleUser> | null> {
    try {
        const uid = uidThrow();
        const path = paths.pageUser(uid);
        return document<model.PossibleUser>(access, path)
    }
    catch(e){
        return Promise.reject(e);
    }
}

export async function getManagedUser(access : CloudAccess, planManaged : CloudObject<model.NDISPlan> | string | null): Promise<CloudObject<model.BaseUser> | null> {
    if(planManaged == null)
        return getUser(access);
    try {
        const uid = uidThrow();
        let mUserId : string | null | undefined
        if(typeof planManaged == "string")
            mUserId = planManaged
        else
            mUserId = planManaged.value(access).managedUser
            
        if(!mUserId)
            return Promise.reject("This plan does not have a managed user");
        const path = paths.pageManagedUser(uid, mUserId);
        return document<model.ManagedUser>(access, path);
    }
    catch(e){
        return Promise.reject(e);
    }
}
/**
 * Gets all managed sub users for a authenticated user
 * @param access 
 * @returns 
 */
export async function getAllManagedUsers(access : CloudAccess): Promise<CloudObject<model.ManagedUser>[]> {
    const path = userPath("users/@user/managed-users")
    return listDb<model.ManagedUser>(access, path);
}


/**
 * Returns the list of support suggestions given some support path
 * @param access 
 * @param path 
 * @returns 
 */
async function getSupportSuggest(access : CloudAccess, path : string): Promise<string[]> {
    try {
        const uid = uidThrow()
        const ideas : string[] = []
        const result = await listDb<model.SupportIdea>(access, path);
        for(let i=0; i < result.length; i++)
            ideas.push(result[i].value(access).name);
        return ideas;
    }catch(e){
        return Promise.reject(e);
    }
}
type RemindOps = {
    support : CloudObject<model.Support>,
    param : number,
    algo : RepeatAlgo
}

/**
 * Pushes a reminder given  the selected parameters
 * @param access 
 * @param collectionReminder reminder collection name
 * @param ops Parameter options including support to add reminder for
 * @returns 
 */
export async function setReminder(access : CloudAccess, collectionReminder : string, ops : RemindOps):Promise<CloudObject<model.SupportReminder>> {
    try{
        //const uid = User();
        //if(uid == null) return Promise.reject("No user");
        const supportPath = ops.support.path();
        const supportTime = fromMillis(ops.support.value(access).dateStart);
        const remind = nextDate(ops.algo, supportTime, ops.param * -1);
        const remindTime = remind.getTime();
        const remindTimeStamp = remind.toLocaleString();

        const request : model.SupportReminder = {
            algo : ops.algo,
            param : ops.param,
            remindTime,
            supportPath,
            remindTimeStamp
        }
        const writePath = `${collectionReminder}/${ops.support.id()}`
        return await writeDb(access,writePath , request);
        //return WrapCloud(access, writePath, request, false);
    }catch(e){
        console.error(e);
        return Promise.reject(e);
    }
}
/**
 * Gets the reminder of a support object or supportId
 * @param access 
 * @param collectionReminder reminder collection name
 * @param supportId 
 * @returns 
 */
export async function getReminder(access : CloudAccess, collectionReminder : string, supportId : string | CloudObject<model.Support>): Promise<CloudObject<model.SupportReminder> | null>{
    if(typeof supportId != "string")
        supportId = supportId.id();
    try{
        const doc = await document<model.SupportReminder>(access, `${collectionReminder}/${supportId}`);
        if(doc == null)
            return null;
        return doc;
    }catch(e){
        return Promise.reject(e);
    }
}
/**
 * Gets the support idea object for some given name
 * @param access 
 * @param name Support name
 * @param ideaCollection collection with support ideas
 * @returns 
 */
export async function getSupportIdea(access : CloudAccess, name : string, ideaCollection : string) : Promise<CloudObject<model.SupportIdea>> {
    name = name.trim();
    try{
        const find = await listDb<model.SupportIdea>(access, ideaCollection, {
            where : {
                field : "name",
                method : "==",
                value : name
            }
        });
        if(find.length > 0)
            return find[0];
        const newIdea = model.emptySupportIdea(name);
        const res = await pushDb<model.SupportIdea>(access, ideaCollection, newIdea);
        return res;
    }catch(e){
        return Promise.reject(e);
    }
}
/**
 * Calls the server check function to mark of checked supports.
 * @param support 
 * @param ideas 
 * @returns 
 */
export async function checkSupport(support : string, ideas : string){
    try{
        const checkFunc = await callHttp("checkSupport") 
        await checkFunc({
            support,
            ideas
        })
    }
    catch(e){
        return Promise.reject();
    }  
}
/**
 * Delete's all the plans for some selected managed user
 * @param access 
 * @param subUser 
 * @returns 
 */
export async function deletePlanFor(access : CloudAccess, subUser : string) : Promise<void[]> {
    const uid = uidThrow();
    return deletePlanForUser(access, uid, subUser);
}
/**
 * Deletes all the plans for a selected managed user
 */
export async function deletePlanForUser(access : CloudAccess, uid : string, subUser : string) : Promise<void[]> {
    
    if(!uid || subUser == null || !subUser)
        return Promise.reject("User no avaliable")
    const path = paths.volUserPlans(uid);
    const query = await listDb<model.NDISPlan>(access, path, {
        where : {
            field  : "managedUser",
            method : "==",
            value : subUser
        }
    });

    const proms : Promise<void>[] = []

    query.forEach(plan => {
        proms.push(plan.delete(access))
    })
    return Promise.all(proms);
}
export async function deleteSupport(access : CloudAccess, support : CloudObject<model.Support>){
    const uid = uidThrow();
 
    const pDelete = support.delete(access);
    const docReminder = paths.pageUserReminder(uid, support.id());
    const deleteRemind = removeDb(docReminder)
    return Promise.all([deleteRemind, pDelete]);

}


/**
 * Push API
 */

type PushPlanParam = {
    start : DateVal,
    end : DateVal,
    planName : string,
    description : string
    user : string | CloudObject<model.ManagedUser> | null

    file : {
        filename : string,
        data : any
    } | null
}
/**
 * Will push a NDIS plan into some database path
 * @param access access caller token
 * @param param 
 * @returns 
 */
export async function pushPlan(access : CloudAccess, path : string, param : PushPlanParam): Promise<CloudObject<model.NDISPlan>> {
    //Save File
    const user = param.user;
    let managedUser : string | null = null
    if(typeof user == "string" || user == null)
        managedUser = user
    else
        managedUser = user.id()


    const start = convert(param.start);
    const end = convert(param.end);

    const plan : model.NDISPlan = {
        created: Date.now(),
        dateStart : start.getTime(),
        dateEnd: end.getTime(),
        
        description : param.description,
        fileDisplay : "Deprecated::",
        filename : null,
        funding : {

        },
        managedUser,
        name : param.planName
   
    }

    let userToken : string | null = null
    //Set Managed user token
    if(typeof param.user == "string" || param.user == null)
        userToken = param.user
    else
        userToken = param.user.id();
    plan.managedUser = userToken;

    return pushDb(access, path, plan)
}

type PushManagedUserParam = {
    firstname : string,
    lastname : string
    ndisNo : string,
    birthday : DateVal
}

export async function pushManagedUser(param : PushManagedUserParam){
    const newManaged : model.ManagedUser = {
        birthday : toPossibleDate(param.birthday),
        firstname : param.firstname,
        lastname : param.lastname,
        ndisNo : param.ndisNo
    }
}