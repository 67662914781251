

import PhoenixComponent from "../PhoenixComponent";
import { CloudAccess, ObjectError } from "@/scripts-core/cloud/CloudObject"

const generalError = "Check your connection & subscription.\nIf your subscription has expired you may still read and edit your NDIS plans but cannot add new plan information"

export default abstract class CloudComponent extends PhoenixComponent implements CloudAccess {
    getAccessName() : string {
        return this.componentClass()
    }
    onError(error : ObjectError) {
        console.error(`OnCloudError: ${error.message} @ ${error.objectPath}`)
        this.sendAlert("Database Error", `${error.message}\n${generalError}`)
    }
    abstract onUserReady?(uid : string | null) : void
}
