import { Goals } from "possibleme-db";
import CloudObject from "../../scripts-core/cloud/CloudObject";
import { DocumentInit } from "../../scripts-core/CloudDB";
import { NDISPlan } from "possibleme-db/lib/Types";
import PhoenixContext from "../../scripts-core/core/PhoenixContext";
import LightArray from "../../scripts-core/util/LightArray";

export type ClientSubscribe<T> = (callback : T | null)=>void
export type SubscribeMethod = "single" | "ongoing";
export type Unsubscribe = () => void;


export const initGoals : DocumentInit<Goals> = ()=>{
    return {
        goalList : {}
    }
}

export type Selector<T> = (list : CloudObject<T>[], selection : Selection<T>) => CloudObject<T> | null

export class Selection<T> {
    private algo : Selector<T>
    private selected : CloudObject<T> | null = null;

    private subscribers : LightArray<ClientSubscribe<CloudObject<T>>>
    
    constructor(select : Selector<T>){
        this.algo = select;
        this.subscribers=  new LightArray();
    }
    change(select : Selector<T>, value : CloudObject<T> | null){
        this.algo = select;
        this.selected = value;
        this.invokeSubs();
    }
    force(select : Selector<T>, value : CloudObject<T> | null){
        this.algo = select;
        this.selected = value;
    }
    /* Should not invoke subscribers if the selection has not changed. Otherwise can cause stack overflow */
    select(list : CloudObject<T>[]): CloudObject<T> | null {
        const newSelect = this.algo(list, this);
        
        if(newSelect == null){ 
            this.selected = null;
            this.invokeSubs();
            return newSelect;
        }
        if(Object.is(this.selected, newSelect))
            return newSelect;
        this.selected = newSelect;
        this.invokeSubs();
        return newSelect
        
    }
    get(): CloudObject<T> | null {
        return this.selected;
    }
    subscribe(context : PhoenixContext, method : SubscribeMethod, sub : ClientSubscribe<CloudObject<T>>) : Unsubscribe {
        return registerSub(context, sub, method, this.subscribers, this.selected);
    }
    private invokeSubs(){
        this.subscribers.forEach(sub =>sub(this.selected))
    }
   
}

export function registerSub<T>(context : PhoenixContext, sub : ClientSubscribe<T>, type : SubscribeMethod, list : LightArray<ClientSubscribe<T>>, init : T | null | undefined): Unsubscribe {
    if(init) setTimeout(()=>{sub(init)},20)
    if(type == "ongoing"){
        context.registerLifecycle({
            onCreate(){list.push(sub)},
            onDispose(){list.eject(sub)}
        })
    }
    return ()=>list.eject(sub);
}