
import PossibleVue from '@/scripts/PossibleFramework';
import { Options } from 'vue-class-component';
import * as WHAT from '@/scripts/what'

@Options({
  props: {
    
  }
})
export default class ToastMessage extends PossibleVue {
    message = "This is a message"
    showMessage = false

    created(){
        this.contextDEP().onMessage((what, object) =>{
            switch(what){
                case WHAT.TOAST_MESSAGE:{
                    this.message = object;
                    this.showMessage = true;
                    
                }break;
                case WHAT.HIDE_TOAST:{
                    this.showMessage = false;
                }break;
            }
        })
    }
}
