<template>
    <div class='report-budget'>

        <div v-if="showReport">
            <ReportHeader :headerTitle="'Budget'" :user="user" :plan="plan">
            </ReportHeader>
            
            <h1 class='report-header' >{{titleReport}}</h1>
    
            <div class='shift-down'>
                <div class='pgbreak-after shift-down' v-for="(budget, index) in groups" :key="`group:${index}`">
                    <h4> Budget Summary for {{budget.category.display}} - {{budget.amount.displayMoney()}}</h4>
                    <table class='widget-report'>
                        <tr v-if="budget.items.length == 0">
                            <td>No Supports for this category.</td>
                        </tr>
                        <tr  v-if="budget.items.length > 0">
                            <th class='cell-date'>Date</th>
                            <th class='cell-support'>Support</th>
                            <th class='cell-invoice'>Invoice</th>
                            <th class='money'>Price</th>
                           
                        </tr>
                        <tr>
                            <td>Initial</td>
                            <td>(Previous Spending)</td>
                            <td>--</td>
                            <td class='money'>{{budget.previous.displayMoney()}}</td>
                        </tr>
                        <tr v-for="(support, index) in budget.items" :key="`budget-support:${index}`">
                            <td>{{displaySpan(support.dateStart, support.dateEnd)}}</td>
                            <td>{{support.name}}</td>
                            <td>{{displayInvoice(support)}}</td>
                            <td class='money'>  {{ displayAmount(support.amount)}}</td>
                        </tr>
                        
                    </table>
                    <table class='widget-report'>
                        <tr v-if="false">
                            <th>Previous</th>
                            <td class='money'>{{getPrevious(budget.category)}}</td>
                        </tr>
                        <tr>
                            <th class='cell-date'>Total Spent</th>
                            <td class='money'>{{budget.report.totalSpent.displayMoney()}}</td>
                        </tr>
                        <tr>
                            <th class='cell-date'>Total Planned</th>
                            <td class='money'>{{budget.report.totalPlanned.displayMoney()}}</td>
                        </tr>
                        <tr>
                            <th class='cell-date'>Total Unspent</th>
                            <td class='money'>{{budget.report.totalUnspent.displayMoney()}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import FireComponent from '@/components/phoenix/core/FireComponent.vue';
import { Options } from 'vue-class-component';
import { document, listDb } from "@/scripts-core/CloudDB"
import { Category, NDISPlan, PossibleUser, Support, ManagedUser} from 'possibleme-db';
import {ObjectHelper} from '@/scripts-core/cloud/CloudObject';
import { generateReport, ReportItem } from "possibleme-db/lib/util/BusinessUtil"
import { getCategories } from "possibleme-db/lib/model/NDISPlan"
import { alphaSort } from "possibleme-db/lib/Util"
import { volUserManagedUser } from "possibleme-db/lib/Paths"
import { displayDate } from "possibleme-db/lib/util/DateHelper"
import createBig, { BigNum, displayMoney } from "possibleme-db/lib/util/BigNum"
import ReportHeader from "@/components/possibleme/helper/ReportHeader.vue"

type BudgetGroup = {
    category : Category
    items : Support[]
    report : ReportItem
    previous : BigNum
    amount : BigNum
}

@Options({
  components: {
      ReportHeader
  }
})
export default class ReportPlan extends FireComponent {
    showReport = false

    path : string | null = null
    planId : string | null = null



    plan : NDISPlan | null = null
    user : PossibleUser | null = null
    groups : BudgetGroup[] = []
  

    /*Report Fields*/
    titleReport = ""
 
    componentClass = ():string => "report-budget"

    getPrevious(category : Category):string {
        return `(${displayMoney(this.plan?.funding[category.idRef].amount ?? "0")})` 
    }

    displaySpan(start : number, end : number):string {
        return `${displayDate(start)}`
    }
    displayInvoice(support : Support):string {
        if(!support.isPaid)
            return "Unpaid"


        const num = support.invoiceNumber;
        if(!num)
            return "--"
        return num;
    }
    displayAmount = displayMoney
    
    async onUserReady(uid : string | null) {
        if(uid == null)
            return

        this.planId = (this.$route.query.planId as string) ?? null;
        this.path = `/users/${uid}/plans/${this.planId}`

        try {
            const refUser = await document<PossibleUser>(this, `/users/${uid}`);
            if(refUser == null)
                return this.redirect404()
            const refPlan = await document<NDISPlan>(this, this.path);
            if(refPlan == null)
                return this.redirect404();
            const refSupports = await listDb<Support>(this, `${refPlan.path()}/support`);
            this.plan = refPlan.value(this)

            const supports = ObjectHelper.convertRaw(refSupports);

            this.user = refUser.value(this);
            this.titleReport = `NDIS Report ${this.plan.name}`

            this.groups =  this.calculateBudgetGroup(this.plan, supports);

            const mUser = this.plan.managedUser
        
            if(mUser){
                console.time('doUser')
                const localUser = await document<ManagedUser>(this, `${volUserManagedUser(uid)}/${mUser}`)
                console.timeEnd('doUser')

                
                if(localUser != null)
                    this.user = localUser.value(this);
            }

            this.showReport = true;

        }catch(e : any){
            console.error(e);
            console.warn(e.code);
            this.redirect404()
        }
    }
    calculateBudgetGroup(plan : NDISPlan, supports : Support[]) : BudgetGroup[] {
        /* find categories */
        const report  = generateReport(plan, supports)
        const categories = getCategories(plan);
        alphaSort(categories, "display")
        const group : BudgetGroup[] = []

        for(let icat in categories){
            const cat = categories[icat];
            const catSupports = this.findSupportCategory(cat, supports);
            const item = report.getItem(cat);
            if(!item)
                continue

            const igroup : BudgetGroup = {
                category : cat,
                items : catSupports,
                report : item,
                previous : createBig(plan.funding[cat.idRef].init),
                amount : createBig(plan.funding[cat.idRef].amount)
            }
            group.push(igroup);
        }
        return group;
    }

    findSupportCategory(cat : Category, list : Support[]) : Support[]{
        const array : Support[] = []
        for(let index in list){
            const ilocal = list[index];
            if(ilocal.category.idRef === cat.idRef)
                array.push(ilocal);
        }
        return array;

    }
    
}
</script>

<style scoped>
.cell-date {
    width: 15%;
}
.cell-support {
    width: 40%;
}

.money {
    padding-right: 16px;
}

.report-budget {
    padding: 16px;
    overflow:auto;
}


</style>