

import { Options } from "vue-class-component";
import PossibleVue from "@/scripts/PossibleFramework";

@Options({
  props: {

  }
  
})
export default class AppBar extends PossibleVue {
    

    clickHome(){
        this.$router.push({path : "/"});
    }
    clickPrice(){
        this.$router.push({path : "/app/pricing"});
    }
  //context = PossibleApp.Instance().newStartedContext("DebugContext", [this.example]);  

}
