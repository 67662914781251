import { uuid } from "vue-uuid";
import { Cloud } from "../Cloud"
import { PossibleData } from "../Data";
import { PossibleHelper } from "../Helper";

import ImpPossibleData from "./PossibleData"
import ImpPossibleHelper from "./PossibleHelper" 

import { sha256 } from "js-sha256";

export default class PossibleCloud implements Cloud {
    helper: PossibleHelper = new ImpPossibleHelper();
    data : PossibleData = new ImpPossibleData();

    genUUID(): string {
        return uuid.v4();
    }
    sha256(msg: string | Uint8Array): string {
        return sha256(msg);
    }
}