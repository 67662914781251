<template>
    <main>
        <article>
          <div class='wrapper'>
              <div class='signform'>
                
                <img src="../assets/logo.png" id="logo" @click="logoClick()" ref="mylogo"/>
                
                <h3>Account Login</h3>
                <input class="app-stdinput" v-model="loginEmail" type="text" placeholder="Email">
                <input class="app-stdinput" v-model="loginPassword" type="password" placeholder="Password">
                <button class="form-button" @click="clickLogin()" id="btnLogin">Login</button>
                <div v-if="isSigningIn">
                  <div  class='loader'></div>
                </div>
                <div v-if="showMessage" class="error">
                    <p>{{userMessage}}</p>
                </div>
                <div>
                  <div id="maintain" style="display: block;text-align:center;">
                    <a id="reset" @click="clickReset()">Reset Password</a>
                    <div>Don't have an account?<router-link to="/signup"> Sign-up</router-link></div>
                  </div>
                </div>
              </div>
          </div>
        </article>
    </main>
</template>
<script lang="ts">
import PossibleApp from '@/scripts/PossibleApp';
import PossibleVue from '@/scripts/PossibleFramework';
import { Options } from 'vue-class-component';


@Options({
  components: {
    
  },
})

export default class Home extends PossibleVue {
  userMessage = "Hello World Message"
  showMessage = false
  loginEmail = "";
  loginPassword =  "";

  isSigningIn = false

  clickReset(){
    this.displayLoadingDEP(true);
    this.showMessage  =false;
    this.apiDEP().resetPassword(this.loginEmail, ()=>{
     
      this.userMessage = "Password reset email sent"
      this.showMessage = true;
      this.displayLoadingDEP(false);
    }, (msg)=>{
      this.userMessage = msg
      this.showMessage = true;
      
    })
  }
  clickLogin(){
    this.showMessage = false;
    this.isSigningIn = true
    this.apiDEP().login(this.loginEmail.trim(), this.loginPassword.trim(), ()=>{
      //Success login
      this.isSigningIn = false
      this.loginEmail ="";
      this.loginPassword= "";

      this.$router.push({path: "/manage"})

    }, (error) => {
      this.isSigningIn = false
      this.showMessage = true;
      this.userMessage = error;
    })
    
  }
}
</script>

<style scoped>
  #reset {
    color: cornflowerblue;
  }
  #reset:hover {
    color: cornflowerblue;
    text-decoration: underline;
    cursor: pointer;
    
  }

  .signform > div {
    display: flex;
    justify-content: center;
  }

  #maintain {
    font-size: 1.2rem;
  }

  #btnLogin {
    margin-bottom: 0rem;
  }

  h3{
    text-align: center;
    margin-top: 1rem;
  }

  .loginform {
    display: inline-block;
  }

  #logo{
    margin: auto;
    margin-top: 1.5rem;
    
    
    display: block;
  }

  input{
    text-align: center;
  }

  .wrapper {
    display: flex;
    justify-content: center;
  }

  .smallbtn {
    width: auto;
    margin: auto;
  }


</style>